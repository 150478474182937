import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';
import { fechaActualFunc } from '../../shared/fechas';

const initialState = {
  pieVentasComparativo: {
    loading: false,
    error: null,
    mesActual: 0,
    mesAnterior: 0,
    estaCargado: false
  },
  barVentasPorVendedor: {
    loading: false,
    error: null,
    ventasPorVendedor: [],
    meses: [],
    estaCargado: false
  },
  textoChequesEnCartera: {
    loading: false,
    error: null,
    monto: 0,
    promedioDias: 0,
    estaCargado: false
  },
  heatmapVentasPorDiaAnual: {
    loading: false,
    error: null,
    ventasDiariasDelAnio: null,
    estaCargado: false
  },
  lineChequesEnCartera: {
    loading: false,
    error: null,
    cheques: [],
    totalCheques: 0,
    estaCargado: false
  },
  textoChequesRechazados: {
    loading: false,
    error: null,
    monto: 0,
    cantidadCheques: 0,
    estaCargado: false
  },
  lineChequesRechazados: {
    loading: false,
    error: null,
    cheques: [],
    totalCheques: 0,
    estaCargado: false
  },
  lineChequesPropios: {
    loading: false,
    error: null,
    cheques: [],
    totalCheques: 0,
    estaCargado: false
  },
  detalleCheques: {
    loading: false,
    error: null,
    cheques: [],
    estaCargado: false
  },
  pieVentasComparativoVendedor: {
    loading: false,
    error: null,
    mesActual: 0,
    mesAnterior: 0,
    estaCargado: false
  },
  ventasDiariasComparativo: {
    loading: false,
    error: null,
    diaActual: 0,
    promedio30Dias: 0,
    loadingRequest: false,
    estaCargado: false
  },
  lineVentasComparativo: {
    loading: false,
    error: null,
    periodoActualDinero: [],
    periodoAnteriorDinero: [],
    periodoActualUnidades: [],
    periodoAnteriorUnidades: [],
    referenciasEjeX: [],
    estaCargado: false
  },
  barVentasPorVendedorDelPeriodoComparativo: {
    loading: false,
    error: null,
    ventasPorVendedorPeriodoActual: [],
    ventasPorVendedorPeriodoAnterior: [],
    vendedores: [],
    estaCargado: false
  },
  textoCobranzasMensual: {
    loading: false,
    error: null,
    mesActual: 0,
    mesAnterior: 0,
    estaCargado: false
  },
  textoPlazoMedioVencimientos: {
    loading: false,
    error: null,
    deuda: 0,
    promedioDias: 0,
    cobranzasSinImputar: 0,
    estaCargado: false
  },
  textoPromDiasCobranzas: {
    loading: false,
    error: null,
    dias: 0,
    total: 0,
    estaCargado: false
  },
  textoPromedioCobranzas: {
    loading: false,
    error: null,
    promedio: 0,
    cantidad: 0,
    estaCargado: false
  },
  deudoresPorVentas: {
    facturas: [],
    facturasPorCliente: [],
    totalFacturas: 0,
    totalFacturasPorCliente: 0,
    loading: false,
    loadingPorCliente: false,
    estaCargado: false,
    estaCargadoPorCliente: false,
    error: null,
    errorPorCliente: null,
  },
  comprobantesVentas: {
    loading: false,
    comprobantes: [],
    error: null,
    estaCargado: false
  },
  comprobantesCobranzas: {
    loading: false,
    comprobantes: [],
    error: null,
    estaCargado: false
  },
  categoriasDelClienteIndicadores: {
    categorias: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  categoriasDelCliente: {
    categorias: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  deudaPorCategoriaDeCliente: {
    categorias: [],
    loading: false,
    error: null,
    total: 0,
    estaCargado: false,
    valoresNegativos: []
  },
  ventasPorMarca: {
    ventas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasPorRubro: {
    ventas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasPorSubRubro: {
    ventas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  utilidadPorMarca: {
    utilidades: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  utilidadPorRubro: {
    utilidades: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  utilidadPorSubrubro: {
    utilidades: [],
    total: 0,
    loading: false,
    progress: 0,
    error: null,
    estaCargado: false
  },
  evolucionVentasMensual: {
    ventas: [],
    ventasDataTable: [],
    totalVentas: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasPorCategoriaDeCliente: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasPorClienteDeLaCategoria: {
    ventas: [],
    loading: false,
    error: null
  },
  evolucionVentasPorCategoriaDeCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorVendedores: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorProvincias: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorZonas: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorMarca: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorRubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorSubrubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasDelMesPivotGrid: {
    articulos: [],
    totalFacturacion: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
  },
  dataEvolucionVentasSelected: 1,
  dataVentasDelMesPorSelected: 'marca',
  fechaVentasDelMes: {
    mes: fechaActualFunc().getMonth() + 1,
    anio: fechaActualFunc().getFullYear()
  },
  vendedorVentasDelMesSelected: undefined,
  //#region UTILIDAD
  dataUtilidadDelMesPorSelected: 'categoriaDeCliente',
  fechaUtilidadDelMes: {
    mes: fechaActualFunc().getMonth() + 1,
    anio: fechaActualFunc().getFullYear()
  },
  evolucionUtilidadMensual: {
    utilidades: [],
    utilidadesDataTable: [],
    totalUtilidad: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  utilidadDelMesPorCategoriaDeCliente: {
    utilidades: [],
    utilidadesNegativo: [],
    utilidadesDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  utilidadDelMesPorVendedores: {
    utilidades: [],
    utilidadesNegativo: [],
    utilidadesDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  utilidadDelMesPorProvincias: {
    utilidades: [],
    utilidadesNegativo: [],
    utilidadesDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  utilidadDelMesPorZonas: {
    utilidades: [],
    utilidadesNegativo: [],
    utilidadesDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  utilidadDelMesPorMarcas: {
    utilidades: [],
    utilidadesNegativo: [],
    utilidadesDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  utilidadDelMesPorRubros: {
    utilidades: [],
    utilidadesNegativo: [],
    utilidadesDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  utilidadDelMesPorSubrubros: {
    utilidades: [],
    utilidadesNegativo: [],
    utilidadesDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  evolucionUtilidadPorCategoriaDeCliente: {
    utilidades: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionUtilidadPorVendedores: {
    utilidades: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionUtilidadPorProvincias: {
    utilidades: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionUtilidadPorZonas: {
    utilidades: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionUtilidadPorMarca: {
    utilidades: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionUtilidadPorRubro: {
    utilidades: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionUtilidadPorSubrubro: {
    utilidades: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  //#endregion UTILIDAD
  deudaDelMesPorCliente: {
    deudas: [],
    deudasNegativo: [],
    deudasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: "",
    ejecucionIndicador: false,
    orden: null,
  },
  fechaDeudasDelMesAnio: {
    mes: fechaActualFunc().getMonth() + 1,
    anio: fechaActualFunc().getFullYear(),
  },
  cobranzasDelDia: {
    loading: false,
    error: null,
    diaActual: 0,
    promedio30Dias: 0,
    loadingRequest: false,
    estaCargado: false
  },
  detalleCobranzasDelDia: {
    loading: false,
    error: null,
    cobranzas: [],
    estaCargado: false
  },
  chequesDepositadosDelDia: {
    loading: false,
    error: null,
    monto: 0,
    cantidadCheques: 0,
    estaCargado: false
  },
  totalNotasDePedido: {
    loading: false,
    error: null,
    total: 0,
    totalConvertido: 0,
    monedaSecundaria: '',
    estaCargado: false
  },
  detalleNotasDePedido: {
    loading: false,
    error: null,
    notasDePedido: [],
    estaCargado: false
  },
  ventasDelMesAUnCliente: {
    loading: false,
    error: null,
    mesActual: 0,
    mesAnterior: 0,
    estaCargado: false
  },
  pedidosMesComparativo: {
    transMesAnt: 0,
    totalMesAnt: 0,
    transMesAct: 0,
    totalMesAct: 0,
    porcTrans: 0,
    porcTotal: 0,
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
    totalMesActualPorCliente: 0,
    totalMesAnteriorPorCliente: 0,
  },
  ventasPorVendedor: {
    error: null,
    estaCargado: false,
    loading: false,
    unidadesVendidas: 0,
    ventas: 0,
    cantidad: 0,
    loadingPorCliente: false,
    unidadesVendidasPorCliente: 0,
    ventasPorCliente: 0,
    cantidadPorCliente: 0,
  },
  pedidosPendientes: {
    cantidad: 0,
    total: 0,
    prom30Dias: 0,
    cantidadPorCliente: 0,
    totalPorCliente: 0,
    prom30DiasPorCliente: 0,
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
  },
  estadosPedidosPendientes: {
    pedidos: [],
    loading: false,
    error: null,
    estaCargado: false,
    total: 0,
  },
  chequesEnCartera: {
    monto: 0,
    cantidadCheques: 0,
    loading: false,
    error: null,
  },
  facturasPendientes: {
    facturas: [],
    facturasPorCliente: [],
    totalFacturas: 0,
    totalFacturasPorCliente: 0,
    loading: false,
    loadingPorCliente: false,
    estaCargado: false,
    estaCargadoPorCliente: false,
    error: null,
    errorPorCliente: null,
  },
  comprobantesPedidosPendientes: {
    comprobantes: [],
    loading: false,
    estaCargado: false,
    error: null,
  },
  comprobantesFacturasPendientes: {
    comprobantes: [],
    loading: false,
    estaCargado: false,
    error: null,
  },
  evolucionVentasMensualInteranual: {
    periodos: [],
    periodoSeleccionado: null,
    items: [],
    filtroPeriodoSeleccionado: {
      aplicado: false,
      desde: null,
      hasta: null
    }
  },
  evolucionUtilidadesMensualInteranual: {
    periodos: [],
    periodoSeleccionado: fechaActualFunc(),
    items: []
  },
  //#region VENTAS POR PERIODOS
  dataEvolucionVentasPorPeriodosSelected: 'marca',
  actualizarDataEvolucionVentasPorPeriodos: true,
  clearDataEvolucionVentasPorPeriodos: false,
  filtroEvolucionVentasPorPeriodos: {
    mesDesde: null,
    mesHasta: null,
    aniosSelected: [],
    dataSelectedId: 'marca',
    aplicado: true,
    userSelected: false,
  },
  dataVentasPorPeriodosSelected: 'marca',
  actualizarDataVentasComparativasPorPeriodos: true,
  clearDataVentasComparativasPorPeriodos: false,
  filtroVentasComparativasPorPeriodos: {
    mesDesde: null,
    mesHasta: null,
    aniosSelected: [],
    dataSelectedId: 'marca',
    aplicado: true,
    userSelected: false,
  },

  dataVentasPorMesSelected: 'marca',
  actualizarDataVentasComparativasPorMes: true,
  clearDataVentasComparativasPorMes: false,
  filtroVentasComparativasPorMes: {
    mesDesde: null,
    mesHasta: null,
    aniosSelected: [],
    dataSelectedId: 'marca',
    aplicado: true,
    userSelected: false,
  },

  evolucionVentasPorPeriodosAniosSelected: [],
  evolucionVentasPorPeriodosPorMarca: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorRubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorArticulo: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorCanal: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorGrupoCompra: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorProvincia: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorResponsable: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorUnidadNegocio: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorVendedor: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorZona: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorSubrubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasPorPeriodosPorCategoriaCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },

  ventasPorPeriodosPorMarcas: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasPorPeriodosPorRubros: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasComparativasPorMarca: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorRubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorArticulo: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorCanal: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorGrupoCompra: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorProvincia: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorResponsable: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorUnidadNegocio: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorVendedor: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorZona: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorSubrubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorCategoriaCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },

  ventasComparativasPorMesPorMarca: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorRubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorArticulo: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorCanal: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorGrupoCompra: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorProvincia: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorResponsable: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorUnidadNegocio: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorVendedor: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorZona: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorSubrubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasComparativasPorMesPorCategoriaCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  //#endregion
  //#region VENTAS DESAGREGADAS
  tipoVentasDesagregadasSelected: 'marca',
  tipoVentasDesagregadas: {
    tipos: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  ventasDelMesPorUnidadesDeNegocios: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorCanales: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorGruposDeCompras: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorResponsables: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorClientes: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorVendedores: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorProvincias: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorZonas: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorMarcas: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorRubros: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorSubrubros: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorArticulos: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  ventasDelMesPorCategoriasClientes: {
    ventas: [],
    ventasNegativo: [],
    total: 0,
    totalUnidades: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: ''
  },
  //#endregion
  //#region CUBO VENTAS
  cuboVentasData: {
    loading: false,
    estaCargado: false,
    error: null,
    datos: null,
    config: null,
    filtros: {},
  },
  cuboVentasFiltros: {
    desde: null,
    hasta: null,
    anioComparacion: null,
    filtroAplicado: true
  },
  //#endregion
};

//#region pie ventas comparativo
const pieVentasComparativoStart = (state) => {
  return updateObject(state, {
    pieVentasComparativo: updateObject(state.pieVentasComparativo, {
      loading: true,
      mesActual: 0,
      mesAnterior: 0,
      error: null
    })
  });
};

const pieVentasComparativoSuccess = (state, mesActual, mesAnterior) => {
  return updateObject(state, {
    pieVentasComparativo: updateObject(state.pieVentasComparativo, {
      loading: false,
      error: null,
      mesActual: mesActual,
      mesAnterior: mesAnterior,
      estaCargado: true
    })
  });
};

const pieVentasComparativoFail = (state, error) => {
  return updateObject(state, {
    pieVentasComparativo: updateObject(state.pieVentasComparativo, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearPieVentasComparativo = (state) => {
  return updateObject(state, {
    pieVentasComparativo: updateObject(state.pieVentasComparativo, {
      loading: false,
      error: null,
      mesActual: 0,
      mesAnterior: 0,
      estaCargado: false
    })
  });
};

//#endregion

//#region bar ventas por vendedor
const barVentasPorVendedorStart = (state) => {
  return updateObject(state, {
    barVentasPorVendedor: updateObject(state.barVentasPorVendedor, {
      loading: true,
      ventasPorVendedor: [],
      meses: [],
      error: null
    })
  });
};

const barVentasPorVendedorSuccess = (state, ventasPorVendedor, meses) => {
  return updateObject(state, {
    barVentasPorVendedor: updateObject(state.barVentasPorVendedor, {
      loading: false,
      error: null,
      ventasPorVendedor: ventasPorVendedor,
      meses: meses,
      estaCargado: true
    })
  });
};

const barVentasPorVendedorFail = (state, error) => {
  return updateObject(state, {
    barVentasPorVendedor: updateObject(state.barVentasPorVendedor, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region texto cheques en cartera
const textoChequesEnCarteraStart = (state) => {
  return updateObject(state, {
    textoChequesEnCartera: updateObject(state.textoChequesEnCartera, {
      loading: true,
      monto: 0,
      promedioDias: 0,
      error: null
    })
  });
};

const textoChequesEnCarteraSuccess = (state, monto, promedioDias) => {
  return updateObject(state, {
    textoChequesEnCartera: updateObject(state.textoChequesEnCartera, {
      loading: false,
      error: null,
      monto: monto,
      promedioDias: promedioDias,
      estaCargado: true
    })
  });
};

const textoChequesEnCarteraFail = (state, error) => {
  return updateObject(state, {
    textoChequesEnCartera: updateObject(state.textoChequesEnCartera, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoChequesEnCartera = (state) => {
  return updateObject(state, {
    textoChequesEnCartera: updateObject(state.textoChequesEnCartera, {
      loading: false,
      error: null,
      monto: 0,
      promedioDias: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region texto cheques rechazados
const textoChequesRechazadosStart = (state) => {
  return updateObject(state, {
    textoChequesRechazados: updateObject(state.textoChequesRechazados, {
      loading: true,
      monto: 0,
      cantidadCheques: 0,
      error: null
    })
  });
};

const textoChequesRechazadosSuccess = (state, monto, cantidadCheques) => {
  return updateObject(state, {
    textoChequesRechazados: updateObject(state.textoChequesRechazados, {
      loading: false,
      error: null,
      monto,
      cantidadCheques,
      estaCargado: true
    })
  });
};

const textoChequesRechazadosFail = (state, error) => {
  return updateObject(state, {
    textoChequesRechazados: updateObject(state.textoChequesRechazados, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoChequesRechazados = (state) => {
  return updateObject(state, {
    textoChequesRechazados: updateObject(state.textoChequesRechazados, {
      loading: false,
      error: null,
      monto: 0,
      cantidadCheques: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region detalle de comprobantes cheques
const detalleChequesStart = (state) => {
  return updateObject(state, {
    detalleCheques: updateObject(state.detalleCheques, {
      loading: true,
      cheques: [],
      error: null
    })
  });
};

const detalleChequesSuccess = (state, cheques) => {
  return updateObject(state, {
    detalleCheques: updateObject(state.detalleCheques, {
      cheques: cheques,
      loading: false,
      error: null,
      estaCargado: true
    })
  });
};

const detalleChequesFail = (state, error) => {
  return updateObject(state, {
    detalleCheques: updateObject(state.detalleCheques, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearDetalleCheques = (state) => {
  return updateObject(state, {
    detalleCheques: updateObject(state.detalleCheques, {
      loading: false,
      error: null,
      cheques: [],
      estaCargado: false
    })
  });
};
//#endregion

//#region heatmap ventas por dia anual
const heatmapVentasPorDiaAnualStart = (state) => {
  return updateObject(state, {
    heatmapVentasPorDiaAnual: updateObject(state.heatmapVentasPorDiaAnual, {
      loading: true,
      error: null,
      ventasDiariasDelAnio: null
    })
  });
};

const heatmapVentasPorDiaAnualSuccess = (state, ventas) => {
  return updateObject(state, {
    heatmapVentasPorDiaAnual: updateObject(state.heatmapVentasPorDiaAnual, {
      loading: false,
      error: null,
      ventasDiariasDelAnio: ventas,
      estaCargado: true
    })
  });
};

const heatmapVentasPorDiaAnualFail = (state, error) => {
  return updateObject(state, {
    heatmapVentasPorDiaAnual: updateObject(state.heatmapVentasPorDiaAnual, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region line cheques en cartera
const lineChequesEnCarteraStart = (state) => {
  return updateObject(state, {
    lineChequesEnCartera: updateObject(state.lineChequesEnCartera, {
      loading: true,
      error: null,
      cheques: []
    })
  });
};

const lineChequesEnCarteraSuccess = (state, cheques, totalCheques) => {
  return updateObject(state, {
    lineChequesEnCartera: updateObject(state.lineChequesEnCartera, {
      loading: false,
      cheques,
      error: null,
      totalCheques,
      estaCargado: true
    })
  });
};

const lineChequesEnCarteraFail = (state, error) => {
  return updateObject(state, {
    lineChequesEnCartera: updateObject(state.lineChequesEnCartera, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearLineChequesEnCartera = (state) => {
  return updateObject(state, {
    lineChequesEnCartera: updateObject(state.lineChequesEnCartera, {
      loading: false,
      error: null,
      cheques: [],
      totalCheques: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region line cheques rechazados
const lineChequesRechazadosStart = (state) => {
  return updateObject(state, {
    lineChequesRechazados: updateObject(state.lineChequesRechazados, {
      loading: true,
      error: null,
      cheques: []
    })
  });
};

const lineChequesRechazadosSuccess = (state, cheques, totalCheques) => {
  return updateObject(state, {
    lineChequesRechazados: updateObject(state.lineChequesRechazados, {
      loading: false,
      cheques,
      error: null,
      totalCheques,
      estaCargado: true
    })
  });
};

const lineChequesRechazadosFail = (state, error) => {
  return updateObject(state, {
    lineChequesRechazados: updateObject(state.lineChequesRechazados, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearLineChequesRechazados = (state) => {
  return updateObject(state, {
    lineChequesRechazados: updateObject(state.lineChequesRechazados, {
      loading: false,
      error: null,
      cheques: [],
      totalCheques: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region line cheques emitidos
const lineChequesPropiosStart = (state) => {
  return updateObject(state, {
    lineChequesPropios: updateObject(state.lineChequesPropios, {
      loading: true,
      error: null,
      cheques: []
    })
  });
};

const lineChequesPropiosSuccess = (state, cheques, totalCheques) => {
  return updateObject(state, {
    lineChequesPropios: updateObject(state.lineChequesPropios, {
      loading: false,
      error: null,
      cheques,
      totalCheques,
      estaCargado: true
    })
  });
};

const lineChequesPropiosFail = (state, error) => {
  return updateObject(state, {
    lineChequesPropios: updateObject(state.lineChequesPropios, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearLineChequesPropios = (state) => {
  return updateObject(state, {
    lineChequesPropios: updateObject(state.lineChequesPropios, {
      loading: false,
      error: null,
      cheques: [],
      totalCheques: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region pie ventas comparativo vendedor
const pieVentasComparativoVendedorStart = (state) => {
  return updateObject(state, {
    pieVentasComparativoVendedor: updateObject(state.pieVentasComparativoVendedor, {
      loading: true,
      mesActual: 0,
      mesAnterior: 0,
      error: null
    })
  });
};

const pieVentasComparativoVendedorSuccess = (state, mesActual, mesAnterior) => {
  return updateObject(state, {
    pieVentasComparativoVendedor: updateObject(state.pieVentasComparativoVendedor, {
      error: null,
      loading: false,
      mesActual: mesActual,
      mesAnterior: mesAnterior,
      estaCargado: true
    })
  });
};

const pieVentasComparativoVendedorFail = (state, error) => {
  return updateObject(state, {
    pieVentasComparativoVendedor: updateObject(state.pieVentasComparativoVendedor, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region ventas diarias comparativo
const ventasDiariasComparativoStart = (state) => {
  return updateObject(state, {
    ventasDiariasComparativo: updateObject(state.ventasDiariasComparativo, {
      loading: true,
      error: null,
      diaActual: 0,
      promedio30Dias: 0
    })
  });
};

const ventasDiariasComparativoRequest = (state, loadingRequest) => {
  return updateObject(state, {
    ventasDiariasComparativo: updateObject(state.ventasDiariasComparativo, {
      loadingRequest
    })
  });
};

const ventasDiariasComparativoSuccess = (state, diaActual, promedio30Dias) => {
  return updateObject(state, {
    ventasDiariasComparativo: updateObject(state.ventasDiariasComparativo, {
      loading: false,
      error: null,
      diaActual: diaActual,
      promedio30Dias: promedio30Dias,
      estaCargado: true
    })
  });
};

const ventasDiariasComparativoFail = (state, error) => {
  return updateObject(state, {
    ventasDiariasComparativo: updateObject(state.ventasDiariasComparativo, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearVentasDiariasComparativo = (state) => {
  return updateObject(state, {
    ventasDiariasComparativo: updateObject(state.ventasDiariasComparativo, {
      loading: false,
      error: null,
      diaActual: 0,
      promedio30Dias: 0,
      loadingRequest: false,
      estaCargado: false
    })
  });
};
//#endregion

//#region line ventas comparativo
const lineVentasComparativoStart = (state) => {
  return updateObject(state, {
    lineVentasComparativo: updateObject(state.lineVentasComparativo, {
      loading: true,
      error: null,
      periodoActualDinero: [],
      periodoAnteriorDinero: [],
      periodoActualUnidades: [],
      periodoAnteriorUnidades: [],
      referenciasEjeX: []
    })
  });
};

const lineVentasComparativoSuccess = (
  state,
  periodoActualDinero,
  periodoAnteriorDinero,
  periodoActualUnidades,
  periodoAnteriorUnidades,
  referenciasEjeX
) => {
  return updateObject(state, {
    lineVentasComparativo: updateObject(state.lineVentasComparativo, {
      loading: false,
      error: null,
      periodoActualDinero: periodoActualDinero,
      periodoAnteriorDinero: periodoAnteriorDinero,
      periodoActualUnidades: periodoActualUnidades,
      periodoAnteriorUnidades: periodoAnteriorUnidades,
      referenciasEjeX: referenciasEjeX,
      estaCargado: true
    })
  });
};

const lineVentasComparativoFail = (state, error) => {
  return updateObject(state, {
    lineVentasComparativo: updateObject(state.lineVentasComparativo, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region bar ventas por vendedor del periodo comparativo
const barVentasPorVendedorDelPeriodoComparativoStart = (state) => {
  return updateObject(state, {
    barVentasPorVendedorDelPeriodoComparativo: updateObject(state.barVentasPorVendedorDelPeriodoComparativo, {
      loading: true,
      ventasPorVendedorPeriodoActual: [],
      ventasPorVendedorPeriodoAnterior: [],
      vendedores: [],
      error: null
    })
  });
};

const barVentasPorVendedorDelPeriodoComparativoSuccess = (
  state,
  ventasPorVendedorPeriodoActual,
  ventasPorVendedorPeriodoAnterior,
  vendedores
) => {
  return updateObject(state, {
    barVentasPorVendedorDelPeriodoComparativo: updateObject(state.barVentasPorVendedorDelPeriodoComparativo, {
      loading: false,
      ventasPorVendedorPeriodoActual: ventasPorVendedorPeriodoActual,
      error: null,
      ventasPorVendedorPeriodoAnterior: ventasPorVendedorPeriodoAnterior,
      vendedores: vendedores
    })
  });
};

const barVentasPorVendedorDelPeriodoComparativoFail = (state, error) => {
  return updateObject(state, {
    barVentasPorVendedorDelPeriodoComparativo: updateObject(state.barVentasPorVendedorDelPeriodoComparativo, {
      loading: false,
      error: error
    })
  });
};
//#endregion

//#region texto cobranzas mensual
const textoCobranzasMensualStart = (state) => {
  return updateObject(state, {
    textoCobranzasMensual: updateObject(state.textoCobranzasMensual, {
      loading: true,
      mesActual: 0,
      mesAnterior: 0,
      error: null
    })
  });
};

const textoCobranzasMensualSuccess = (state, mesActual, mesAnterior) => {
  return updateObject(state, {
    textoCobranzasMensual: updateObject(state.textoCobranzasMensual, {
      loading: false,
      error: null,
      mesActual,
      mesAnterior,
      estaCargado: true
    })
  });
};

const textoCobranzasMensualFail = (state, error) => {
  return updateObject(state, {
    textoCobranzasMensual: updateObject(state.textoCobranzasMensual, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoCobranzasMensual = (state) => {
  return updateObject(state, {
    textoCobranzasMensual: updateObject(state.textoCobranzasMensual, {
      loading: false,
      error: null,
      mesActual: 0,
      mesAnterior: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region texto deudores por ventas
const textoPlazoMedioVencimientosStart = (state) => {
  return updateObject(state, {
    textoPlazoMedioVencimientos: updateObject(state.textoPlazoMedioVencimientos, {
      loading: true,
      error: null,
      deuda: 0,
      promedioDias: 0,
      cobranzasSinImputar: 0,
    })
  });
};

const textoPlazoMedioVencimientosSuccess = (state, deuda, promedioDias, cobranzasSinImputar) => {
  return updateObject(state, {
    textoPlazoMedioVencimientos: updateObject(state.textoPlazoMedioVencimientos, {
      loading: false,
      error: null,
      deuda: deuda,
      promedioDias: promedioDias,
      cobranzasSinImputar: cobranzasSinImputar,
      estaCargado: true
    })
  });
};

const textoPlazoMedioVencimientosFail = (state, error) => {
  return updateObject(state, {
    textoPlazoMedioVencimientos: updateObject(state.textoPlazoMedioVencimientos, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoPlazoMedioVencimientos = (state) => {
  return updateObject(state, {
    textoPlazoMedioVencimientos: updateObject(state.textoPlazoMedioVencimientos, {
      loading: false,
      error: null,
      deuda: 0,
      promedioDias: 0,
      cobranzasSinImputar: 0,
      estaCargado: false
    })
  });
};

//#endregion

//#region texto plazo medio cobranzas
const textoPromDiasCobranzasStart = (state) => {
  return updateObject(state, {
    textoPromDiasCobranzas: updateObject(state.textoPromDiasCobranzas, {
      loading: true,
      error: null,
      dias: 0,
      total: 0
    })
  });
};

const textoPromDiasCobranzasSuccess = (state, dias, total) => {
  return updateObject(state, {
    textoPromDiasCobranzas: updateObject(state.textoPromDiasCobranzas, {
      loading: false,
      error: null,
      dias,
      total,
      estaCargado: true
    })
  });
};

const textoPromDiasCobranzasFail = (state, error) => {
  return updateObject(state, {
    textoPromDiasCobranzas: updateObject(state.textoPromDiasCobranzas, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearTextoPromDiasCobranzas = (state) => {
  return updateObject(state, {
    textoPromDiasCobranzas: updateObject(state.textoPromDiasCobranzas, {
      loading: false,
      error: null,
      dias: 0,
      total: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region texto promedio cobranzas
const textoPromedioCobranzasStart = (state) => {
  return updateObject(state, {
    textoPromedioCobranzas: updateObject(state.textoPromedioCobranzas, {
      loading: true,
      error: null,
      promedio: 0
    })
  });
};

const textoPromedioCobranzasSuccess = (state, promedio, cantidad) => {
  return updateObject(state, {
    textoPromedioCobranzas: updateObject(state.textoPromedioCobranzas, {
      loading: false,
      error: null,
      promedio: promedio,
      cantidad: cantidad,
      estaCargado: true
    })
  });
};

const textoPromedioCobranzasFail = (state, error) => {
  return updateObject(state, {
    textoPromedioCobranzas: updateObject(state.textoPromedioCobranzas, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region loadDeudoresPorVentasStart
const loadDeudoresPorVentasStart = (state) => {
  return updateObject(state, {
    deudoresPorVentas: updateObject(state.deudoresPorVentas, {
      loading: true,
      error: null,
      deudas: [],
      totalDeudas: 0
    })
  });
};

const loadDeudoresPorVentasSuccess = (state, facturas, totalFacturas) => {
  return updateObject(state, {
    deudoresPorVentas: updateObject(state.deudoresPorVentas, {
      facturas,
      totalFacturas,
      loading: false,
      estaCargado: true,
      error: null,
    })
  });
};

const loadDeudoresPorVentasFail = (state, error) => {
  return updateObject(state, {
    deudoresPorVentas: updateObject(state.deudoresPorVentas, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearDeudoresPorVentas = (state) => {
  return updateObject(state, {
    deudoresPorVentas: updateObject(state.deudoresPorVentas, {
      loading: false,
      error: null,
      deudas: [],
      totalDeudas: 0,
      estaCargado: false
    })
  });
};

//#endregion

//#region load comprobantes de Ventas
const loadComprobantesVentasStart = (state) => {
  return updateObject(state, {
    comprobantesVentas: updateObject(state.comprobantesVentas, {
      loading: true,
      comprobantes: [],
      error: null
    })
  });
};

const loadComprobantesVentasSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesVentas: updateObject(state.comprobantesVentas, {
      error: null,
      loading: false,
      comprobantes,
      estaCargado: true
    })
  });
};

const loadComprobantesVentasFail = (state, error) => {
  return updateObject(state, {
    comprobantesVentas: updateObject(state.comprobantesVentas, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region load comprobantes de Cobranzas
const loadComprobantesCobranzasStart = (state) => {
  return updateObject(state, {
    comprobantesCobranzas: updateObject(state.comprobantesCobranzas, {
      loading: true,
      comprobantes: [],
      error: null
    })
  });
};

const loadComprobantesCobranzasSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesCobranzas: updateObject(state.comprobantesCobranzas, {
      error: null,
      loading: false,
      comprobantes,
      estaCargado: true
    })
  });
};

const loadComprobantesCobranzasFail = (state, error) => {
  return updateObject(state, {
    comprobantesCobranzas: updateObject(state.comprobantesCobranzas, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region load Categorias del cliente Indicadores
const loadCategoriasDelClienteIndicadoresStart = (state) => {
  return updateObject(state, {
    categoriasDelClienteIndicadores: updateObject(state.categoriasDelClienteIndicadores, {
      loading: true
    })
  });
};

const loadCategoriasDelClienteIndicadoresSuccess = (state, categorias) => {
  return updateObject(state, {
    categoriasDelClienteIndicadores: updateObject(state.categoriasDelClienteIndicadores, {
      categorias,
      error: null,
      loading: false,
      estaCargado: true
    })
  });
};

const loadCategoriasDelClienteIndicadoresFail = (state, error) => {
  return updateObject(state, {
    categoriasDelClienteIndicadores: updateObject(state.categoriasDelClienteIndicadores, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region load Categorias del cliente
const loadCategoriasDelClienteStart = (state) => {
  return updateObject(state, {
    categoriasDelCliente: updateObject(state.categoriasDelCliente, {
      loading: true
    })
  });
};

const loadCategoriasDelClienteSuccess = (state, categorias) => {
  return updateObject(state, {
    categoriasDelCliente: updateObject(state.categoriasDelCliente, {
      categorias,
      error: null,
      loading: false,
      estaCargado: true
    })
  });
};

const loadCategoriasDelClienteFail = (state, error) => {
  return updateObject(state, {
    categoriasDelCliente: updateObject(state.categoriasDelCliente, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};
//#endregion

//#region load Deuda por categoria de cliente
const loadDeudaPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    deudaPorCategoriaDeCliente: updateObject(state.deudaPorCategoriaDeCliente, {
      categorias: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false,
      valoresNegativos: []
    })
  });
};

const loadDeudaPorCategoriaDeClienteSuccess = (state, categorias, total, valoresNegativos) => {
  return updateObject(state, {
    deudaPorCategoriaDeCliente: updateObject(state.deudaPorCategoriaDeCliente, {
      categorias,
      total,
      error: null,
      loading: false,
      estaCargado: true,
      valoresNegativos
    })
  });
};

const loadDeudaPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    deudaPorCategoriaDeCliente: updateObject(state.deudaPorCategoriaDeCliente, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearDeudaPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    deudaPorCategoriaDeCliente: updateObject(state.deudaPorCategoriaDeCliente, {
      categorias: [],
      valoresNegativos: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load Ventas por marca
const loadVentasPorMarcaStart = (state) => {
  return updateObject(state, {
    ventasPorMarca: updateObject(state.ventasPorMarca, {
      loading: true
    })
  });
};

const loadVentasPorMarcaSuccess = (state, ventas, total) => {
  return updateObject(state, {
    ventasPorMarca: updateObject(state.ventasPorMarca, {
      ventas,
      total,
      error: null,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorMarcaFail = (state, error) => {
  return updateObject(state, {
    ventasPorMarca: updateObject(state.ventasPorMarca, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearVentasPorMarca = (state) => {
  return updateObject(state, {
    ventasPorMarca: updateObject(state.ventasPorMarca, {
      ventas: [],
      total: 0,
      error: null,
      loading: false,
      estaCargado: false
    })
  });
};
//#endregion

//#region load Ventas por rubro
const loadVentasPorRubroStart = (state) => {
  return updateObject(state, {
    ventasPorRubro: updateObject(state.ventasPorRubro, {
      loading: true
    })
  });
};

const loadVentasPorRubroSuccess = (state, ventas, total) => {
  return updateObject(state, {
    ventasPorRubro: updateObject(state.ventasPorRubro, {
      ventas,
      total,
      error: null,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorRubroFail = (state, error) => {
  return updateObject(state, {
    ventasPorRubro: updateObject(state.ventasPorRubro, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearVentasPorRubro = (state) => {
  return updateObject(state, {
    ventasPorRubro: updateObject(state.ventasPorRubro, {
      ventas: [],
      total: 0,
      error: null,
      loading: false,
      estaCargado: false
    })
  });
};
//#endregion

//#region load Ventas por sub rubro
const loadVentasPorSubRubroStart = (state) => {
  return updateObject(state, {
    ventasPorSubRubro: updateObject(state.ventasPorSubRubro, {
      loading: true
    })
  });
};

const loadVentasPorSubRubroSuccess = (state, ventas, total) => {
  return updateObject(state, {
    ventasPorSubRubro: updateObject(state.ventasPorSubRubro, {
      ventas,
      total,
      error: null,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasPorSubRubroFail = (state, error) => {
  return updateObject(state, {
    ventasPorSubRubro: updateObject(state.ventasPorSubRubro, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearVentasPorSubRubro = (state) => {
  return updateObject(state, {
    ventasPorSubRubro: updateObject(state.ventasPorSubRubro, {
      ventas: [],
      total: 0,
      error: null,
      loading: false,
      estaCargado: false
    })
  });
};
//#endregion

//#region load Utilidad por marca
const loadUtilidadPorMarcaStart = (state) => {
  return updateObject(state, {
    utilidadPorMarca: updateObject(state.utilidadPorMarca, {
      utilidades: [],
      total: 0,
      error: null,
      loading: true,
      estaCargado: false
    })
  });
};

const loadUtilidadPorMarcaSuccess = (state, utilidades, total) => {
  return updateObject(state, {
    utilidadPorMarca: updateObject(state.utilidadPorMarca, {
      utilidades,
      total,
      error: null,
      loading: false,
      estaCargado: true
    })
  });
};

const loadUtilidadPorMarcaFail = (state, error) => {
  return updateObject(state, {
    utilidadPorMarca: updateObject(state.utilidadPorMarca, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearUtilidadPorMarca = (state) => {
  return updateObject(state, {
    utilidadPorMarca: updateObject(state.utilidadPorMarca, {
      utilidades: [],
      total: 0,
      error: null,
      loading: false,
      estaCargado: false
    })
  });
};

const updateUtilidadesPorMarcaOcultos = (state, utilidades) => {
  return updateObject(state, {
    ventasPorMarca: updateObject(state.ventasPorMarca, {
      utilidades
    })
  });
};
//#endregion

//#region load Utilidad por rubro
const loadUtilidadPorRubroStart = (state) => {
  return updateObject(state, {
    utilidadPorRubro: updateObject(state.utilidadPorRubro, {
      utilidades: [],
      total: 0,
      error: null,
      loading: true,
      estaCargado: false
    })
  });
};

const loadUtilidadPorRubroSuccess = (state, utilidades, total) => {
  return updateObject(state, {
    utilidadPorRubro: updateObject(state.utilidadPorRubro, {
      utilidades,
      total,
      error: null,
      loading: false,
      estaCargado: true
    })
  });
};

const loadUtilidadPorRubroFail = (state, error) => {
  return updateObject(state, {
    utilidadPorRubro: updateObject(state.utilidadPorRubro, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearUtilidadPorRubro = (state) => {
  return updateObject(state, {
    utilidadPorRubro: updateObject(state.utilidadPorRubro, {
      utilidades: [],
      total: 0,
      error: null,
      loading: false,
      estaCargado: false
    })
  });
};

const updateUtilidadesPorRubroOcultos = (state, utilidades) => {
  return updateObject(state, {
    ventasPorRubro: updateObject(state.ventasPorRubro, {
      utilidades
    })
  });
};
//#endregion

//#region load Utilidad por Subrubro
const loadUtilidadPorSubrubroStart = (state, progress) => {
  return updateObject(state, {
    utilidadPorSubrubro: updateObject(state.utilidadPorSubrubro, {
      utilidades: [],
      total: 0,
      error: null,
      loading: true,
      progress,
      estaCargado: false
    })
  });
};

const loadUtilidadPorSubrubroSuccess = (state, utilidades, total) => {
  return updateObject(state, {
    utilidadPorSubrubro: updateObject(state.utilidadPorSubrubro, {
      utilidades,
      total,
      error: null,
      loading: false,
      estaCargado: true
    })
  });
};

const loadUtilidadPorSubrubroFail = (state, error) => {
  return updateObject(state, {
    utilidadPorSubrubro: updateObject(state.utilidadPorSubrubro, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearUtilidadPorSubrubro = (state) => {
  return updateObject(state, {
    utilidadPorSubrubro: updateObject(state.utilidadPorSubrubro, {
      utilidades: [],
      total: 0,
      error: null,
      loading: false,
      estaCargado: false
    })
  });
};

const updateUtilidadesPorSubrubroOcultos = (state, utilidades) => {
  return updateObject(state, {
    ventasPorSubrubro: updateObject(state.ventasPorSubrubro, {
      utilidades
    })
  });
};
//#endregion

//#region load evolucion de ventas
const loadEvolucionDeVentasMensualStart = (state) => {
  return updateObject(state, {
    evolucionVentasMensual: updateObject(state.evolucionVentasMensual, {
      ventas: [],
      ventasDataTable: [],
      totalVentas: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadEvolucionDeVentasMensualSuccess = (state, ventas, ventasDataTable, totalVentas, totalUnidades) => {
  return updateObject(state, {
    evolucionVentasMensual: updateObject(state.evolucionVentasMensual, {
      ventas,
      ventasDataTable,
      totalVentas,
      totalUnidades,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionDeVentasMensualFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasMensual: updateObject(state.evolucionVentasMensual, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearEvolucionDeVentasMensual = (state) => {
  return updateObject(state, {
    evolucionVentasMensual: updateObject(state.evolucionVentasMensual, {
      ventas: [],
      ventasDataTable: [],
      totalVentas: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load ventas por categoria de cliente
const loadVentasPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    ventasPorCategoriaDeCliente: updateObject(state.ventasPorCategoriaDeCliente, {
      ventas: [],
      totalUnidades: 0,
      ventasDataTable: [],
      ventasNegativo: [],
      fecha: '',
      TEXTO_CHEQUES_EN_CARTERA_FAIL: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadVentasPorCategoriaDeClienteSuccess = (state, ventas, ventasNegativo, ventasDataTable, fecha, totalVentas, totalUnidades) => {
  return updateObject(state, {
    ventasPorCategoriaDeCliente: updateObject(state.ventasPorCategoriaDeCliente, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total: totalVentas,
      loading: false,
      estaCargado: true,
      totalUnidades
    })
  });
};

const loadVentasPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    ventasPorCategoriaDeCliente: updateObject(state.ventasPorCategoriaDeCliente, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    ventasPorCategoriaDeCliente: updateObject(state.ventasPorCategoriaDeCliente, {
      ventas: [],
      ventasDataTable: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region load Ventas Por Cliente De La Categoria
const loadVentasPorClienteDeLaCategoriaStart = (state) => {
  return updateObject(state, {
    ventasPorClienteDeLaCategoria: updateObject(state.ventasPorClienteDeLaCategoria, {
      ventas: [],
      loading: true,
      error: null
    })
  });
};

const loadVentasPorClienteDeLaCategoriaSuccess = (state, ventas) => {
  return updateObject(state, {
    ventasPorClienteDeLaCategoria: updateObject(state.ventasPorClienteDeLaCategoria, {
      ventas,
      loading: false
    })
  });
};

const loadVentasPorClienteDeLaCategoriaFail = (state, error) => {
  return updateObject(state, {
    ventasPorClienteDeLaCategoria: updateObject(state.ventasPorClienteDeLaCategoria, {
      error,
      loading: false
    })
  });
};

const clearVentasPorClienteDeLaCategoria = (state) => {
  return updateObject(state, {
    ventasPorClienteDeLaCategoria: updateObject(state.ventasPorClienteDeLaCategoria, {
      ventas: [],
      loading: false,
      error: null
    })
  });
};
//#endregion

//#region load evolucion ventas por categoria de cliente
const loadEvolucionVentasPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    evolucionVentasPorCategoriaDeCliente: updateObject(state.evolucionVentasPorCategoriaDeCliente, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasPorCategoriaDeClienteSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasPorCategoriaDeCliente: updateObject(state.evolucionVentasPorCategoriaDeCliente, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasPorCategoriaDeCliente: updateObject(state.evolucionVentasPorCategoriaDeCliente, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    evolucionVentasPorCategoriaDeCliente: updateObject(state.evolucionVentasPorCategoriaDeCliente, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por vendedores
const loadEvolucionVentasPorVendedoresStart = (state) => {
  return updateObject(state, {
    evolucionVentasPorVendedores: updateObject(state.evolucionVentasPorVendedores, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasPorVendedoresSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasPorVendedores: updateObject(state.evolucionVentasPorVendedores, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasPorVendedoresFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasPorVendedores: updateObject(state.evolucionVentasPorVendedores, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasPorVendedores = (state) => {
  return updateObject(state, {
    evolucionVentasPorVendedores: updateObject(state.evolucionVentasPorVendedores, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Provincias
const loadEvolucionVentasPorProvinciasStart = (state) => {
  return updateObject(state, {
    evolucionVentasPorProvincias: updateObject(state.evolucionVentasPorProvincias, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasPorProvinciasSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasPorProvincias: updateObject(state.evolucionVentasPorProvincias, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasPorProvinciasFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasPorProvincias: updateObject(state.evolucionVentasPorProvincias, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasPorProvincias = (state) => {
  return updateObject(state, {
    evolucionVentasPorProvincias: updateObject(state.evolucionVentasPorProvincias, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Zonas
const loadEvolucionVentasPorZonasStart = (state) => {
  return updateObject(state, {
    evolucionVentasPorZonas: updateObject(state.evolucionVentasPorZonas, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasPorZonasSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasPorZonas: updateObject(state.evolucionVentasPorZonas, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasPorZonasFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasPorZonas: updateObject(state.evolucionVentasPorZonas, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasPorZonas = (state) => {
  return updateObject(state, {
    evolucionVentasPorZonas: updateObject(state.evolucionVentasPorZonas, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Marca
const loadEvolucionVentasPorMarcaStart = (state) => {
  return updateObject(state, {
    evolucionVentasPorMarca: updateObject(state.evolucionVentasPorMarca, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasPorMarcaSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasPorMarca: updateObject(state.evolucionVentasPorMarca, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasPorMarcaFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasPorMarca: updateObject(state.evolucionVentasPorMarca, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasPorMarca = (state) => {
  return updateObject(state, {
    evolucionVentasPorMarca: updateObject(state.evolucionVentasPorMarca, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Rubro
const loadEvolucionVentasPorRubroStart = (state) => {
  return updateObject(state, {
    evolucionVentasPorRubro: updateObject(state.evolucionVentasPorRubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasPorRubroSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasPorRubro: updateObject(state.evolucionVentasPorRubro, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasPorRubroFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasPorRubro: updateObject(state.evolucionVentasPorRubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasPorRubro = (state) => {
  return updateObject(state, {
    evolucionVentasPorRubro: updateObject(state.evolucionVentasPorRubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Subrubro
const loadEvolucionVentasPorSubrubroStart = (state) => {
  return updateObject(state, {
    evolucionVentasPorSubrubro: updateObject(state.evolucionVentasPorSubrubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasPorSubrubroSuccess = (state, ventas, fechas, total, totalUnidades) => {
  return updateObject(state, {
    evolucionVentasPorSubrubro: updateObject(state.evolucionVentasPorSubrubro, {
      ventas,
      fechas,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasPorSubrubroFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasPorSubrubro: updateObject(state.evolucionVentasPorSubrubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasPorSubrubro = (state) => {
  return updateObject(state, {
    evolucionVentasPorSubrubro: updateObject(state.evolucionVentasPorSubrubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load ventas del mes por vendedores
const loadVentasDelMesPorVendedoresStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorVendedores: updateObject(state.ventasDelMesPorVendedores, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorVendedoresSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorVendedores: updateObject(state.ventasDelMesPorVendedores, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorVendedoresFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorVendedores: updateObject(state.ventasDelMesPorVendedores, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDelMesPorVendedores = (state) => {
  return updateObject(state, {
    ventasDelMesPorVendedores: updateObject(state.ventasDelMesPorVendedores, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas del mes por provincias
const loadVentasDelMesPorProvinciasStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorProvincias: updateObject(state.ventasDelMesPorProvincias, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorProvinciasSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorProvincias: updateObject(state.ventasDelMesPorProvincias, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorProvinciasFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorProvincias: updateObject(state.ventasDelMesPorProvincias, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDelMesPorProvincias = (state) => {
  return updateObject(state, {
    ventasDelMesPorProvincias: updateObject(state.ventasDelMesPorProvincias, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas del mes por zonas
const loadVentasDelMesPorZonasStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorZonas: updateObject(state.ventasDelMesPorZonas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorZonasSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorZonas: updateObject(state.ventasDelMesPorZonas, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorZonasFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorZonas: updateObject(state.ventasDelMesPorZonas, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDelMesPorZonas = (state) => {
  return updateObject(state, {
    ventasDelMesPorZonas: updateObject(state.ventasDelMesPorZonas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas del mes pivot grid
const loadVentasDelMesPivotGridStart = (state) => {
  return updateObject(state, {
    ventasDelMesPivotGrid: updateObject(state.ventasDelMesPivotGrid, {
      articulos: [],
      totalFacturacion: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPivotGridSuccess = (state, articulos, totalUnidades, totalFacturacion, fecha) => {
  return updateObject(state, {
    ventasDelMesPivotGrid: updateObject(state.ventasDelMesPivotGrid, {
      articulos,
      totalFacturacion,
      totalUnidades,
      loading: false,
      estaCargado: true,
      error: null,
      fecha
    })
  });
};

const loadVentasDelMesPivotGridFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPivotGrid: updateObject(state.ventasDelMesPivotGrid, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDelMesPivotGrid = (state) => {
  return updateObject(state, {
    ventasDelMesPivotGrid: updateObject(state.ventasDelMesPivotGrid, {
      articulos: [],
      totalFacturacion: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas del mes por marcas
const loadVentasDelMesPorMarcasStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorMarcas: updateObject(state.ventasDelMesPorMarcas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorMarcasSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorMarcas: updateObject(state.ventasDelMesPorMarcas, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorMarcasFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorMarcas: updateObject(state.ventasDelMesPorMarcas, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorMarcas = (state) => {
  return updateObject(state, {
    ventasDelMesPorMarcas: updateObject(state.ventasDelMesPorMarcas, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas del mes por rubros
const loadVentasDelMesPorRubrosStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorRubros: updateObject(state.ventasDelMesPorRubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorRubrosSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorRubros: updateObject(state.ventasDelMesPorRubros, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorRubrosFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorRubros: updateObject(state.ventasDelMesPorRubros, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorRubros = (state) => {
  return updateObject(state, {
    ventasDelMesPorRubros: updateObject(state.ventasDelMesPorRubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas del mes por subrubros
const loadVentasDelMesPorSubrubrosStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorSubrubros: updateObject(state.ventasDelMesPorSubrubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorSubrubrosSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorSubrubros: updateObject(state.ventasDelMesPorSubrubros, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorSubrubrosFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorSubrubros: updateObject(state.ventasDelMesPorSubrubros, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorSubrubros = (state) => {
  return updateObject(state, {
    ventasDelMesPorSubrubros: updateObject(state.ventasDelMesPorSubrubros, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region UTILIDAD
//#region load evolucion de utilidades
const loadEvolucionDeUtilidadMensualStart = (state) => {
  return updateObject(state, {
    evolucionUtilidadMensual: updateObject(state.evolucionUtilidadMensual, {
      utilidades: [],
      utilidadesDataTable: [],
      totalUtilidad: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadEvolucionDeUtilidadMensualSuccess = (state, utilidades, utilidadesDataTable, totalUtilidad) => {
  return updateObject(state, {
    evolucionUtilidadMensual: updateObject(state.evolucionUtilidadMensual, {
      utilidades,
      utilidadesDataTable,
      totalUtilidad,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionDeUtilidadMensualFail = (state, error) => {
  return updateObject(state, {
    evolucionUtilidadMensual: updateObject(state.evolucionUtilidadMensual, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearEvolucionDeUtilidadMensual = (state) => {
  return updateObject(state, {
    evolucionUtilidadMensual: updateObject(state.evolucionUtilidadMensual, initialState.evolucionUtilidadMensual)
  });
};
//#endregion

//#region load utilidad del mes por categoria de cliente
const loadUtilidadDelMesPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    utilidadDelMesPorCategoriaDeCliente: updateObject(state.utilidadDelMesPorCategoriaDeCliente, {
      utilidades: [],
      utilidadesDataTable: [],
      utilidadesNegativo: [],
      fecha: '',
      loading: true,
      estaCargado: false,
      error: null,
      total: 0
    })
  });
};

const loadUtilidadDelMesPorCategoriaDeClienteSuccess = (
  state,
  utilidades,
  utilidadesNegativo,
  utilidadesDataTable,
  fecha,
  totalUtilidad
) => {
  return updateObject(state, {
    utilidadDelMesPorCategoriaDeCliente: updateObject(state.utilidadDelMesPorCategoriaDeCliente, {
      utilidades,
      utilidadesNegativo,
      utilidadesDataTable,
      fecha,
      total: totalUtilidad,
      loading: false,
      estaCargado: true
    })
  });
};

const loadUtilidadDelMesPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    utilidadDelMesPorCategoriaDeCliente: updateObject(state.utilidadDelMesPorCategoriaDeCliente, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearUtilidadDelMesPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    utilidadDelMesPorCategoriaDeCliente: updateObject(
      state.utilidadDelMesPorCategoriaDeCliente,
      initialState.utilidadDelMesPorCategoriaDeCliente
    )
  });
};
//#endregion

//#region load utilidades del mes por vendedores
const loadUtilidadDelMesPorVendedoresStart = (state) => {
  return updateObject(state, {
    utilidadDelMesPorVendedores: updateObject(state.utilidadDelMesPorVendedores, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadUtilidadDelMesPorVendedoresSuccess = (
  state,
  utilidades,
  utilidadesNegativo,
  utilidadesDataTable,
  total,
  fecha
) => {
  return updateObject(state, {
    utilidadDelMesPorVendedores: updateObject(state.utilidadDelMesPorVendedores, {
      utilidades,
      utilidadesNegativo,
      utilidadesDataTable,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadUtilidadDelMesPorVendedoresFail = (state, error) => {
  return updateObject(state, {
    utilidadDelMesPorVendedores: updateObject(state.utilidadDelMesPorVendedores, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearUtilidadDelMesPorVendedores = (state) => {
  return updateObject(state, {
    utilidadDelMesPorVendedores: updateObject(state.utilidadDelMesPorVendedores, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load utilidades del mes por provincias
const loadUtilidadDelMesPorProvinciasStart = (state) => {
  return updateObject(state, {
    utilidadDelMesPorProvincias: updateObject(state.utilidadDelMesPorProvincias, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadUtilidadDelMesPorProvinciasSuccess = (
  state,
  utilidades,
  utilidadesNegativo,
  utilidadesDataTable,
  total,
  fecha
) => {
  return updateObject(state, {
    utilidadDelMesPorProvincias: updateObject(state.utilidadDelMesPorProvincias, {
      utilidades,
      utilidadesNegativo,
      utilidadesDataTable,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadUtilidadDelMesPorProvinciasFail = (state, error) => {
  return updateObject(state, {
    utilidadDelMesPorProvincias: updateObject(state.utilidadDelMesPorProvincias, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearUtilidadDelMesPorProvincias = (state) => {
  return updateObject(state, {
    utilidadDelMesPorProvincias: updateObject(state.utilidadDelMesPorProvincias, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load utilidades del mes por zonas
const loadUtilidadDelMesPorZonasStart = (state) => {
  return updateObject(state, {
    utilidadDelMesPorZonas: updateObject(state.utilidadDelMesPorZonas, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadUtilidadDelMesPorZonasSuccess = (
  state,
  utilidades,
  utilidadesNegativo,
  utilidadesDataTable,
  total,
  fecha
) => {
  return updateObject(state, {
    utilidadDelMesPorZonas: updateObject(state.utilidadDelMesPorZonas, {
      utilidades,
      utilidadesNegativo,
      utilidadesDataTable,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadUtilidadDelMesPorZonasFail = (state, error) => {
  return updateObject(state, {
    utilidadDelMesPorZonas: updateObject(state.utilidadDelMesPorZonas, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearUtilidadDelMesPorZonas = (state) => {
  return updateObject(state, {
    utilidadDelMesPorZonas: updateObject(state.utilidadDelMesPorZonas, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load utilidades del mes por marcas
const loadUtilidadDelMesPorMarcasStart = (state) => {
  return updateObject(state, {
    utilidadDelMesPorMarcas: updateObject(state.utilidadDelMesPorMarcas, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadUtilidadDelMesPorMarcasSuccess = (
  state,
  utilidades,
  utilidadesNegativo,
  utilidadesDataTable,
  total,
  fecha
) => {
  return updateObject(state, {
    utilidadDelMesPorMarcas: updateObject(state.utilidadDelMesPorMarcas, {
      utilidades,
      utilidadesNegativo,
      utilidadesDataTable,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadUtilidadDelMesPorMarcasFail = (state, error) => {
  return updateObject(state, {
    utilidadDelMesPorMarcas: updateObject(state.utilidadDelMesPorMarcas, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearUtilidadDelMesPorMarcas = (state) => {
  return updateObject(state, {
    utilidadDelMesPorMarcas: updateObject(state.utilidadDelMesPorMarcas, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load utilidades del mes por rubros
const loadUtilidadDelMesPorRubrosStart = (state) => {
  return updateObject(state, {
    utilidadDelMesPorRubros: updateObject(state.utilidadDelMesPorRubros, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadUtilidadDelMesPorRubrosSuccess = (
  state,
  utilidades,
  utilidadesNegativo,
  utilidadesDataTable,
  total,
  fecha
) => {
  return updateObject(state, {
    utilidadDelMesPorRubros: updateObject(state.utilidadDelMesPorRubros, {
      utilidades,
      utilidadesNegativo,
      utilidadesDataTable,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadUtilidadDelMesPorRubrosFail = (state, error) => {
  return updateObject(state, {
    utilidadDelMesPorRubros: updateObject(state.utilidadDelMesPorRubros, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearUtilidadDelMesPorRubros = (state) => {
  return updateObject(state, {
    utilidadDelMesPorRubros: updateObject(state.utilidadDelMesPorRubros, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load utilidades del mes por subrubros
const loadUtilidadDelMesPorSubrubrosStart = (state) => {
  return updateObject(state, {
    utilidadDelMesPorSubrubros: updateObject(state.utilidadDelMesPorSubrubros, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadUtilidadDelMesPorSubrubrosSuccess = (
  state,
  utilidades,
  utilidadesNegativo,
  utilidadesDataTable,
  total,
  fecha
) => {
  return updateObject(state, {
    utilidadDelMesPorSubrubros: updateObject(state.utilidadDelMesPorSubrubros, {
      utilidades,
      utilidadesNegativo,
      utilidadesDataTable,
      total,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadUtilidadDelMesPorSubrubrosFail = (state, error) => {
  return updateObject(state, {
    utilidadDelMesPorSubrubros: updateObject(state.utilidadDelMesPorSubrubros, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearUtilidadDelMesPorSubrubros = (state) => {
  return updateObject(state, {
    utilidadDelMesPorSubrubros: updateObject(state.utilidadDelMesPorSubrubros, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load evolucion ventas por categoria de cliente
const loadEvolucionUtilidadPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorCategoriaDeCliente: updateObject(state.evolucionUtilidadPorCategoriaDeCliente, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionUtilidadPorCategoriaDeClienteSuccess = (state, utilidades, fechas, total) => {
  return updateObject(state, {
    evolucionUtilidadPorCategoriaDeCliente: updateObject(state.evolucionUtilidadPorCategoriaDeCliente, {
      utilidades,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionUtilidadPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    evolucionUtilidadPorCategoriaDeCliente: updateObject(state.evolucionUtilidadPorCategoriaDeCliente, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionUtilidadPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorCategoriaDeCliente: updateObject(state.evolucionUtilidadPorCategoriaDeCliente, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion utilidades por vendedores
const loadEvolucionUtilidadPorVendedoresStart = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorVendedores: updateObject(state.evolucionUtilidadPorVendedores, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionUtilidadPorVendedoresSuccess = (state, utilidades, fechas, total) => {
  return updateObject(state, {
    evolucionUtilidadPorVendedores: updateObject(state.evolucionUtilidadPorVendedores, {
      utilidades,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionUtilidadPorVendedoresFail = (state, error) => {
  return updateObject(state, {
    evolucionUtilidadPorVendedores: updateObject(state.evolucionUtilidadPorVendedores, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionUtilidadPorVendedores = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorVendedores: updateObject(state.evolucionUtilidadPorVendedores, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion utilidades por Provincias
const loadEvolucionUtilidadPorProvinciasStart = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorProvincias: updateObject(state.evolucionUtilidadPorProvincias, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionUtilidadPorProvinciasSuccess = (state, utilidades, fechas, total) => {
  return updateObject(state, {
    evolucionUtilidadPorProvincias: updateObject(state.evolucionUtilidadPorProvincias, {
      utilidades,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionUtilidadPorProvinciasFail = (state, error) => {
  return updateObject(state, {
    evolucionUtilidadPorProvincias: updateObject(state.evolucionUtilidadPorProvincias, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionUtilidadPorProvincias = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorProvincias: updateObject(state.evolucionUtilidadPorProvincias, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion utilidades por Zonas
const loadEvolucionUtilidadPorZonasStart = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorZonas: updateObject(state.evolucionUtilidadPorZonas, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionUtilidadPorZonasSuccess = (state, utilidades, fechas, total) => {
  return updateObject(state, {
    evolucionUtilidadPorZonas: updateObject(state.evolucionUtilidadPorZonas, {
      utilidades,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionUtilidadPorZonasFail = (state, error) => {
  return updateObject(state, {
    evolucionUtilidadPorZonas: updateObject(state.evolucionUtilidadPorZonas, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionUtilidadPorZonas = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorZonas: updateObject(state.evolucionUtilidadPorZonas, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion utilidades por Marca
const loadEvolucionUtilidadPorMarcaStart = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorMarca: updateObject(state.evolucionUtilidadPorMarca, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionUtilidadPorMarcaSuccess = (state, utilidades, fechas, total) => {
  return updateObject(state, {
    evolucionUtilidadPorMarca: updateObject(state.evolucionUtilidadPorMarca, {
      utilidades,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionUtilidadPorMarcaFail = (state, error) => {
  return updateObject(state, {
    evolucionUtilidadPorMarca: updateObject(state.evolucionUtilidadPorMarca, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionUtilidadPorMarca = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorMarca: updateObject(state.evolucionUtilidadPorMarca, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion utilidades por Rubro
const loadEvolucionUtilidadPorRubroStart = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorRubro: updateObject(state.evolucionUtilidadPorRubro, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionUtilidadPorRubroSuccess = (state, utilidades, fechas, total) => {
  return updateObject(state, {
    evolucionUtilidadPorRubro: updateObject(state.evolucionUtilidadPorRubro, {
      utilidades,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionUtilidadPorRubroFail = (state, error) => {
  return updateObject(state, {
    evolucionUtilidadPorRubro: updateObject(state.evolucionUtilidadPorRubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionUtilidadPorRubro = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorRubro: updateObject(state.evolucionUtilidadPorRubro, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion utilidades por Subrubro
const loadEvolucionUtilidadPorSubrubroStart = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorSubrubro: updateObject(state.evolucionUtilidadPorSubrubro, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionUtilidadPorSubrubroSuccess = (state, utilidades, fechas, total) => {
  return updateObject(state, {
    evolucionUtilidadPorSubrubro: updateObject(state.evolucionUtilidadPorSubrubro, {
      utilidades,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionUtilidadPorSubrubroFail = (state, error) => {
  return updateObject(state, {
    evolucionUtilidadPorSubrubro: updateObject(state.evolucionUtilidadPorSubrubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionUtilidadPorSubrubro = (state) => {
  return updateObject(state, {
    evolucionUtilidadPorSubrubro: updateObject(state.evolucionUtilidadPorSubrubro, {
      utilidades: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#endregion UTILIDAD

//#region cobranzas del día
const cobranzasDelDiaStart = (state) => {
  return updateObject(state, {
    cobranzasDelDia: updateObject(state.cobranzasDelDia, {
      loading: true,
      error: null,
      diaActual: 0,
      promedio30Dias: 0
    })
  });
};

const cobranzasDelDiaRequest = (state, loadingRequest) => {
  return updateObject(state, {
    cobranzasDelDia: updateObject(state.cobranzasDelDia, {
      loadingRequest
    })
  });
};

const cobranzasDelDiaSuccess = (state, diaActual, promedio30Dias) => {
  return updateObject(state, {
    cobranzasDelDia: updateObject(state.cobranzasDelDia, {
      loading: false,
      error: null,
      diaActual: diaActual,
      promedio30Dias: promedio30Dias,
      estaCargado: true
    })
  });
};

const cobranzasDelDiaFail = (state, error) => {
  return updateObject(state, {
    cobranzasDelDia: updateObject(state.cobranzasDelDia, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearCobranzasDelDia = (state) => {
  return updateObject(state, {
    cobranzasDelDia: updateObject(state.cobranzasDelDia, {
      loading: false,
      error: null,
      diaActual: 0,
      promedio30Dias: 0,
      loadingRequest: false,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion de ventas interanual
const setItem = (state, anio, ventas, ventasDataTable, totalVentas, loading, estaCargado, error) => {
  let items = state.evolucionVentasMensualInteranual.items;
  let findItem = items.find((item) => item.anio === anio);
  if (findItem !== undefined) {
    items = items.filter((item) => item.anio !== anio);
  }

  let newItem = {
    anio,
    ventas,
    ventasDataTable,
    totalVentas,
    loading,
    estaCargado,
    error
  };

  items.push(newItem);

  return items;
}

const loadEvolucionDeVentasMensualInteranualStart = (state, anio) => {
  let items = setItem(state, anio, [], [], 0, true, false, null);

  return updateObject(state, {
    evolucionVentasMensualInteranual: updateObject(state.evolucionVentasMensualInteranual, {
      items: items,
    })
  });
};

const loadEvolucionDeVentasMensualInteranualSuccess = (state, anio, ventas, ventasDataTable, totalVentas) => {
  let items = setItem(state, anio, ventas, ventasDataTable, totalVentas, false, true, null);

  return updateObject(state, {
    evolucionVentasMensualInteranual: updateObject(state.evolucionVentasMensualInteranual, {
      items: items,
    })
  });
};

const loadEvolucionDeVentasMensualInteranualFail = (state, anio, error) => {
  let items = setItem(state, anio, [], [], 0, false, true, error);

  return updateObject(state, {
    evolucionVentasMensualInteranual: updateObject(state.evolucionVentasMensualInteranual, {
      items: items,
    })
  });
};

const clearEvolucionDeVentasMensualInteranual = (state, periodo) => {

  const anio = periodo.getFullYear();
  const items = state.evolucionVentasMensualInteranual.items.filter((item) => item.anio !== anio);
  const periodos = state.evolucionVentasMensualInteranual.periodos.filter((per) => per.getFullYear() !== anio);

  return updateObject(state, {
    evolucionVentasMensualInteranual: updateObject(state.evolucionVentasMensualInteranual, {
      items: items,
      periodos: periodos,
    })
  });
};

const clearEvolucionesDeVentasMensualInteranual = (state) => {
  let periodos = state.evolucionVentasMensualInteranual.periodos;

  return updateObject(state, {
    evolucionVentasMensualInteranual: updateObject(state.evolucionVentasMensualInteranual, {
      items: [],
      periodos: periodos,
    })
  });
};

const agregarEvolucionDeVentasMensualInteranual = (state, periodo) => {
  let periodos = state.evolucionVentasMensualInteranual.periodos;
  periodos.push(periodo);
  return updateObject(state, {
    evolucionVentasMensualInteranual: updateObject(state.evolucionVentasMensualInteranual, {
      periodos: periodos,
    })
  });
}

const seleccionFechaVentasDelMesAnio = (state, mes, anio) => {
  return updateObject(state, {
    fechaVentasDelMes: { mes, anio },
    evolucionVentasMensualInteranual: updateObject(state.evolucionVentasMensualInteranual, {
      periodoSeleccionado: new Date(anio, mes - 1, 1),
    })
  });
}

const updateFiltroEvolucionesDeVentasMensualInteranual = (state, desde, hasta, aplicado) => {
  return updateObject(state, {
    evolucionVentasMensualInteranual: updateObject(state.evolucionVentasMensualInteranual, {
      filtroPeriodoSeleccionado: {
        aplicado,
        desde,
        hasta
      }
    })
  })
}

const clearFiltroEvolucionesDeVentasMensualInteranual = (state) => {
  return updateObject(state, {
    evolucionVentasMensualInteranual: updateObject(state.evolucionVentasMensualInteranual, {
      filtroPeriodoSeleccionado: {
        aplicado: false,
        desde: null,
        hasta: null
      }
    })
  })
}
//#endregion

//#region detalle cobranzas del día
const detalleCobranzasDelDiaStart = (state) => {
  return updateObject(state, {
    detalleCobranzasDelDia: updateObject(state.detalleCobranzasDelDia, {
      loading: true,
      error: null,
      cobranzas: []
    })
  });
};

const detalleCobranzasDelDiaSuccess = (state, cobranzas) => {
  return updateObject(state, {
    detalleCobranzasDelDia: updateObject(state.detalleCobranzasDelDia, {
      loading: false,
      error: null,
      cobranzas: cobranzas,
      estaCargado: true
    })
  });
};

const detalleCobranzasDelDiaFail = (state, error) => {
  return updateObject(state, {
    detalleCobranzasDelDia: updateObject(state.detalleCobranzasDelDia, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearDetalleCobranzasDelDia = (state) => {
  return updateObject(state, {
    detalleCobranzasDelDia: updateObject(state.detalleCobranzasDelDia, {
      loading: false,
      error: null,
      cobranzas: [],
      estaCargado: false
    })
  });
};
//#endregion

//#region deudas
const deudaDelMesPorOrdenamientoStart = (state, ordenamiento) => {
  return updateObject(state, {
    deudaDelMesPorCliente: updateObject(state.deudaDelMesPorCliente, {
      utilidades: [],
      utilidadesNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: "",
      ordenamiento,
      ejecucionIndicador: true,
    }),
  });
};

const deudaDelMesPorOrdenamientoSuccess = (state, deudas, deudasNegativo, deudasDataTable, total, fecha, ordenamiento, orden) => {
  let dataTable = deudasDataTable.map((deuda) => {
    return {
      id: deuda.id,
      mes: deuda.mes,
      anio: deuda.anio,
      name: `${deuda.nombre}`.trimStart().trimEnd().toUpperCase(),
      porcParticipacion: deuda.porcentajeDeParticipacion,
      monto: deuda.monto,
      creditoMaximo: deuda.creditoMaximo,
    }
  });

  return updateObject(state, {
    deudaDelMesPorCliente: updateObject(state.deudaDelMesPorCliente, {
      deudas,
      deudasNegativo,
      deudasDataTable: dataTable,
      total,
      loading: false,
      estaCargado: true,
      fecha,
      ordenamiento,
      ejecucionIndicador: true,
      orden,
    }),
  });
};

const deudaDelMesPorOrdenamientoFail = (state, error, ordenamiento) => {
  return updateObject(state, {
    deudaDelMesPorCliente: updateObject(state.deudaDelMesPorCliente, {
      error,
      loading: false,
      estaCargado: true,
      ordenamiento,
      ejecucionIndicador: true,
    }),
  });
};

const clearDeudaDelMesPorOrdenamiento = (state, ordenamiento) => {
  return updateObject(state, {
    deudaDelMesPorCliente: updateObject(state.deudaDelMesPorCliente, initialState.deudaDelMesPorCliente),
  });
};

const ejecucionDeudaDelMesPorOrdenamiento = (state, ejecucion) => {
  return updateObject(state, {
    deudaDelMesPorCliente: updateObject(state.deudaDelMesPorCliente, {
      ejecucionIndicador: ejecucion,
    }),
  });
}
//#endregion

//#region cheques depositados
const chequesDepositadosDelDiaStart = (state) => {

  return updateObject(state, {
    chequesDepositadosDelDia: updateObject(state.chequesDepositadosDelDia, {
      monto: 0,
      cantidadCheques: 0,
      loading: true,
      estaCargado: false,
      error: null,
    }),
  });
};

const chequesDepositadosDelDiaSuccess = (state, monto, cantidadCheques) => {
  return updateObject(state, {
    chequesDepositadosDelDia: updateObject(state.chequesDepositadosDelDia, {
      monto,
      cantidadCheques,
      loading: false,
      estaCargado: true,
    }),
  });
};

const chequesDepositadosDelDiaFail = (state, error) => {
  return updateObject(state, {
    chequesDepositadosDelDia: updateObject(state.chequesDepositadosDelDia, {
      error,
      loading: false,
      estaCargado: true,
    }),
  });
};

const clearChequesDepositadosDelDia = (state) => {
  return updateObject(state, {
    chequesDepositadosDelDia: updateObject(state.chequesDepositadosDelDia, initialState.chequesDepositadosDelDia),
  });
};
//#endregion

//#region notas de pedido
const totalNotasDePedidoStart = (state) => {

  return updateObject(state, {
    totalNotasDePedido: updateObject(state.totalNotasDePedido, {
      total: 0,
      totalConvertido: 0,
      monedaSecundaria: '',
      loading: true,
      estaCargado: false,
      error: null,
    }),
  });
};

const totalNotasDePedidoSuccess = (state, total, totalConvertido, monedaSecundaria) => {
  return updateObject(state, {
    totalNotasDePedido: updateObject(state.totalNotasDePedido, {
      total,
      totalConvertido,
      monedaSecundaria,
      loading: false,
      estaCargado: true,
    }),
  });
};

const totalNotasDePedidoFail = (state, error) => {
  return updateObject(state, {
    totalNotasDePedido: updateObject(state.totalNotasDePedido, {
      error,
      loading: false,
      estaCargado: true,
    }),
  });
};

const clearTotalNotasDePedido = (state) => {
  return updateObject(state, {
    totalNotasDePedido: updateObject(state.totalNotasDePedido, initialState.totalNotasDePedido),
  });
};

//#endregion

//#region detalle cobranzas del día
const detalleNotasDePedidoStart = (state) => {
  return updateObject(state, {
    detalleNotasDePedido: updateObject(state.detalleNotasDePedido, {
      loading: true,
      error: null,
      notasDePedido: []
    })
  });
};

const detalleNotasDePedidoSuccess = (state, notasDePedido) => {
  return updateObject(state, {
    detalleNotasDePedido: updateObject(state.detalleNotasDePedido, {
      loading: false,
      error: null,
      notasDePedido: notasDePedido,
      estaCargado: true
    })
  });
};

const detalleNotasDePedidoFail = (state, error) => {
  return updateObject(state, {
    detalleNotasDePedido: updateObject(state.detalleNotasDePedido, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearDetalleNotasDePedido = (state) => {
  return updateObject(state, {
    detalleNotasDePedido: updateObject(state.detalleNotasDePedido, {
      loading: false,
      error: null,
      notasDePedido: [],
      estaCargado: false
    })
  });
};
//#endregion

//#region ventas del mes a un cliente 
const ventasDelMesAUnClienteStart = (state) => {
  return updateObject(state, {
    ventasDelMesAUnCliente: updateObject(state.ventasDelMesAUnCliente, {
      loading: true,
      mesActual: 0,
      mesAnterior: 0,
      error: null
    })
  });
};

const ventasDelMesAUnClienteSuccess = (state, mesActual, mesAnterior) => {
  return updateObject(state, {
    ventasDelMesAUnCliente: updateObject(state.ventasDelMesAUnCliente, {
      loading: false,
      error: null,
      mesActual: mesActual,
      mesAnterior: mesAnterior,
      estaCargado: true
    })
  });
};

const ventasDelMesAUnClienteFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesAUnCliente: updateObject(state.ventasDelMesAUnCliente, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearVentasDelMesAUnCliente = (state) => {
  return updateObject(state, {
    ventasDelMesAUnCliente: updateObject(state.ventasDelMesAUnCliente, {
      loading: false,
      error: null,
      mesActual: 0,
      mesAnterior: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region notas de pedido del mes 
const loadPedidosMesComparativoStart = (state) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      loading: true,
    }),
  });
};

const loadPedidosMesComparativoSuccess = (state, transMesAnt, totalMesAnt, transMesAct, totalMesAct, porcTrans, porcTotal) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      transMesAnt,
      totalMesAnt,
      transMesAct,
      totalMesAct,
      porcTrans,
      porcTotal,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadPedidosMesComparativoFail = (state, error) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearPedidosMesComparativo = (state) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      transMesAnt: 0,
      totalMesAnt: 0,
      transMesAct: 0,
      totalMesAct: 0,
      porcTrans: 0,
      porcTotal: 0,
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};

//#endregion

//#region loadVentasPorVendedor
const loadVentasPorVendedorStart = (state) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      loading: true,
    }),
  });
};

const loadVentasPorVendedorSuccess = (state, unidadesVendidas, ventas, cantidad) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      unidadesVendidas,
      ventas,
      loading: false,
      cantidad,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadVentasPorVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearVentasPorVendedor = (state) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      unidadesVendidas: 0,
      ventas: 0,
      loading: false,
      error: null,
      estaCargado: false,
      unidadesVendidasPorCliente: 0,
      ventasPorCliente: 0,
      loadingPorCliente: false,
    }),
  });
};
//#endregion

//#region loadPedidosPendientes
const loadPedidosPendientesStart = (state) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      loading: true,
    }),
  });
};

const loadPedidosPendientesSuccess = (state, cantidad, total, prom30Dias) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      cantidad,
      total,
      prom30Dias,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadPedidosPendientesFail = (state, error) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearPedidosPendientes = (state) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      cantidad: 0,
      total: 0,
      prom30Dias: 0,
      loading: false,
      error: null,
      estaCargado: false,
      cantidadPorCliente: 0,
      totalPorCliente: 0,
      prom30DiasPorCliente: 0,
      loadingPorCliente: false,
    }),
  });
};
//#endregion

//#region loadEstadosPedidosPendientes
const loadEstadosPedidosPendientesStart = (state) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      loading: true,
    }),
  });
};

const loadEstadosPedidosPendientesSuccess = (state, pedidos, total) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      pedidos,
      loading: false,
      estaCargado: true,
      total,
      error: null,
    }),
  });
};

const loadEstadosPedidosPendientesFail = (state, error) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearEstadosPedidosPendientes = (state) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      pedidos: [],
      loading: false,
      error: null,
      estaCargado: false,
      total: 0,
    }),
  });
};
//#endregion

//#region loadChequesEnCartera
const loadChequesEnCarteraStart = (state) => {
  return updateObject(state, {
    chequesEnCartera: updateObject(state.chequesEnCartera, {
      loading: true,
    }),
  });
};

const loadChequesEnCarteraSuccess = (state, monto, cantidadCheques) => {
  return updateObject(state, {
    chequesEnCartera: updateObject(state.chequesEnCartera, {
      loading: false,
      monto,
      cantidadCheques,
      error: null,
    }),
  });
};

const loadChequesEnCarteraFail = (state, error) => {
  return updateObject(state, {
    chequesEnCartera: updateObject(state.chequesEnCartera, {
      monto: 0,
      cantidadCheques: 0,
      loading: false,
      error,
    }),
  });
};

const clearChequesEnCartera = (state) => {
  return updateObject(state, {
    chequesEnCartera: updateObject(state.chequesEnCartera, {
      monto: 0,
      cantidadCheques: 0,
      loading: false,
      error: null,
    }),
  });
};
//#endregion

//#region loadFacturasPendientes
const loadFacturasPendientesStart = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loading: true,
    }),
  });
};

const loadFacturasPendientesSuccess = (state, facturas, totalFacturas) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturas,
      totalFacturas,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadFacturasPendientesFail = (state, error) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loading: false,
      error,
    }),
  });
};

const clearFacturasPendientes = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturas: [],
      totalFacturas: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region Comprobantes Pedidos Pendientes
const loadComprobantesPedidosPendientesStart = (state) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      loading: true,
    }),
  });
};

const loadComprobantesPedidosPendientesSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      comprobantes,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComprobantesPedidosPendientesFail = (state, error) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      loading: false,
      estaCargado: false,
      error,
    }),
  });
};

const clearComprobantesPedidosPendientes = (state) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      comprobantes: [],
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region Comprobantes Facturas Pendientes
const loadComprobantesFacturasPendientesStart = (state) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      loading: true,
    }),
  });
};

const loadComprobantesFacturasPendientesSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      comprobantes,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComprobantesFacturasPendientesFail = (state, error) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      loading: false,
      error,
    }),
  });
};

const clearComprobantesFacturasPendientes = (state) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      comprobantes: [],
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};

//#endregion

//#region load evolucion de utilidades interanual
const setItemUtilidad = (state, anio, utilidades, utilidadesDataTable, totalUtilidades, loading, estaCargado, error) => {
  let items = state.evolucionUtilidadesMensualInteranual.items;
  let findItem = items.find((item) => item.anio === anio);

  if (findItem !== undefined) {
    items = items.filter((item) => item.anio !== anio);
  }

  let newItem = {
    anio,
    utilidades,
    utilidadesDataTable,
    totalUtilidades,
    loading,
    estaCargado,
    error
  };

  items.push(newItem);

  return items;
}

const loadEvolucionDeUtilidadesMensualInteranualStart = (state, anio) => {
  let items = setItemUtilidad(state, anio, [], [], 0, true, false, null);

  return updateObject(state, {
    evolucionUtilidadesMensualInteranual: updateObject(state.evolucionUtilidadesMensualInteranual, {
      items: items,
    })
  });
};

const loadEvolucionDeUtilidadesMensualInteranualSuccess = (state, anio, utilidades, utilidadesDataTable, totalUtilidades) => {
  let items = setItemUtilidad(state, anio, utilidades, utilidadesDataTable, totalUtilidades, false, true, null);

  return updateObject(state, {
    evolucionUtilidadesMensualInteranual: updateObject(state.evolucionUtilidadesMensualInteranual, {
      items: items,
    })
  });
};

const loadEvolucionDeUtilidadesMensualInteranualFail = (state, anio, error) => {
  let items = setItemUtilidad(state, anio, [], [], 0, false, true, error);

  return updateObject(state, {
    evolucionUtilidadesMensualInteranual: updateObject(state.evolucionUtilidadesMensualInteranual, {
      items: items,
    })
  });
};

const clearEvolucionDeUtilidadesMensualInteranual = (state, periodo) => {
  let anio = periodo.getFullYear();
  let items = state.evolucionUtilidadesMensualInteranual.items
    .filter((item) => item.anio !== anio);
  let periodos = state.evolucionUtilidadesMensualInteranual.periodos
    .filter((per) => per.getFullYear() !== periodo.getFullYear());

  return updateObject(state, {
    evolucionUtilidadesMensualInteranual: updateObject(state.evolucionUtilidadesMensualInteranual, {
      items: items,
      periodos: periodos,
    })
  });
};

const clearEvolucionesDeUtilidadesMensualInteranual = (state) => {
  let periodos = state.evolucionUtilidadesMensualInteranual.periodos;

  return updateObject(state, {
    evolucionUtilidadesMensualInteranual: updateObject(state.evolucionUtilidadesMensualInteranual, {
      items: [],
      periodos: periodos,
    })
  });
};

const agregarEvolucionDeUtilidadesMensualInteranual = (state, periodo) => {
  let periodos = state.evolucionUtilidadesMensualInteranual.periodos;
  periodos.push(periodo);

  return updateObject(state, {
    evolucionUtilidadesMensualInteranual: updateObject(state.evolucionUtilidadesMensualInteranual, {
      periodos: periodos,
    })
  });
}

const seleccionFechaUtilidadesDelMesAnio = (state, mes, anio) => {
  return updateObject(state, {
    fechaUtilidadDelMes: { mes, anio },
    evolucionUtilidadesMensualInteranual: updateObject(state.evolucionUtilidadesMensualInteranual, {
      periodoSeleccionado: new Date(anio, mes - 1, 1),
    })
  });
}
//#endregion

//#region VENTAS DESAGREGADAS

//#region Load Tipo de Ventas Desagregadas

const loadVentasDesagregadasStart = (state) => {
  return updateObject(state, {
    tipoVentasDesagregadas: updateObject(state.tipoVentasDesagregadas, {
      loading: true,
      tipos: [],
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasDesagregadasSuccess = (state, tipos) => {
  return updateObject(state, {
    tipoVentasDesagregadas: updateObject(state.tipoVentasDesagregadas, {
      loading: false,
      error: null,
      tipos,
      estaCargado: true
    })
  });
};

const loadVentasDesagregadasFail = (state, error) => {
  return updateObject(state, {
    tipoVentasDesagregadas: updateObject(state.tipoVentasDesagregadas, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearVentasDesagregadas = (state) => {
  return updateObject(state, {
    tipoVentasDesagregadas: updateObject(state.tipoVentasDesagregadas, {
      loading: false,
      error: null,
      tipos: [],
      estaCargado: false
    })
  });
};
//#endregion

//#region Ventas por Unidades de Negocio
const loadVentasDelMesPorUnidadesDeNegociosStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorUnidadesDeNegocios: updateObject(state.ventasDelMesPorUnidadesDeNegocios, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorUnidadesDeNegociosSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorUnidadesDeNegocios: updateObject(state.ventasDelMesPorUnidadesDeNegocios, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorUnidadesDeNegociosFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorUnidadesDeNegocios: updateObject(state.ventasDelMesPorUnidadesDeNegocios, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorUnidadesDeNegocios = (state) => {
  return updateObject(state, {
    ventasDelMesPorUnidadesDeNegocios: updateObject(state.ventasDelMesPorUnidadesDeNegocios, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};



//#endregion

//#region Ventas por Canales
const loadVentasDelMesPorCanalesStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorCanales: updateObject(state.ventasDelMesPorCanales, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorCanalesSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorCanales: updateObject(state.ventasDelMesPorCanales, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorCanalesFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorCanales: updateObject(state.ventasDelMesPorCanales, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorCanales = (state) => {
  return updateObject(state, {
    ventasDelMesPorCanales: updateObject(state.ventasDelMesPorCanales, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};



//#endregion

//#region Ventas por Grupos De Compras
const loadVentasDelMesPorGruposDeComprasStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorGruposDeCompras: updateObject(state.ventasDelMesPorGruposDeCompras, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorGruposDeComprasSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorGruposDeCompras: updateObject(state.ventasDelMesPorGruposDeCompras, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorGruposDeComprasFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorGruposDeCompras: updateObject(state.ventasDelMesPorGruposDeCompras, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorGruposDeCompras = (state) => {
  return updateObject(state, {
    ventasDelMesPorGruposDeCompras: updateObject(state.ventasDelMesPorGruposDeCompras, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};



//#endregion

//#region Ventas por Responsables
const loadVentasDelMesPorResponsablesStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorResponsables: updateObject(state.ventasDelMesPorResponsables, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorResponsablesSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorResponsables: updateObject(state.ventasDelMesPorResponsables, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorResponsablesFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorResponsables: updateObject(state.ventasDelMesPorResponsables, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorResponsables = (state) => {
  return updateObject(state, {
    ventasDelMesPorResponsables: updateObject(state.ventasDelMesPorResponsables, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

//#endregion

//#region Ventas por Clientes
const loadVentasDelMesPorClientesStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorClientes: updateObject(state.ventasDelMesPorClientes, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorClientesSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorClientes: updateObject(state.ventasDelMesPorClientes, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorClientesFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorClientes: updateObject(state.ventasDelMesPorClientes, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorClientes = (state) => {
  return updateObject(state, {
    ventasDelMesPorClientes: updateObject(state.ventasDelMesPorClientes, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

//#endregion

//#region Ventas por Articulos
const loadVentasDelMesPorArticulosStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorArticulos: updateObject(state.ventasDelMesPorArticulos, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorArticulosSuccess = (state, ventas, ventasNegativo, total, fecha, totalUnidades) => {
  return updateObject(state, {
    ventasDelMesPorArticulos: updateObject(state.ventasDelMesPorArticulos, {
      ventas,
      ventasNegativo,
      total,
      totalUnidades,
      loading: false,
      estaCargado: true,
      fecha
    })
  });
};

const loadVentasDelMesPorArticulosFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorArticulos: updateObject(state.ventasDelMesPorArticulos, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDelMesPorArticulos = (state) => {
  return updateObject(state, {
    ventasDelMesPorArticulos: updateObject(state.ventasDelMesPorArticulos, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      totalUnidades: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#endregion

//#region EVOLUCION VENTAS POR PERIODOS

const updateEvolucionPorMarca = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorMarca: updateObject(state.evolucionVentasPorPeriodosPorMarca, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorRubro = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorRubro: updateObject(state.evolucionVentasPorPeriodosPorRubro, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorArticulo = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorArticulo: updateObject(state.evolucionVentasPorPeriodosPorArticulo, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorCanal = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorCanal: updateObject(state.evolucionVentasPorPeriodosPorCanal, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorCliente = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorCliente: updateObject(state.evolucionVentasPorPeriodosPorCliente, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorGrupoCompra = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorGrupoCompra: updateObject(state.evolucionVentasPorPeriodosPorGrupoCompra, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorProvincia = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorProvincia: updateObject(state.evolucionVentasPorPeriodosPorProvincia, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorResponsable = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorResponsable: updateObject(state.evolucionVentasPorPeriodosPorResponsable, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorUnidadNegocio = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorUnidadNegocio: updateObject(state.evolucionVentasPorPeriodosPorUnidadNegocio, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorVendedor = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorVendedor: updateObject(state.evolucionVentasPorPeriodosPorVendedor, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorZona = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorZona: updateObject(state.evolucionVentasPorPeriodosPorZona, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorCategoriaCliente = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorCategoriaCliente: updateObject(state.evolucionVentasPorPeriodosPorCategoriaCliente, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateEvolucionPorSubrubro = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataEvolucionVentasPorPeriodos: clear,
    evolucionVentasPorPeriodosPorSubrubro: updateObject(state.evolucionVentasPorPeriodosPorSubrubro, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

export const updateFiltroEvolucionesDeVentasPorPeriodos = (state, mesDesde, mesHasta, anios, dataSelectedId, aplicado, userSelected) => {
  return updateObject(state, {
    filtroEvolucionVentasPorPeriodos: updateObject(state.filtroEvolucionVentasPorPeriodos, {
      mesDesde,
      mesHasta,
      aniosSelected: anios,
      dataSelectedId,
      aplicado,
      userSelected,
    })
  });
}

export const updateFiltroVentasComparativasPorPeriodos = (state, mesDesde, mesHasta, anios, dataSelectedId, aplicado, userSelected) => {
  return updateObject(state, {
    filtroVentasComparativasPorPeriodos: updateObject(state.filtroVentasComparativasPorPeriodos, {
      mesDesde,
      mesHasta,
      aniosSelected: anios,
      dataSelectedId,
      aplicado,
      userSelected,
    })
  });
}

export const updateFiltroVentasComparativasPorMes = (state, mesDesde, mesHasta, anios, dataSelectedId, aplicado, userSelected) => {
  return updateObject(state, {
    filtroVentasComparativasPorMes: updateObject(state.filtroVentasComparativasPorMes, {
      mesDesde,
      mesHasta,
      aniosSelected: anios,
      dataSelectedId,
      aplicado,
      userSelected,
    })
  });
}

const loadEvolucionVentasPorPeriodosPorTipoVentaStart = (state, tipoVenta) => {
  if (tipoVenta === 'Marca')
    return updateEvolucionPorMarca(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Rubro')
    return updateEvolucionPorRubro(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Articulo')
    return updateEvolucionPorArticulo(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Canal')
    return updateEvolucionPorCanal(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Cliente')
    return updateEvolucionPorCliente(state, [], [], 0, true, null, false);
  if (tipoVenta === 'GrupoDeCompra')
    return updateEvolucionPorGrupoCompra(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Provincia')
    return updateEvolucionPorProvincia(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Responsable')
    return updateEvolucionPorResponsable(state, [], [], 0, true, null, false);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateEvolucionPorUnidadNegocio(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Vendedor')
    return updateEvolucionPorVendedor(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Zona')
    return updateEvolucionPorZona(state, [], [], 0, true, null, false);
  if (tipoVenta === 'CategoriaCliente')
    return updateEvolucionPorCategoriaCliente(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Subrubro')
    return updateEvolucionPorSubrubro(state, [], [], 0, true, null, false);
};

const loadEvolucionVentasPorPeriodosPorTipoVentaSuccess = (state, tipoVenta, ventas, fechas, total) => {
  if (tipoVenta === 'Marca')
    return updateEvolucionPorMarca(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Rubro')
    return updateEvolucionPorRubro(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Articulo')
    return updateEvolucionPorArticulo(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Canal')
    return updateEvolucionPorCanal(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Cliente')
    return updateEvolucionPorCliente(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'GrupoDeCompra')
    return updateEvolucionPorGrupoCompra(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Provincia')
    return updateEvolucionPorProvincia(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Responsable')
    return updateEvolucionPorResponsable(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateEvolucionPorUnidadNegocio(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Vendedor')
    return updateEvolucionPorVendedor(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Zona')
    return updateEvolucionPorZona(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'CategoriaCliente')
    return updateEvolucionPorCategoriaCliente(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Subrubro')
    return updateEvolucionPorSubrubro(state, ventas, fechas, total, false, null, true);
};

const loadEvolucionVentasPorPeriodosPorTipoVentaFail = (state, tipoVenta, error) => {
  if (tipoVenta === 'Marca')
    return updateEvolucionPorMarca(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Rubro')
    return updateEvolucionPorRubro(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Articulo')
    return updateEvolucionPorArticulo(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Canal')
    return updateEvolucionPorCanal(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Cliente')
    return updateEvolucionPorCliente(state, [], [], 0, false, error, true);
  if (tipoVenta === 'GrupoDeCompra')
    return updateEvolucionPorGrupoCompra(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Provincia')
    return updateEvolucionPorProvincia(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Responsable')
    return updateEvolucionPorResponsable(state, [], [], 0, false, error, true);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateEvolucionPorUnidadNegocio(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Vendedor')
    return updateEvolucionPorVendedor(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Zona')
    return updateEvolucionPorZona(state, [], [], 0, false, error, true);
  if (tipoVenta === 'CategoriaCliente')
    return updateEvolucionPorCategoriaCliente(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Subrubro')
    return updateEvolucionPorSubrubro(state, [], [], 0, false, error, true);
};

const clearEvolucionVentasPorPeriodosPorTipoVenta = (state, tipoVenta) => {
  if (tipoVenta === 'Marca')
    return updateEvolucionPorMarca(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'Rubro')
    return updateEvolucionPorRubro(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'Articulo')
    return updateEvolucionPorArticulo(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'Canal')
    return updateEvolucionPorCanal(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'Cliente')
    return updateEvolucionPorCliente(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'GrupoDeCompra')
    return updateEvolucionPorGrupoCompra(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'Provincia')
    return updateEvolucionPorProvincia(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'Responsable')
    return updateEvolucionPorResponsable(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateEvolucionPorUnidadNegocio(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'Vendedor')
    return updateEvolucionPorVendedor(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'Zona')
    return updateEvolucionPorZona(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'CategoriaCliente')
    return updateEvolucionPorCategoriaCliente(state, [], [], 0, false, null, false, true);
  if (tipoVenta === 'Subrubro')
    return updateEvolucionPorSubrubro(state, [], [], 0, false, null, false, true);
};

//#endregion

//#region VENTAS COMPARATIVAS

const updateVentasComparativasPorMarca = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorMarca: updateObject(state.ventasComparativasPorMarca, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorRubro = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorRubro: updateObject(state.ventasComparativasPorRubro, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorArticulo = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorArticulo: updateObject(state.ventasComparativasPorArticulo, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorCanal = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorCanal: updateObject(state.ventasComparativasPorCanal, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorCliente = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorCliente: updateObject(state.ventasComparativasPorCliente, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorGrupoCompra = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorGrupoCompra: updateObject(state.ventasComparativasPorGrupoCompra, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorProvincia = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorProvincia: updateObject(state.ventasComparativasPorProvincia, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorResponsable = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorResponsable: updateObject(state.ventasComparativasPorResponsable, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorUnidadNegocio = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorUnidadNegocio: updateObject(state.ventasComparativasPorUnidadNegocio, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorVendedor = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorVendedor: updateObject(state.ventasComparativasPorVendedor, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorZona = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorZona: updateObject(state.ventasComparativasPorZona, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorCategoriaCliente = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorCategoriaCliente: updateObject(state.ventasComparativasPorCategoriaCliente, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorSubrubro = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorPeriodos: clear,
    ventasComparativasPorSubrubro: updateObject(state.ventasComparativasPorSubrubro, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const loadVentasComparativasPorTipoVentaStart = (state, tipoVenta) => {
  if (tipoVenta === 'Marca')
    return updateVentasComparativasPorMarca(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Rubro')
    return updateVentasComparativasPorRubro(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Articulo')
    return updateVentasComparativasPorArticulo(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Canal')
    return updateVentasComparativasPorCanal(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Cliente')
    return updateVentasComparativasPorCliente(state, [], [], 0, true, null, false);
  if (tipoVenta === 'GrupoDeCompra')
    return updateVentasComparativasPorGrupoCompra(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Provincia')
    return updateVentasComparativasPorProvincia(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Responsable')
    return updateVentasComparativasPorResponsable(state, [], [], 0, true, null, false);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateVentasComparativasPorUnidadNegocio(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Vendedor')
    return updateVentasComparativasPorVendedor(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Zona')
    return updateVentasComparativasPorZona(state, [], [], 0, true, null, false);
  if (tipoVenta === 'CategoriaCliente')
    return updateVentasComparativasPorCategoriaCliente(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Subrubro')
    return updateVentasComparativasPorSubrubro(state, [], [], 0, true, null, false);
};

const loadVentasComparativasPorTipoVentaSuccess = (state, tipoVenta, ventas, fechas, total) => {
  if (tipoVenta === 'Marca')
    return updateVentasComparativasPorMarca(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Rubro')
    return updateVentasComparativasPorRubro(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Articulo')
    return updateVentasComparativasPorArticulo(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Canal')
    return updateVentasComparativasPorCanal(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Cliente')
    return updateVentasComparativasPorCliente(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'GrupoDeCompra')
    return updateVentasComparativasPorGrupoCompra(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Provincia')
    return updateVentasComparativasPorProvincia(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Responsable')
    return updateVentasComparativasPorResponsable(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateVentasComparativasPorUnidadNegocio(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Vendedor')
    return updateVentasComparativasPorVendedor(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Zona')
    return updateVentasComparativasPorZona(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'CategoriaCliente')
    return updateVentasComparativasPorCategoriaCliente(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Subrubro')
    return updateVentasComparativasPorSubrubro(state, ventas, fechas, total, false, null, true);
};

const loadVentasComparativasPorTipoVentaFail = (state, tipoVenta, error) => {
  if (tipoVenta === 'Marca')
    return updateVentasComparativasPorMarca(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Rubro')
    return updateVentasComparativasPorRubro(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Articulo')
    return updateVentasComparativasPorArticulo(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Canal')
    return updateVentasComparativasPorCanal(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Cliente')
    return updateVentasComparativasPorCliente(state, [], [], 0, false, error, true);
  if (tipoVenta === 'GrupoDeCompra')
    return updateVentasComparativasPorGrupoCompra(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Provincia')
    return updateVentasComparativasPorProvincia(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Responsable')
    return updateVentasComparativasPorResponsable(state, [], [], 0, false, error, true);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateVentasComparativasPorUnidadNegocio(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Vendedor')
    return updateVentasComparativasPorVendedor(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Zona')
    return updateVentasComparativasPorZona(state, [], [], 0, false, error, true);
  if (tipoVenta === 'CategoriaCliente')
    return updateVentasComparativasPorCategoriaCliente(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Subrubro')
    return updateVentasComparativasPorSubrubro(state, [], [], 0, false, error, true);
};

const clearVentasComparativasPorTipoVenta = (state, tipoVenta) => {
  if (tipoVenta === 'Marca')
    return updateVentasComparativasPorMarca(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Rubro')
    return updateVentasComparativasPorRubro(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Articulo')
    return updateVentasComparativasPorArticulo(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Canal')
    return updateVentasComparativasPorCanal(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Cliente')
    return updateVentasComparativasPorCliente(state, [], [], 0, false, null, false);
  if (tipoVenta === 'GrupoDeCompra')
    return updateVentasComparativasPorGrupoCompra(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Provincia')
    return updateVentasComparativasPorProvincia(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Responsable')
    return updateVentasComparativasPorResponsable(state, [], [], 0, false, null, false);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateVentasComparativasPorUnidadNegocio(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Vendedor')
    return updateVentasComparativasPorVendedor(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Zona')
    return updateVentasComparativasPorZona(state, [], [], 0, false, null, false);
  if (tipoVenta === 'CategoriaCliente')
    return updateVentasComparativasPorCategoriaCliente(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Subrubro')
    return updateVentasComparativasPorSubrubro(state, [], [], 0, false, null, false);
};

//#endregion

//#region VENTAS COMPARATIVAS POR MES

const updateVentasComparativasPorMesPorMarca = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorMarca: updateObject(state.ventasComparativasPorMesPorMarca, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorRubro = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorRubro: updateObject(state.ventasComparativasPorMesPorRubro, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorArticulo = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorArticulo: updateObject(state.ventasComparativasPorMesPorArticulo, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorCanal = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorCanal: updateObject(state.ventasComparativasPorMesPorCanal, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorCliente = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorCliente: updateObject(state.ventasComparativasPorMesPorCliente, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorGrupoCompra = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorGrupoCompra: updateObject(state.ventasComparativasPorMesPorGrupoCompra, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorProvincia = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorProvincia: updateObject(state.ventasComparativasPorMesPorProvincia, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorResponsable = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorResponsable: updateObject(state.ventasComparativasPorMesPorResponsable, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorUnidadNegocio = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorUnidadNegocio: updateObject(state.ventasComparativasPorMesPorUnidadNegocio, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorVendedor = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorVendedor: updateObject(state.ventasComparativasPorMesPorVendedor, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorZona = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorZona: updateObject(state.ventasComparativasPorMesPorZona, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorCategoriaCliente = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorCategoriaCliente: updateObject(state.ventasComparativasPorMesPorCategoriaCliente, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const updateVentasComparativasPorMesPorSubrubro = (state, ventas, fechas, total, loading, error, estaCargado, clear = false) => {
  return updateObject(state, {
    clearDataVentasComparativasPorMes: clear,
    ventasComparativasPorMesPorSubrubro: updateObject(state.ventasComparativasPorMesPorSubrubro, {
      ventas: ventas,
      fechas: fechas,
      total: total,
      loading: loading,
      error: error,
      estaCargado: estaCargado
    })
  });
}

const loadVentasComparativasPorMesPorTipoVentaStart = (state, tipoVenta) => {
  if (tipoVenta === 'Marca')
    return updateVentasComparativasPorMesPorMarca(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Rubro')
    return updateVentasComparativasPorMesPorRubro(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Articulo')
    return updateVentasComparativasPorMesPorArticulo(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Canal')
    return updateVentasComparativasPorMesPorCanal(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Cliente')
    return updateVentasComparativasPorMesPorCliente(state, [], [], 0, true, null, false);
  if (tipoVenta === 'GrupoDeCompra')
    return updateVentasComparativasPorMesPorGrupoCompra(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Provincia')
    return updateVentasComparativasPorMesPorProvincia(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Responsable')
    return updateVentasComparativasPorMesPorResponsable(state, [], [], 0, true, null, false);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateVentasComparativasPorMesPorUnidadNegocio(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Vendedor')
    return updateVentasComparativasPorMesPorVendedor(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Zona')
    return updateVentasComparativasPorMesPorZona(state, [], [], 0, true, null, false);
  if (tipoVenta === 'CategoriaCliente')
    return updateVentasComparativasPorMesPorCategoriaCliente(state, [], [], 0, true, null, false);
  if (tipoVenta === 'Subrubro')
    return updateVentasComparativasPorMesPorSubrubro(state, [], [], 0, true, null, false);
};

const loadVentasComparativasPorMesPorTipoVentaSuccess = (state, tipoVenta, ventas, fechas, total) => {
  if (tipoVenta === 'Marca')
    return updateVentasComparativasPorMesPorMarca(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Rubro')
    return updateVentasComparativasPorMesPorRubro(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Articulo')
    return updateVentasComparativasPorMesPorArticulo(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Canal')
    return updateVentasComparativasPorMesPorCanal(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Cliente')
    return updateVentasComparativasPorMesPorCliente(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'GrupoDeCompra')
    return updateVentasComparativasPorMesPorGrupoCompra(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Provincia')
    return updateVentasComparativasPorMesPorProvincia(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Responsable')
    return updateVentasComparativasPorMesPorResponsable(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateVentasComparativasPorMesPorUnidadNegocio(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Vendedor')
    return updateVentasComparativasPorMesPorVendedor(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Zona')
    return updateVentasComparativasPorMesPorZona(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'CategoriaCliente')
    return updateVentasComparativasPorMesPorCategoriaCliente(state, ventas, fechas, total, false, null, true);
  if (tipoVenta === 'Subrubro')
    return updateVentasComparativasPorMesPorSubrubro(state, ventas, fechas, total, false, null, true);
};

const loadVentasComparativasPorMesPorTipoVentaFail = (state, tipoVenta, error) => {
  if (tipoVenta === 'Marca')
    return updateVentasComparativasPorMesPorMarca(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Rubro')
    return updateVentasComparativasPorMesPorRubro(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Articulo')
    return updateVentasComparativasPorMesPorArticulo(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Canal')
    return updateVentasComparativasPorMesPorCanal(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Cliente')
    return updateVentasComparativasPorMesPorCliente(state, [], [], 0, false, error, true);
  if (tipoVenta === 'GrupoDeCompra')
    return updateVentasComparativasPorMesPorGrupoCompra(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Provincia')
    return updateVentasComparativasPorMesPorProvincia(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Responsable')
    return updateVentasComparativasPorMesPorResponsable(state, [], [], 0, false, error, true);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateVentasComparativasPorMesPorUnidadNegocio(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Vendedor')
    return updateVentasComparativasPorMesPorVendedor(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Zona')
    return updateVentasComparativasPorMesPorZona(state, [], [], 0, false, error, true);
  if (tipoVenta === 'CategoriaCliente')
    return updateVentasComparativasPorMesPorCategoriaCliente(state, [], [], 0, false, error, true);
  if (tipoVenta === 'Subrubro')
    return updateVentasComparativasPorMesPorSubrubro(state, [], [], 0, false, error, true);
};

const clearVentasComparativasPorMesPorTipoVenta = (state, tipoVenta) => {
  if (tipoVenta === 'Marca')
    return updateVentasComparativasPorMesPorMarca(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Rubro')
    return updateVentasComparativasPorMesPorRubro(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Articulo')
    return updateVentasComparativasPorMesPorArticulo(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Canal')
    return updateVentasComparativasPorMesPorCanal(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Cliente')
    return updateVentasComparativasPorMesPorCliente(state, [], [], 0, false, null, false);
  if (tipoVenta === 'GrupoDeCompra')
    return updateVentasComparativasPorMesPorGrupoCompra(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Provincia')
    return updateVentasComparativasPorMesPorProvincia(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Responsable')
    return updateVentasComparativasPorMesPorResponsable(state, [], [], 0, false, null, false);
  if (tipoVenta === 'UnidadDeNegocio')
    return updateVentasComparativasPorMesPorUnidadNegocio(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Vendedor')
    return updateVentasComparativasPorMesPorVendedor(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Zona')
    return updateVentasComparativasPorMesPorZona(state, [], [], 0, false, null, false);
  if (tipoVenta === 'CategoriaCliente')
    return updateVentasComparativasPorMesPorCategoriaCliente(state, [], [], 0, false, null, false);
  if (tipoVenta === 'Subrubro')
    return updateVentasComparativasPorMesPorSubrubro(state, [], [], 0, false, null, false);
};

//#endregion

//#region CUBO VENTAS
const loadCuboVentasDataStart = (state) => {
  return updateObject(state, {
    cuboVentasData: updateObject(state.cuboVentasData, {
      loading: true,
      error: null,
      datos: null,
      config: null,
      estaCargado: false,
      filtros: {},
    })
  });
};

const loadCuboVentasDataSuccess = (state, datos, config, filtros) => {
  return updateObject(state, {
    cuboVentasData: updateObject(state.cuboVentasData, {
      loading: false,
      error: null,
      datos,
      config,
      estaCargado: true,
      filtros,
    })
  });
};

const loadCuboVentasDataFail = (state, error) => {
  return updateObject(state, {
    cuboVentasData: updateObject(state.cuboVentasData, {
      loading: false,
      error: error,
      estaCargado: true,
    })
  });
};

const clearCuboVentasData = (state) => {
  return updateObject(state, {
    cuboVentasData: updateObject(state.cuboVentasData, {
      loading: false,
      error: null,
      datos: null,
      config: null,
      estaCargado: false,
      filtros: {},
    })
  });
};

const clearCuboVentasFiltros = (state) => {
  return updateObject(state, {
    cuboVentasFiltros: updateObject(state.cuboVentasFiltros, {
      desde: null,
      hasta: null,
      anioComparacion: null,
      filtroAplicado: true,
    })
  });
}

const updateCuboVentasFiltros = (state, desde, hasta, anioComparacion, filtroAplicado) => {
  return updateObject(state, {
    cuboVentasFiltros: updateObject(state.cuboVentasFiltros, {
      desde,
      hasta,
      anioComparacion,
      filtroAplicado
    })
  });
}
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECCION_DATA_EVOLUCION_VENTAS:
      return { ...state, dataEvolucionVentasSelected: action.selectionId };

    case actionTypes.SELECCION_DATA_VENTAS_DEL_MES_POR:
      return { ...state, dataVentasDelMesPorSelected: action.selectionId };

    case actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO:
      return seleccionFechaVentasDelMesAnio(state, action.mes, action.anio);

    case actionTypes.EXECUTE_CAMBIAR_VENDEDOR_ANALISIS_DE_VENTAS:
      return { ...state, vendedorVentasDelMesSelected: action.vendedorId };

    case actionTypes.PIE_VENTAS_COMPARATIVAS_START:
      return pieVentasComparativoStart(state);
    case actionTypes.PIE_VENTAS_COMPARATIVAS_SUCCESS:
      return pieVentasComparativoSuccess(state, action.mesActual, action.mesAnterior);
    case actionTypes.PIE_VENTAS_COMPARATIVAS_FAIL:
      return pieVentasComparativoFail(state, action.error);
    case actionTypes.CLEAR_PIE_VENTAS_COMPARATIVO:
      return clearPieVentasComparativo(state);

    case actionTypes.BAR_VENTAS_VENDEDOR_MENSUAL_START:
      return barVentasPorVendedorStart(state);
    case actionTypes.BAR_VENTAS_VENDEDOR_MENSUAL_SUCCESS:
      return barVentasPorVendedorSuccess(state, action.ventasPorVendedor, action.meses);
    case actionTypes.BAR_VENTAS_VENDEDOR_MENSUAL_FAIL:
      return barVentasPorVendedorFail(state, action.error);

    case actionTypes.TEXTO_CHEQUES_EN_CARTERA_START:
      return textoChequesEnCarteraStart(state);
    case actionTypes.TEXTO_CHEQUES_EN_CARTERA_SUCCESS:
      return textoChequesEnCarteraSuccess(state, action.monto, action.promedioDias);
    case actionTypes.TEXTO_CHEQUES_EN_CARTERA_FAIL:
      return textoChequesEnCarteraFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_CHEQUES_EN_CARTERA:
      return clearTextoChequesEnCartera(state);
    case actionTypes.TEXTO_CHEQUES_RECHAZADOS_START:
      return textoChequesRechazadosStart(state);
    case actionTypes.TEXTO_CHEQUES_RECHAZADOS_SUCCESS:
      return textoChequesRechazadosSuccess(state, action.monto, action.cantidadCheques);
    case actionTypes.TEXTO_CHEQUES_RECHAZADOS_FAIL:
      return textoChequesRechazadosFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_CHEQUES_RECHAZADOS:
      return clearTextoChequesRechazados(state);
    case actionTypes.DETALLE_CHEQUES_START:
      return detalleChequesStart(state);
    case actionTypes.DETALLE_CHEQUES_SUCCESS:
      return detalleChequesSuccess(state, action.cheques);
    case actionTypes.DETALLE_CHEQUES_FAIL:
      return detalleChequesFail(state, action.error);
    case actionTypes.CLEAR_DETALLE_CHEQUES:
      return clearDetalleCheques(state);
    case actionTypes.HEATMAP_VENTAS_DIARIAS_DEL_ANIO_START:
      return heatmapVentasPorDiaAnualStart(state);

    case actionTypes.HEATMAP_VENTAS_DIARIAS_DEL_ANIO_SUCCESS:
      return heatmapVentasPorDiaAnualSuccess(state, action.ventas);

    case actionTypes.HEATMAP_VENTAS_DIARIAS_DEL_ANIO_FAIL:
      return heatmapVentasPorDiaAnualFail(state, action.error);

    case actionTypes.LINE_CHEQUES_EN_CARTERA_START:
      return lineChequesEnCarteraStart(state);
    case actionTypes.LINE_CHEQUES_EN_CARTERA_SUCCESS:
      return lineChequesEnCarteraSuccess(state, action.cheques, action.totalCheques);
    case actionTypes.LINE_CHEQUES_EN_CARTERA_FAIL:
      return lineChequesEnCarteraFail(state, action.error);
    case actionTypes.CLEAR_LINE_CHEQUES_EN_CARTERA:
      return clearLineChequesEnCartera(state);

    case actionTypes.LINE_CHEQUES_RECHAZADOS_START:
      return lineChequesRechazadosStart(state);
    case actionTypes.LINE_CHEQUES_RECHAZADOS_SUCCESS:
      return lineChequesRechazadosSuccess(state, action.cheques, action.totalCheques);
    case actionTypes.LINE_CHEQUES_RECHAZADOS_FAIL:
      return lineChequesRechazadosFail(state, action.error);
    case actionTypes.CLEAR_LINE_CHEQUES_RECHAZADOS:
      return clearLineChequesRechazados(state);

    case actionTypes.LINE_CHEQUES_PROPIOS_START:
      return lineChequesPropiosStart(state);
    case actionTypes.LINE_CHEQUES_PROPIOS_SUCCESS:
      return lineChequesPropiosSuccess(state, action.cheques, action.totalCheques);
    case actionTypes.LINE_CHEQUES_PROPIOS_FAIL:
      return lineChequesPropiosFail(state, action.error);
    case actionTypes.CLEAR_LINE_CHEQUES_PROPIOS:
      return clearLineChequesPropios(state);

    case actionTypes.PIE_VENTAS_COMPARATIVO_VENDEDOR_START:
      return pieVentasComparativoVendedorStart(state);

    case actionTypes.PIE_VENTAS_COMPARATIVO_VENDEDOR_SUCCESS:
      return pieVentasComparativoVendedorSuccess(state, action.mesActual, action.mesAnterior);

    case actionTypes.PIE_VENTAS_COMPARATIVO_VENDEDOR_FAIL:
      return pieVentasComparativoVendedorFail(state, action.error);

    case actionTypes.VENTAS_DIARIAS_COMPARATIVO_START:
      return ventasDiariasComparativoStart(state);
    case actionTypes.VENTAS_DIARIAS_COMPARATIVO_REQUEST:
      return ventasDiariasComparativoRequest(state, action.loadingRequest);
    case actionTypes.VENTAS_DIARIAS_COMPARATIVO_SUCCESS:
      return ventasDiariasComparativoSuccess(state, action.diaActual, action.promedio30Dias);
    case actionTypes.VENTAS_DIARIAS_COMPARATIVO_FAIL:
      return ventasDiariasComparativoFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DIARIAS_COMPARATIVO:
      return clearVentasDiariasComparativo(state);

    case actionTypes.LINE_VENTAS_COMPARATIVO_START:
      return lineVentasComparativoStart(state);

    case actionTypes.LINE_VENTAS_COMPARATIVO_SUCCESS:
      return lineVentasComparativoSuccess(
        state,
        action.periodoActualDinero,
        action.periodoAnteriorDinero,
        action.periodoActualUnidades,
        action.periodoAnteriorUnidades,
        action.referenciasEjeX
      );

    case actionTypes.LINE_VENTAS_COMPARATIVO_FAIL:
      return lineVentasComparativoFail(state, action.error);

    case actionTypes.BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_START:
      return barVentasPorVendedorDelPeriodoComparativoStart(state);

    case actionTypes.BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_SUCCESS:
      return barVentasPorVendedorDelPeriodoComparativoSuccess(
        state,
        action.ventasPorVendedorPeriodoActual,
        action.ventasPorVendedorPeriodoAnterior,
        action.vendedores
      );

    case actionTypes.BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_FAIL:
      return barVentasPorVendedorDelPeriodoComparativoFail(state, action.error);

    case actionTypes.TEXTO_COBRANZAS_MENSUAL_START:
      return textoCobranzasMensualStart(state);
    case actionTypes.TEXTO_COBRANZAS_MENSUAL_SUCCESS:
      return textoCobranzasMensualSuccess(state, action.mesActual, action.mesAnterior);
    case actionTypes.TEXTO_COBRANZAS_MENSUAL_FAIL:
      return textoCobranzasMensualFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_COBRANZAS_MENSUAL:
      return clearTextoCobranzasMensual(state);

    case actionTypes.TEXTO_DEUDORES_POR_VENTAS_START:
      return textoPlazoMedioVencimientosStart(state);
    case actionTypes.TEXTO_DEUDORES_POR_VENTAS_SUCCESS:
      return textoPlazoMedioVencimientosSuccess(state, action.deuda, action.promedioDias, action.cobranzasSinImputar);
    case actionTypes.TEXTO_DEUDORES_POR_VENTAS_FAIL:
      return textoPlazoMedioVencimientosFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_DEUDORES_POR_VENTAS:
      return clearTextoPlazoMedioVencimientos(state);

    case actionTypes.TEXTO_PROMEDIO_DIAS_COBRANZAS_START:
      return textoPromDiasCobranzasStart(state);
    case actionTypes.TEXTO_PROMEDIO_DIAS_COBRANZAS_SUCCESS:
      return textoPromDiasCobranzasSuccess(state, action.dias, action.total);
    case actionTypes.TEXTO_PROMEDIO_DIAS_COBRANZAS_FAIL:
      return textoPromDiasCobranzasFail(state, action.error);
    case actionTypes.CLEAR_TEXTO_PROMEDIO_DIAS_COBRANZAS:
      return clearTextoPromDiasCobranzas(state);

    case actionTypes.TEXTO_PROMEDIO_COBRANZAS_START:
      return textoPromedioCobranzasStart(state);
    case actionTypes.TEXTO_PROMEDIO_COBRANZAS_SUCCESS:
      return textoPromedioCobranzasSuccess(state, action.promedio, action.cantidad);
    case actionTypes.TEXTO_PROMEDIO_COBRANZAS_FAIL:
      return textoPromedioCobranzasFail(state, action.error);

    case actionTypes.LOAD_DEUDORES_POR_VENTAS_START:
      return loadDeudoresPorVentasStart(state);
    case actionTypes.LOAD_DEUDORES_POR_VENTAS_SUCCESS:
      return loadDeudoresPorVentasSuccess(state, action.facturas, action.totalFacturas);
    case actionTypes.LOAD_DEUDORES_POR_VENTAS_FAIL:
      return loadDeudoresPorVentasFail(state, action.error);
    case actionTypes.CLEAR_DEUDORES_POR_VENTAS:
      return clearDeudoresPorVentas(state);

    case actionTypes.LOAD_COMPROBANTES_VENTAS_START:
      return loadComprobantesVentasStart(state);
    case actionTypes.LOAD_COMPROBANTES_VENTAS_SUCCESS:
      return loadComprobantesVentasSuccess(state, action.comprobantesVentas);
    case actionTypes.LOAD_COMPROBANTES_VENTAS_FAIL:
      return loadComprobantesVentasFail(state, action.error);

    case actionTypes.LOAD_COMPROBANTES_COBRANZAS_START:
      return loadComprobantesCobranzasStart(state);
    case actionTypes.LOAD_COMPROBANTES_COBRANZAS_SUCCESS:
      return loadComprobantesCobranzasSuccess(state, action.comprobantesCobranzas);
    case actionTypes.LOAD_COMPROBANTES_COBRANZAS_FAIL:
      return loadComprobantesCobranzasFail(state, action.error);

    case actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_INDICADORES_START:
      return loadCategoriasDelClienteIndicadoresStart(state);
    case actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_INDICADORES_SUCCESS:
      return loadCategoriasDelClienteIndicadoresSuccess(state, action.categorias);
    case actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_INDICADORES_FAIL:
      return loadCategoriasDelClienteIndicadoresFail(state, action.error);

    case actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_START:
      return loadCategoriasDelClienteStart(state);
    case actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_SUCCESS:
      return loadCategoriasDelClienteSuccess(state, action.categorias);
    case actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_FAIL:
      return loadCategoriasDelClienteFail(state, action.error);

    case actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_START:
      return loadDeudaPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadDeudaPorCategoriaDeClienteSuccess(state, action.categorias, action.total, action.valoresNegativos);
    case actionTypes.LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadDeudaPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_DEUDA_POR_CATEGORIA_DE_CLIENTE:
      return clearDeudaPorCategoriaDeCliente(state);

    case actionTypes.LOAD_VENTAS_POR_MARCA_START:
      return loadVentasPorMarcaStart(state);
    case actionTypes.LOAD_VENTAS_POR_MARCA_SUCCESS:
      return loadVentasPorMarcaSuccess(state, action.ventas, action.total);
    case actionTypes.LOAD_VENTAS_POR_MARCA_FAIL:
      return loadVentasPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_MARCA:
      return clearVentasPorMarca(state);

    case actionTypes.LOAD_VENTAS_POR_RUBRO_START:
      return loadVentasPorRubroStart(state);
    case actionTypes.LOAD_VENTAS_POR_RUBRO_SUCCESS:
      return loadVentasPorRubroSuccess(state, action.ventas, action.total);
    case actionTypes.LOAD_VENTAS_POR_RUBRO_FAIL:
      return loadVentasPorRubroFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_RUBRO:
      return clearVentasPorRubro(state);

    case actionTypes.LOAD_VENTAS_POR_SUBRUBRO_START:
      return loadVentasPorSubRubroStart(state);
    case actionTypes.LOAD_VENTAS_POR_SUBRUBRO_SUCCESS:
      return loadVentasPorSubRubroSuccess(state, action.ventas, action.total);
    case actionTypes.LOAD_VENTAS_POR_SUBRUBRO_FAIL:
      return loadVentasPorSubRubroFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_SUBRUBRO:
      return clearVentasPorSubRubro(state);

    case actionTypes.LOAD_UTILIDAD_POR_MARCA_START:
      return loadUtilidadPorMarcaStart(state);
    case actionTypes.LOAD_UTILIDAD_POR_MARCA_SUCCESS:
      return loadUtilidadPorMarcaSuccess(state, action.utilidades, action.total);
    case actionTypes.LOAD_UTILIDAD_POR_MARCA_FAIL:
      return loadUtilidadPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_POR_MARCA:
      return clearUtilidadPorMarca(state);
    case actionTypes.UPDATE_UTILIDADES_POR_MARCA_OCULTOS:
      return updateUtilidadesPorMarcaOcultos(state, action.utilidades);

    case actionTypes.LOAD_UTILIDAD_POR_RUBRO_START:
      return loadUtilidadPorRubroStart(state);
    case actionTypes.LOAD_UTILIDAD_POR_RUBRO_SUCCESS:
      return loadUtilidadPorRubroSuccess(state, action.utilidades, action.total);
    case actionTypes.LOAD_UTILIDAD_POR_RUBRO_FAIL:
      return loadUtilidadPorRubroFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_POR_RUBRO:
      return clearUtilidadPorRubro(state);
    case actionTypes.UPDATE_UTILIDADES_POR_RUBRO_OCULTOS:
      return updateUtilidadesPorRubroOcultos(state, action.utilidades);

    case actionTypes.LOAD_UTILIDAD_POR_SUBRUBRO_START:
      return loadUtilidadPorSubrubroStart(state, action.progress);
    case actionTypes.LOAD_UTILIDAD_POR_SUBRUBRO_SUCCESS:
      return loadUtilidadPorSubrubroSuccess(state, action.utilidades, action.total);
    case actionTypes.LOAD_UTILIDAD_POR_SUBRUBRO_FAIL:
      return loadUtilidadPorSubrubroFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_POR_SUBRUBRO:
      return clearUtilidadPorSubrubro(state);
    case actionTypes.UPDATE_UTILIDADES_POR_SUBRUBRO_OCULTOS:
      return updateUtilidadesPorSubrubroOcultos(state, action.utilidades);

    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_START:
      return loadEvolucionDeVentasMensualStart(state);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_SUCCESS:
      return loadEvolucionDeVentasMensualSuccess(state, action.ventas, action.ventasDataTable, action.totalVentas);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_FAIL:
      return loadEvolucionDeVentasMensualFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_VENTAS:
      return clearEvolucionDeVentasMensual(state);

    case actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_START:
      return loadVentasPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadVentasPorCategoriaDeClienteSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.totalVentas,
        action.totalUnidades
      );
    case actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadVentasPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE:
      return clearVentasPorCategoriaDeCliente(state);

    case actionTypes.LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_START:
      return loadVentasPorClienteDeLaCategoriaStart(state);
    case actionTypes.LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_SUCCESS:
      return loadVentasPorClienteDeLaCategoriaSuccess(state, action.ventas);
    case actionTypes.LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_FAIL:
      return loadVentasPorClienteDeLaCategoriaFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA:
      return clearVentasPorClienteDeLaCategoria(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_START:
      return loadEvolucionVentasPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadEvolucionVentasPorCategoriaDeClienteSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadEvolucionVentasPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE:
      return clearEvolucionVentasPorCategoriaDeCliente(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_START:
      return loadEvolucionVentasPorVendedoresStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_SUCCESS:
      return loadEvolucionVentasPorVendedoresSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_FAIL:
      return loadEvolucionVentasPorVendedoresFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_POR_VENDEDORES:
      return clearEvolucionVentasPorVendedores(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_START:
      return loadEvolucionVentasPorProvinciasStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_SUCCESS:
      return loadEvolucionVentasPorProvinciasSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_FAIL:
      return loadEvolucionVentasPorProvinciasFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_POR_PROVINCIAS:
      return clearEvolucionVentasPorProvincias(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_ZONAS_START:
      return loadEvolucionVentasPorZonasStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_ZONAS_SUCCESS:
      return loadEvolucionVentasPorZonasSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_ZONAS_FAIL:
      return loadEvolucionVentasPorZonasFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_POR_ZONAS:
      return clearEvolucionVentasPorZonas(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_MARCA_START:
      return loadEvolucionVentasPorMarcaStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_MARCA_SUCCESS:
      return loadEvolucionVentasPorMarcaSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_MARCA_FAIL:
      return loadEvolucionVentasPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_POR_MARCA:
      return clearEvolucionVentasPorMarca(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_RUBRO_START:
      return loadEvolucionVentasPorRubroStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_RUBRO_SUCCESS:
      return loadEvolucionVentasPorRubroSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_RUBRO_FAIL:
      return loadEvolucionVentasPorRubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_POR_RUBRO:
      return clearEvolucionVentasPorRubro(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_START:
      return loadEvolucionVentasPorSubrubroStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_SUCCESS:
      return loadEvolucionVentasPorSubrubroSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_FAIL:
      return loadEvolucionVentasPorSubrubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_POR_SUBRUBRO:
      return clearEvolucionVentasPorSubrubro(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_VENDEDOR_START:
      return loadVentasDelMesPorVendedoresStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorVendedoresSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha,
        action.totalUnidades
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_VENDEDOR_FAIL:
      return loadVentasDelMesPorVendedoresFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_VENDEDOR:
      return clearVentasDelMesPorVendedores(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_START:
      return loadVentasDelMesPorProvinciasStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_SUCCESS:
      return loadVentasDelMesPorProvinciasSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha,
        action.totalUnidades
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_FAIL:
      return loadVentasDelMesPorProvinciasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_PROVINCIA:
      return clearVentasDelMesPorProvincias(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_START:
      return loadVentasDelMesPorZonasStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_SUCCESS:
      return loadVentasDelMesPorZonasSuccess(state, action.ventas, action.ventasNegativo, action.total, action.fecha,
        action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_FAIL:
      return loadVentasDelMesPorZonasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_ZONA:
      return clearVentasDelMesPorZonas(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ARTICULO_START:
      return loadVentasDelMesPorArticulosStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ARTICULO_SUCCESS:
      return loadVentasDelMesPorArticulosSuccess(state, action.ventas, action.ventasNegativo, action.total, action.fecha,
        action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ARTICULO_FAIL:
      return loadVentasDelMesPorArticulosFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_ARTICULO:
      return clearVentasDelMesPorArticulos(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_PIVOT_GRID_START:
      return loadVentasDelMesPivotGridStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_PIVOT_GRID_SUCCESS:
      return loadVentasDelMesPivotGridSuccess(state, action.articulos, action.totalUnidades, action.totalFacturacion, action.fecha,
        action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_PIVOT_GRID_FAIL:
      return loadVentasDelMesPivotGridFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_PIVOT_GRID:
      return clearVentasDelMesPivotGrid(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_START:
      return loadVentasDelMesPorMarcasStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_SUCCESS:
      return loadVentasDelMesPorMarcasSuccess(state, action.ventas, action.ventasNegativo, action.total, action.fecha,
        action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_FAIL:
      return loadVentasDelMesPorMarcasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_MARCA:
      return clearVentasDelMesPorMarcas(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_START:
      return loadVentasDelMesPorRubrosStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_SUCCESS:
      return loadVentasDelMesPorRubrosSuccess(state, action.ventas, action.ventasNegativo, action.total, action.fecha,
        action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_FAIL:
      return loadVentasDelMesPorRubrosFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_RUBRO:
      return clearVentasDelMesPorRubros(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_START:
      return loadVentasDelMesPorSubrubrosStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_SUCCESS:
      return loadVentasDelMesPorSubrubrosSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.total,
        action.fecha,
        action.totalUnidades
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_FAIL:
      return loadVentasDelMesPorSubrubrosFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO:
      return clearVentasDelMesPorSubrubros(state);

    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_START:
      return loadEvolucionDeVentasMensualInteranualStart(state, action.anio);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_SUCCESS:
      return loadEvolucionDeVentasMensualInteranualSuccess(state, action.anio, action.ventas, action.ventasDataTable, action.totalVentas);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_FAIL:
      return loadEvolucionDeVentasMensualInteranualFail(state, action.anio, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_VENTAS_INTERANUAL:
      return clearEvolucionDeVentasMensualInteranual(state, action.periodo);
    case actionTypes.CLEAR_EVOLUCIONES_DE_VENTAS_INTERANUAL:
      return clearEvolucionesDeVentasMensualInteranual(state);
    case actionTypes.AGREGAR_EVOLUCION_DE_VENTAS_INTERANUAL:
      return agregarEvolucionDeVentasMensualInteranual(state, action.periodo);
    case actionTypes.UPDATE_FILTRO_EVOLUCION_DE_VENTAS_INTERANUAL:
      return updateFiltroEvolucionesDeVentasMensualInteranual(state, action.desde, action.hasta, action.aplicado);
    case actionTypes.CLEAR_FILTRO_EVOLUCION_DE_VENTAS_INTERANUAL:
      return clearFiltroEvolucionesDeVentasMensualInteranual(state);

    //#region UTILIDAD
    case actionTypes.SELECCION_DATA_UTILIDAD_DEL_MES_POR:
      return { ...state, dataUtilidadDelMesPorSelected: action.selectionId };
    case actionTypes.SELECCION_FECHA_UTILIDAD_DEL_MES_ANIO:
      return seleccionFechaUtilidadesDelMesAnio(state, action.mes, action.anio);
    case actionTypes.LOAD_EVOLUCION_DE_UTILIDAD_START:
      return loadEvolucionDeUtilidadMensualStart(state);
    case actionTypes.LOAD_EVOLUCION_DE_UTILIDAD_SUCCESS:
      return loadEvolucionDeUtilidadMensualSuccess(
        state,
        action.utilidades,
        action.utilidadesDataTable,
        action.totalUtilidad
      );
    case actionTypes.LOAD_EVOLUCION_DE_UTILIDAD_FAIL:
      return loadEvolucionDeUtilidadMensualFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_UTILIDAD:
      return clearEvolucionDeUtilidadMensual(state);

    case actionTypes.LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_START:
      return loadUtilidadDelMesPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadUtilidadDelMesPorCategoriaDeClienteSuccess(
        state,
        action.utilidades,
        action.utilidadesNegativo,
        action.utilidadesDataTable,
        action.fecha,
        action.totalUtilidad
      );
    case actionTypes.LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadUtilidadDelMesPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_POR_CATEGORIA_DE_CLIENTE:
      return clearUtilidadDelMesPorCategoriaDeCliente(state);

    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_START:
      return loadUtilidadDelMesPorVendedoresStart(state);
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_SUCCESS:
      return loadUtilidadDelMesPorVendedoresSuccess(
        state,
        action.utilidades,
        action.utilidadesNegativo,
        action.utilidadesDataTable,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_FAIL:
      return loadUtilidadDelMesPorVendedoresFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_VENDEDOR:
      return clearUtilidadDelMesPorVendedores(state);

    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_START:
      return loadUtilidadDelMesPorProvinciasStart(state);
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_SUCCESS:
      return loadUtilidadDelMesPorProvinciasSuccess(
        state,
        action.utilidades,
        action.utilidadesNegativo,
        action.utilidadesDataTable,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_FAIL:
      return loadUtilidadDelMesPorProvinciasFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_PROVINCIA:
      return clearUtilidadDelMesPorProvincias(state);

    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_ZONA_START:
      return loadUtilidadDelMesPorZonasStart(state);
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_ZONA_SUCCESS:
      return loadUtilidadDelMesPorZonasSuccess(
        state,
        action.utilidades,
        action.utilidadesNegativo,
        action.utilidadesDataTable,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_ZONA_FAIL:
      return loadUtilidadDelMesPorZonasFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_ZONA:
      return clearUtilidadDelMesPorZonas(state);

    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_MARCA_START:
      return loadUtilidadDelMesPorMarcasStart(state);
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_MARCA_SUCCESS:
      return loadUtilidadDelMesPorMarcasSuccess(
        state,
        action.utilidades,
        action.utilidadesNegativo,
        action.utilidadesDataTable,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_MARCA_FAIL:
      return loadUtilidadDelMesPorMarcasFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_MARCA:
      return clearUtilidadDelMesPorMarcas(state);

    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_RUBRO_START:
      return loadUtilidadDelMesPorRubrosStart(state);
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_RUBRO_SUCCESS:
      return loadUtilidadDelMesPorRubrosSuccess(
        state,
        action.utilidades,
        action.utilidadesNegativo,
        action.utilidadesDataTable,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_RUBRO_FAIL:
      return loadUtilidadDelMesPorRubrosFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_RUBRO:
      return clearUtilidadDelMesPorRubros(state);

    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_START:
      return loadUtilidadDelMesPorSubrubrosStart(state);
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_SUCCESS:
      return loadUtilidadDelMesPorSubrubrosSuccess(
        state,
        action.utilidades,
        action.utilidadesNegativo,
        action.utilidadesDataTable,
        action.total,
        action.fecha
      );
    case actionTypes.LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_FAIL:
      return loadUtilidadDelMesPorSubrubrosFail(state, action.error);
    case actionTypes.CLEAR_UTILIDAD_DEL_MES_POR_SUBRUBRO:
      return clearUtilidadDelMesPorSubrubros(state);

    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_START:
      return loadEvolucionUtilidadPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadEvolucionUtilidadPorCategoriaDeClienteSuccess(state, action.utilidades, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadEvolucionUtilidadPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE:
      return clearEvolucionUtilidadPorCategoriaDeCliente(state);

    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_START:
      return loadEvolucionUtilidadPorVendedoresStart(state);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_SUCCESS:
      return loadEvolucionUtilidadPorVendedoresSuccess(state, action.utilidades, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_FAIL:
      return loadEvolucionUtilidadPorVendedoresFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_VENDEDORES:
      return clearEvolucionUtilidadPorVendedores(state);

    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_START:
      return loadEvolucionUtilidadPorProvinciasStart(state);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_SUCCESS:
      return loadEvolucionUtilidadPorProvinciasSuccess(state, action.utilidades, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_FAIL:
      return loadEvolucionUtilidadPorProvinciasFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_PROVINCIAS:
      return clearEvolucionUtilidadPorProvincias(state);

    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_START:
      return loadEvolucionUtilidadPorZonasStart(state);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_SUCCESS:
      return loadEvolucionUtilidadPorZonasSuccess(state, action.utilidades, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_FAIL:
      return loadEvolucionUtilidadPorZonasFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_ZONAS:
      return clearEvolucionUtilidadPorZonas(state);

    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_MARCA_START:
      return loadEvolucionUtilidadPorMarcaStart(state);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_MARCA_SUCCESS:
      return loadEvolucionUtilidadPorMarcaSuccess(state, action.utilidades, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_MARCA_FAIL:
      return loadEvolucionUtilidadPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_MARCA:
      return clearEvolucionUtilidadPorMarca(state);

    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_START:
      return loadEvolucionUtilidadPorRubroStart(state);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_SUCCESS:
      return loadEvolucionUtilidadPorRubroSuccess(state, action.utilidades, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_FAIL:
      return loadEvolucionUtilidadPorRubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_RUBRO:
      return clearEvolucionUtilidadPorRubro(state);

    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_START:
      return loadEvolucionUtilidadPorSubrubroStart(state);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_SUCCESS:
      return loadEvolucionUtilidadPorSubrubroSuccess(state, action.utilidades, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_FAIL:
      return loadEvolucionUtilidadPorSubrubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_UTILIDAD_POR_SUBRUBRO:
      return clearEvolucionUtilidadPorSubrubro(state);

    case actionTypes.LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_START:
      return loadEvolucionDeUtilidadesMensualInteranualStart(state, action.anio);
    case actionTypes.LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_SUCCESS:
      return loadEvolucionDeUtilidadesMensualInteranualSuccess(state, action.anio, action.utilidades, action.utilidadesDataTable, action.totalUtilidad);
    case actionTypes.LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_FAIL:
      return loadEvolucionDeUtilidadesMensualInteranualFail(state, action.anio, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_UTILIDADES_INTERANUAL:
      return clearEvolucionDeUtilidadesMensualInteranual(state, action.periodo);
    case actionTypes.CLEAR_EVOLUCIONES_DE_UTILIDADES_INTERANUAL:
      return clearEvolucionesDeUtilidadesMensualInteranual(state);
    case actionTypes.AGREGAR_EVOLUCION_DE_UTILIDADES_INTERANUAL:
      return agregarEvolucionDeUtilidadesMensualInteranual(state, action.periodo);
    //#endregion UTILIDAD

    //#region COBRANZAS DEL DÍA 
    case actionTypes.COBRANZAS_DEL_DIA_START:
      return cobranzasDelDiaStart(state);
    case actionTypes.COBRANZAS_DEL_DIA_SUCCESS:
      return cobranzasDelDiaSuccess(state, action.diaActual, action.promedio30Dias);
    case actionTypes.COBRANZAS_DEL_DIA_REQUEST:
      return cobranzasDelDiaRequest(state, action.loadingRequest);
    case actionTypes.COBRANZAS_DEL_DIA_FAIL:
      return cobranzasDelDiaFail(state, action.error);
    case actionTypes.CLEAR_COBRANZAS_DEL_DIA:
      return clearCobranzasDelDia(state);
    //#endregion

    //#region DETALLE COBRANZAS DEL DÍA 
    case actionTypes.DETALLE_COBRANZAS_DEL_DIA_START:
      return detalleCobranzasDelDiaStart(state);
    case actionTypes.DETALLE_COBRANZAS_DEL_DIA_SUCCESS:
      return detalleCobranzasDelDiaSuccess(state, action.cobranzas);
    case actionTypes.DETALLE_COBRANZAS_DEL_DIA_FAIL:
      return detalleCobranzasDelDiaFail(state, action.error);
    case actionTypes.CLEAR_DETALLE_COBRANZAS_DEL_DIA:
      return clearDetalleCobranzasDelDia(state);
    //#endregion

    //#region DEUDAS
    case actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START:
      return deudaDelMesPorOrdenamientoStart(state, action.ordenamiento);
    case actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS:
      return deudaDelMesPorOrdenamientoSuccess(state, action.deudas, action.deudasNegativo,
        action.deudasDataTable, action.totalDeudas, action.fecha, action.ordenamiento, action.orden);
    case actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL:
      return deudaDelMesPorOrdenamientoFail(state, action.error, action.ordenamiento);
    case actionTypes.CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO:
      return clearDeudaDelMesPorOrdenamiento(state, action.ordenamiento);
    case actionTypes.EJECUCION_DEUDA_DEL_MES_POR_ORDENAMIENTO:
      return ejecucionDeudaDelMesPorOrdenamiento(state, action.ejecucion);

    //#endregion

    //#region CHEQUES DEPOSITADOS DEL DÍA
    case actionTypes.CHEQUES_DEPOSITADOS_DEL_DIA_START:
      return chequesDepositadosDelDiaStart(state);
    case actionTypes.CHEQUES_DEPOSITADOS_DEL_DIA_SUCCESS:
      return chequesDepositadosDelDiaSuccess(state, action.monto, action.cantidadCheques);
    case actionTypes.CHEQUES_DEPOSITADOS_DEL_DIA_FAIL:
      return chequesDepositadosDelDiaFail(state, action.error);
    case actionTypes.CLEAR_CHEQUES_DEPOSITADOS_DEL_DIA:
      return clearChequesDepositadosDelDia(state);
    //#endregion

    //#region TOTAL NOTAS DE PEDIDO
    case actionTypes.TOTAL_NOTAS_DE_PEDIDO_START:
      return totalNotasDePedidoStart(state);
    case actionTypes.TOTAL_NOTAS_DE_PEDIDO_SUCCESS:
      return totalNotasDePedidoSuccess(state, action.total, action.totalConvertido, action.monedaSecundaria);
    case actionTypes.TOTAL_NOTAS_DE_PEDIDO_FAIL:
      return totalNotasDePedidoFail(state, action.error);
    case actionTypes.CLEAR_TOTAL_NOTAS_DE_PEDIDO:
      return clearTotalNotasDePedido(state);
    //#endregion

    //#region DETALLE NOTAS DE PEDIDO
    case actionTypes.DETALLE_NOTAS_DE_PEDIDO_START:
      return detalleNotasDePedidoStart(state);
    case actionTypes.DETALLE_NOTAS_DE_PEDIDO_SUCCESS:
      return detalleNotasDePedidoSuccess(state, action.notasDePedido);
    case actionTypes.DETALLE_NOTAS_DE_PEDIDO_FAIL:
      return detalleNotasDePedidoFail(state, action.error);
    case actionTypes.CLEAR_DETALLE_NOTAS_DE_PEDIDO:
      return clearDetalleNotasDePedido(state);
    //#endregion

    //#region VENTAS DEL MES A UN CLIENTE
    case actionTypes.VENTAS_DEL_MES_A_UN_CLIENTE_START:
      return ventasDelMesAUnClienteStart(state);
    case actionTypes.VENTAS_DEL_MES_A_UN_CLIENTE_SUCCESS:
      return ventasDelMesAUnClienteSuccess(state, action.mesActual, action.mesAnterior);
    case actionTypes.VENTAS_DEL_MES_A_UN_CLIENTE_FAIL:
      return ventasDelMesAUnClienteFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_A_UN_CLIENTE:
      return clearVentasDelMesAUnCliente(state);
    //#endregion

    //#region NOTAS DE PEDIDO DEL MES A UN CLIENTE
    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_START:
      return loadPedidosMesComparativoStart(state);
    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS:
      return loadPedidosMesComparativoSuccess(state, action.transMesAnt, action.totalMesAnt,
        action.transMesAct, action.totalMesAct, action.porcTrans, action.porcTotal
      );
    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_FAIL:
      return loadPedidosMesComparativoFail(state, action.error);
    case actionTypes.CLEAR_PEDIDOS_MES_COMPARATIVO:
      return clearPedidosMesComparativo(state);
    //#endregion

    //#region VENTAS POR VENDEDOR
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_START:
      return loadVentasPorVendedorStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_SUCCESS:
      return loadVentasPorVendedorSuccess(state, action.unidadesVendidas, action.ventas, action.cantidad);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_FAIL:
      return loadVentasPorVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR:
      return clearVentasPorVendedor(state);
    //#endregion

    //#region PEDIDOS PENDIENTES
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_START:
      return loadPedidosPendientesStart(state);
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_SUCCESS:
      return loadPedidosPendientesSuccess(state, action.cantidad, action.total, action.prom30Dias);
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_FAIL:
      return loadPedidosPendientesFail(state, action.error);
    case actionTypes.CLEAR_PEDIDOS_PENDIENTES:
      return clearPedidosPendientes(state);
    //#endregion

    //#region CHEQUES EN CARTERA
    case actionTypes.LOAD_CHEQUES_EN_CARTERA_START:
      return loadChequesEnCarteraStart(state);
    case actionTypes.LOAD_CHEQUES_EN_CARTERA_SUCCESS:
      return loadChequesEnCarteraSuccess(state, action.monto, action.cantidadCheques);
    case actionTypes.LOAD_CHEQUES_EN_CARTERA_FAIL:
      return loadChequesEnCarteraFail(state, action.error);
    case actionTypes.CLEAR_CHEQUES_EN_CARTERA:
      return clearChequesEnCartera(state);
    //#endregion

    //#region ESTADO PEDIDOS PENDIENTES
    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_START:
      return loadEstadosPedidosPendientesStart(state);
    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS:
      return loadEstadosPedidosPendientesSuccess(state, action.estadosPedidosPendientes, action.total);
    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL:
      return loadEstadosPedidosPendientesFail(state, action.error);
    case actionTypes.CLEAR_ESTADOS_PEDIDOS_PENDIENTES:
      return clearEstadosPedidosPendientes(state);
    //#endregion

    //#region FACTURAS PENDIENTES
    case actionTypes.LOAD_FACTURAS_PENDIENTES_START:
      return loadFacturasPendientesStart(state);
    case actionTypes.LOAD_FACTURAS_PENDIENTES_SUCCESS:
      return loadFacturasPendientesSuccess(state, action.facturas, action.totalFacturas);
    case actionTypes.LOAD_FACTURAS_PENDIENTES_FAIL:
      return loadFacturasPendientesFail(state, action.error);
    case actionTypes.CLEAR_FACTURAS_PENDIENTES:
      return clearFacturasPendientes(state);
    //#endregion

    //#region COMPROBANTES PEDIDOS PENDIENTES
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START:
      return loadComprobantesPedidosPendientesStart(state);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS:
      return loadComprobantesPedidosPendientesSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL:
      return loadComprobantesPedidosPendientesFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES:
      return clearComprobantesPedidosPendientes(state);
    //#endregion

    //#region COMPROBANTES FACTURAS PENDIENTES 
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START:
      return loadComprobantesFacturasPendientesStart(state);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS:
      return loadComprobantesFacturasPendientesSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL:
      return loadComprobantesFacturasPendientesFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_FACTURAS_PENDIENTES:
      return clearComprobantesFacturasPendientes(state);
    //#endregion

    //#region EVOLUCION VENTAS POR PERIODOS

    case actionTypes.SELECCION_DATA_EVOLUCION_VENTAS_POR_PERIODOS:
      return { ...state, dataEvolucionVentasPorPeriodosSelected: action.selectionId };

    case actionTypes.SELECCION_DATA_VENTAS_POR_PERIODOS_POR:
      return { ...state, dataVentasPorPeriodosSelected: action.selectionId };

    case actionTypes.SELECCION_DATA_VENTAS_POR_MES_POR:
      return { ...state, dataVentasPorMesSelected: action.selectionId };

    case actionTypes.FILTROS_EVOLUCION_VENTAS_POR_PERIODOS:
      return updateFiltroEvolucionesDeVentasPorPeriodos(state, action.desde, action.hasta, action.anios,
        action.dataSelectedId, action.aplicado, action.userSelected);

    case actionTypes.FILTROS_VENTAS_COMPARATIVAS_POR_PERIODOS_POR:
      return updateFiltroVentasComparativasPorPeriodos(state, action.desde, action.hasta, action.anios,
        action.dataSelectedId, action.aplicado, action.userSelected);

    case actionTypes.FILTROS_VENTAS_COMPARATIVAS_POR_MES_POR:
      return updateFiltroVentasComparativasPorMes(state, action.desde, action.hasta, action.anios,
        action.dataSelectedId, action.aplicado, action.userSelected);

    case actionTypes.ACTUALIZAR_DATA_EVOLUCION_VENTAS_POR_PERIODOS:
      return { ...state, actualizarDataEvolucionVentasPorPeriodos: action.actualizar };

    case actionTypes.ACTUALIZAR_DATA_COMPARATIVAS_POR_PERIODOS:
      return { ...state, actualizarDataVentasComparativasPorPeriodos: action.actualizar };

    case actionTypes.ACTUALIZAR_DATA_COMPARATIVAS_POR_MES:
      return { ...state, actualizarDataVentasComparativasPorMes: action.actualizar };

    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_START:
      return loadEvolucionVentasPorPeriodosPorTipoVentaStart(state, action.tipoVenta);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_SUCCESS:
      return loadEvolucionVentasPorPeriodosPorTipoVentaSuccess(state, action.tipoVenta, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_FAIL:
      return loadEvolucionVentasPorPeriodosPorTipoVentaFail(state, action.tipoVenta, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA:
      return clearEvolucionVentasPorPeriodosPorTipoVenta(state, action.tipoVenta);

    //#endregion

    //#region VENTAS COMPARATIVAS

    case actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_START:
      return loadVentasComparativasPorTipoVentaStart(state, action.tipoVenta);
    case actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_SUCCESS:
      return loadVentasComparativasPorTipoVentaSuccess(state, action.tipoVenta, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_FAIL:
      return loadVentasComparativasPorTipoVentaFail(state, action.tipoVenta, action.error);
    case actionTypes.CLEAR_VENTAS_COMPARATIVAS_POR_TIPO_VENTA:
      return clearVentasComparativasPorTipoVenta(state, action.tipoVenta);

    //#endregion

    //#region VENTAS COMPARATIVAS POR MES

    case actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_START:
      return loadVentasComparativasPorMesPorTipoVentaStart(state, action.tipoVenta);
    case actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_SUCCESS:
      return loadVentasComparativasPorMesPorTipoVentaSuccess(state, action.tipoVenta, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_FAIL:
      return loadVentasComparativasPorMesPorTipoVentaFail(state, action.tipoVenta, action.error);
    case actionTypes.CLEAR_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA:
      return clearVentasComparativasPorMesPorTipoVenta(state, action.tipoVenta);

    //#endregion

    //#region VENTADAS DESAGREGADAS
    case actionTypes.SELECCION_TIPO_VENTAS_DESAGREGADAS:
      return { ...state, tipoVentasDesagregadasSelected: action.selectionId };
    case actionTypes.LOAD_TIPO_VENTAS_DESAGREGADAS_START:
      return loadVentasDesagregadasStart(state);
    case actionTypes.LOAD_TIPO_VENTAS_DESAGREGADAS_SUCCESS:
      return loadVentasDesagregadasSuccess(state, action.items);
    case actionTypes.LOAD_TIPO_VENTAS_DESAGREGADAS_FAIL:
      return loadVentasDesagregadasFail(state, action.error);
    case actionTypes.CLEAR_TIPO_VENTAS_DESAGREGADAS:
      return clearVentasDesagregadas(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_START:
      return loadVentasDelMesPorUnidadesDeNegociosStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_SUCCESS:
      return loadVentasDelMesPorUnidadesDeNegociosSuccess(state, action.ventas, action.ventasNegativo, action.total, action.fecha,
        action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_FAIL:
      return loadVentasDelMesPorUnidadesDeNegociosFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO:
      return clearVentasDelMesPorUnidadesDeNegocios(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_CANAL_START:
      return loadVentasDelMesPorCanalesStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_CANAL_SUCCESS:
      return loadVentasDelMesPorCanalesSuccess(state, action.ventas, action.ventasNegativo, action.total, action.fecha, action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_CANAL_FAIL:
      return loadVentasDelMesPorCanalesFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_CANAL:
      return clearVentasDelMesPorCanales(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_START:
      return loadVentasDelMesPorGruposDeComprasStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_SUCCESS:
      return loadVentasDelMesPorGruposDeComprasSuccess(state, action.ventas, action.ventasNegativo, action.total, action.fecha, action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_FAIL:
      return loadVentasDelMesPorGruposDeComprasFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS:
      return clearVentasDelMesPorGruposDeCompras(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_START:
      return loadVentasDelMesPorResponsablesStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_SUCCESS:
      return loadVentasDelMesPorResponsablesSuccess(state, action.ventas, action.ventasNegativo, action.total, action.fecha,
        action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_FAIL:
      return loadVentasDelMesPorResponsablesFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_RESPONSABLE:
      return clearVentasDelMesPorResponsables(state);

    case actionTypes.LOAD_VENTAS_DEL_MES_POR_CLIENTE_START:
      return loadVentasDelMesPorClientesStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_CLIENTE_SUCCESS:
      return loadVentasDelMesPorClientesSuccess(state, action.ventas, action.ventasNegativo, action.total, action.fecha,
        action.totalUnidades);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_CLIENTE_FAIL:
      return loadVentasDelMesPorClientesFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_CLIENTE:
      return clearVentasDelMesPorClientes(state);

    //#endregion

    //#region CUBO VENTAS
    case actionTypes.LOAD_CUBO_VENTAS_DATA_START:
      return clearCuboVentasFiltros(state) && loadCuboVentasDataStart(state);
    case actionTypes.LOAD_CUBO_VENTAS_DATA_SUCCESS:
      return loadCuboVentasDataSuccess(state, action.data, action.config, action.filtros);
    case actionTypes.LOAD_CUBO_VENTAS_DATA_FAIL:
      return loadCuboVentasDataFail(state, action.error);
    case actionTypes.UPDATE_CUBO_VENTAS_FILTROS:
      return updateCuboVentasFiltros(state, action.desde, action.hasta, action.anioComparacion, action.filtroAplicado);
    case actionTypes.CLEAR_CUBO_VENTAS_DATA:
      return clearCuboVentasFiltros(state) && clearCuboVentasData(state);
    //#endregion
    default:
      return state;
  }
};

export default reducer;

export const pathNamePages = {
  fotoDelDia: '/fotoDelDia',
  home: '/',
  gastos: '/gastos',
  multiempresa: '/multiempresa',
  tiemposReq: '/tiemposReq',
  rentabilidad: '/rentabilidad',
  analisisVentas: '/analisisVentas',
  analisisSucursales: '/analisisSucursales',
  analisisVendedores: '/analisisVendedores',
  analisisUtilidad: '/analisisUtilidad',
  noganetHome: '/noganet',
  tenactaHome: '/tenacta',
  tenactaInformes: '/tenactaInformes',
  tenactaInformeDos: '/tenactaInformeDos',
  tenactaInformeTres: '/tenactaInformeTres',
  encuestas: '/encuestas',
  changeLogs: '/changeLogs'
};

const idIndicadores = {
  ventasDelMes: 1,
  ventasDelDia: 2,
  ivaFiscalDelMes: 3,
  cobranzasDelMes: 4,
  promDiasDePagos: 5,
  promDiasDeCobranzas: 6,
  gastosPorCategoria: 7,
  chequesEnCartera: 8,
  chequesEmitidos: 9,
  plazoMedioVencACobrar: 10,
  plazoMedioVencAPagar: 11,
  gastosDelMes: 12,
  pagosDelMes: 13,
  saldoAcreedores: 14,
  saldoDeudores: 15,
  chequesEnCarteraMultiempresa: 16,
  chequesEmitidosPendientesMultiempresa: 17,
  gastosDelMesMultiempresa: 18,
  ventasDiariasMultiempresa: 19,
  ivaFiscalMultiempresa: 20,
  ventasMensualesMultiempresa: 21,
  pagosDelMesMultiempresa: 22,
  saldoAcreedoresMultiempresa: 23,
  saldoDeudoresMultiempresa: 24,
  cobranzasMensualesMultiempresa: 25,
  logIn: 26,
  monedas: 27,
  cambiarMoneda: 28,
  monedaDefecto: 29,
  comprobantesCobranzas: 30,
  comprobantesCompras: 31,
  comprobantesGastos: 32,
  comprobantesPagos: 33,
  comprobantesVentas: 34,
  categoriaDeItems: 35,
  comprobantesPedidos: 36,
  indicadoresOcultosPorUsuario: 37,
  indicadoresPorUsuario: 38,
  evolucionGastosMesual: 39,
  GastosPorItemDeCategoriaDelMes: 40,
  cantidadPedidosPendientes: 41,
  pedidosDelMesComparativo: 42,
  estadosPedidosPendientes: 43,
  facturasDelMesInteranual: 44,
  pedidosDelMesInteranual: 45,
  facturasPendientes: 46,
  ventasAlClienteEvolutivo: 47,
  comisionComparativo: 48,
  comisionEvolutivo: 49,
  chequesRechazados: 50,
  vendedores: 51,
  vendedoresPorEmpresa: 52,
  empresasConVendedor: 53,
  clientesInfoMinima: 54,
  clienteInfoMedia: 55,
  ultimaCompra: 56,
  rentabilidadEvolutivo: 57,
  lineChequesEnCartera: 58,
  lineChequesEmitidosPendientes: 59,
  categoriasDelCliente: 60,
  deudaPorCategoriaDeCliente: 61,
  ventasPorMarca: 62,
  ventasPorRubro: 63,
  ventasPorSubrubro: 64,
  rentabilidadMultiempresa: 65,
  cambiarEmpresa: 66,
  deudaPorCategoriaDeProveedor: 67,
  categoriasDeProveedor: 68,
  utilidadPorMarca: 69,
  utilidadPorRubro: 70,
  utilidadPorSubrubro: 71,
  evolucionGastosPorCategoria: 72,
  detalleGastosDelItemDeCompra: 73,
  evolucionVentasMensual: 74,
  ventasPorCategoriaDeCliente: 75,
  ventasDesglosadasPorMes: 76,
  evolucionVentasPorCategoriaDeCliente: 77,
  ventasPorClienteDeLaCategoria: 78,
  ventasAlCliente: 79,
  evolucionVentasPorVendedor: 80,
  evolucionVentasPorProvincia: 81,
  evolucionVentasPorZona: 82,
  evolucionVentasPorMarca: 83,
  evolucionVentasPorRubro: 84,
  evolucionVentasPorSubrubro: 85,
  evolucionVentasPorCategoriaDeClienteLineSeleccion: 86,
  ventasPorMesPorVendedor: 87,
  ventasPorMesPorProvincia: 88,
  ventasPorMesPorZona: 89,
  ventasPorMesPorMarca: 90,
  ventasPorMesPorRubro: 91,
  ventasPorMesPorSubrubro: 92,
  ventasPorMesPorCategoriaDeCliente: 93,
  ventasDelMesPorArticuloDeLaMarca: 94,
  ventasDelMesPorArticuloDelRubro: 95,
  ventasDelMesPorArticuloDelSubrubro: 96,
  ventasDelMesPorArticuloDeLaProvincia: 97,
  ventasDelMesPorArticuloDeLaZona: 98,
  ventasDelMesPorArticuloDelVendedor: 99,
  ventasDelArticuloPorCliente: 100,
  ventasDelArticuloAlCliente: 101,
  ventasDelMesPorArticuloDelCliente: 102,
  ventasDelMesPorClienteEnLaZona: 103,
  ventasPorMesDeLaSucursal: 104,
  ventasDelMesDeLaSucursalPorCategoriaDeCliente: 105,
  ventasDelMesDeLaSucursalPorMarca: 106,
  ventasDelMesDeLaSucursalPorRubro: 107,
  ventasDelMesDeLaSucursalPorSubrubro: 108,
  ventasDelMesDeLaSucursalPorProvincia: 109,
  ventasDelMesDeLaSucursalPorZona: 110,
  ventasDelMesDeLaSucursalPorVendedor: 111,
  ventasDeLaSucursalPorClienteDeLaCategoria: 112,
  ventasDeLaSucursalPorClienteDeLaProvincia: 113,
  ventasDeLaSucursalPorClienteDeLaZona: 114,
  ventasDeLaSucursalPorClienteDelVendedor: 115,
  ventasDeLaSucursalPorArticuloDeLaMarca: 116,
  ventasDeLaSucursalPorArticuloDelRubro: 117,
  ventasDeLaSucursalPorArticuloDelSubrubro: 118,
  ventasDeLaSucursalPorClienteDelArticulo: 119,
  ventasDeLaSucursalPorArticuloDelCliente: 120,
  ventasDeLaSucursalDelArticuloAlCliente: 121,
  ventasDeLaSucursalEvolutivoPorCategoriaDeCliente: 122,
  ventasDeLaSucursalEvolutivoPorMarca: 123,
  ventasDeLaSucursalEvolutivoPorRubro: 124,
  ventasDeLaSucursalEvolutivoPorSubrubro: 125,
  ventasDeLaSucursalEvolutivoPorProvincia: 126,
  ventasDeLaSucursalEvolutivoPorZona: 127,
  ventasDeLaSucursalEvolutivoPorVendedor: 128,
  estadosCheques: 129,
  chequesValorEstado: 130,
  postChequesValorEstado: 131,
  cambiarSucursal: 132,
  detalleCheques: 134,
  ventasDelMesPorVendedor: 135,
  ventasDelMesPorProvincia: 136,
  ventasDelMesPorZona: 137,
  ventasDelMesPorClienteDeLaProvincia: 138,
  ventasDelMesPorClienteDelVendedor: 139,
  utilidadPorMes: 140,
  utilidadDelMesPorCategoriaDeCliente: 141,
  utilidadDelMesPorMarca: 142,
  utilidadDelMesPorRubro: 143,
  utilidadDelMesPorSubrubro: 144,
  utilidadDelMesPorVendedor: 145,
  utilidadDelMesPorProvincia: 146,
  utilidadDelMesPorZona: 147,
  cuentaCorrienteCliente: 148,
  evolucionVentasMensualDeUnVendedor: 149,
  ventasDeUnVendedorPorCategoriaDeCliente: 150,
  ventasDeUnVendedorPorMarca: 151,
  ventasDeUnVendedorPorRubro: 152,
  ventasDeUnVendedorPorSubrubro: 153,
  ventasDeUnVendedorPorProvincia: 154,
  ventasDeUnVendedorPorZona: 155,
  ventasDeUnVendedorEvolutivoPorMarca: 156,
  ventasDeUnVendedorEvolutivoPorRubro: 157,
  ventasDeUnVendedorEvolutivoPorSubrubro: 158,
  ventasDeUnVendedorEvolutivoPorProvincia: 159,
  ventasDeUnVendedorEvolutivoPorZona: 160,
  ventasDeUnVendedorEvolutivoPorCategoriaDeCliente: 161,
  chequesRechazadosMultiempresa: 162,
  cobranzasDelDia: 163,
  ventasPorUnidadesDeNegocios: 164,
  ventasPorCanales: 165,
  ventasPorGruposDeCompras: 166,
  ventasPorResponsables: 167,
  ventasPorClientes: 168,
  ventasPorArticulos: 169,
  comprobantesPedidosPendientes: 170,
  comprobantesFacturasPendientes: 171,
  VentasPorSucursalPorProvincia: 172,
  VentasPorSucursalPorMarca: 172,
  VentasPorSucursalDelRubro: 172,
  VentasPorSucursalDelSubrubro: 172,
  VentasPorSucursalDeLaProvincia: 172,
  VentasPorSucursalDeLaZona: 172,
  VentasPorSucursalPorVendedor: 172,
  VentasDeLasSucursalesPorMarca: 173,
  VentasPorSucursalPorRubro: 174,
  VentasPorSucursalPorSubrubro: 174,
  VentasPorSucursalPorZona: 174,
  VentasPorSucursalDeLaMarca: 174,
  VentasPorSucursalDelVendedor: 174,
  categoriasDelClienteIndicadores: 175,
};

export default idIndicadores;

export const PIE_VENTAS_COMPARATIVAS_START = 'PIE_VENTAS_COMPARATIVAS_START';
export const PIE_VENTAS_COMPARATIVAS_SUCCESS = 'PIE_VENTAS_COMPARATIVAS_SUCCESS';
export const PIE_VENTAS_COMPARATIVAS_FAIL = 'PIE_VENTAS_COMPARATIVAS_FAIL';
export const CLEAR_PIE_VENTAS_COMPARATIVAS_FAIL = 'CLEAR_PIE_VENTAS_COMPARATIVAS_FAIL';

export const BAR_VENTAS_VENDEDOR_MENSUAL_START = 'BAR_VENTAS_VENDEDOR_MENSUAL_START';
export const BAR_VENTAS_VENDEDOR_MENSUAL_SUCCESS = 'BAR_VENTAS_VENDEDOR_MENSUAL_SUCCESS';
export const BAR_VENTAS_VENDEDOR_MENSUAL_FAIL = 'BAR_VENTAS_VENDEDOR_MENSUAL_FAIL';

export const TEXTO_CHEQUES_EN_CARTERA_START = 'TEXTO_CHEQUES_EN_CARTERA_START';
export const TEXTO_CHEQUES_EN_CARTERA_SUCCESS = 'TEXTO_CHEQUES_EN_CARTERA_SUCCESS';
export const TEXTO_CHEQUES_EN_CARTERA_FAIL = 'TEXTO_CHEQUES_EN_CARTERA_FAIL';
export const CLEAR_TEXTO_CHEQUES_EN_CARTERA = 'CLEAR_TEXTO_CHEQUES_EN_CARTERA';

export const TEXTO_CHEQUES_RECHAZADOS_START = 'TEXTO_CHEQUES_RECHAZADOS_START';
export const TEXTO_CHEQUES_RECHAZADOS_SUCCESS = 'TEXTO_CHEQUES_RECHAZADOS_SUCCESS';
export const TEXTO_CHEQUES_RECHAZADOS_FAIL = 'TEXTO_CHEQUES_RECHAZADOS_FAIL';
export const CLEAR_TEXTO_CHEQUES_RECHAZADOS = 'CLEAR_TEXTO_CHEQUES_RECHAZADOS';

export const HEATMAP_VENTAS_DIARIAS_DEL_ANIO_START = 'HEATMAP_VENTAS_DIARIAS_DEL_ANIO_START';
export const HEATMAP_VENTAS_DIARIAS_DEL_ANIO_SUCCESS = 'HEATMAP_VENTAS_DIARIAS_DEL_ANIO_SUCCESS';
export const HEATMAP_VENTAS_DIARIAS_DEL_ANIO_FAIL = 'HEATMAP_VENTAS_DIARIAS_DEL_ANIO_FAIL';

export const LINE_CHEQUES_EN_CARTERA_START = 'LINE_CHEQUES_EN_CARTERA_START';
export const LINE_CHEQUES_EN_CARTERA_SUCCESS = 'LINE_CHEQUES_EN_CARTERA_SUCCESS';
export const LINE_CHEQUES_EN_CARTERA_FAIL = 'LINE_CHEQUES_EN_CARTERA_FAIL';
export const CLEAR_LINE_CHEQUES_EN_CARTERA = 'CLEAR_LINE_CHEQUES_EN_CARTERA';

export const LINE_CHEQUES_RECHAZADOS_START = 'LINE_CHEQUES_RECHAZADOS_START';
export const LINE_CHEQUES_RECHAZADOS_SUCCESS = 'LINE_CHEQUES_RECHAZADOS_SUCCESS';
export const LINE_CHEQUES_RECHAZADOS_FAIL = 'LINE_CHEQUES_RECHAZADOS_FAIL';
export const CLEAR_LINE_CHEQUES_RECHAZADOS = 'CLEAR_LINE_CHEQUES_RECHAZADOS';

export const LINE_CHEQUES_PROPIOS_START = 'LINE_CHEQUES_PROPIOS_START';
export const LINE_CHEQUES_PROPIOS_SUCCESS = 'LINE_CHEQUES_PROPIOS_SUCCESS';
export const LINE_CHEQUES_PROPIOS_FAIL = 'LINE_CHEQUES_PROPIOS_FAIL';
export const CLEAR_LINE_CHEQUES_PROPIOS = 'CLEAR_LINE_CHEQUES_PROPIOS';

export const DETALLE_CHEQUES_START = 'DETALLE_CHEQUES_START';
export const DETALLE_CHEQUES_SUCCESS = 'DETALLE_CHEQUES_SUCCESS';
export const DETALLE_CHEQUES_FAIL = 'DETALLE_CHEQUES_FAIL';
export const CLEAR_DETALLE_CHEQUES = 'CLEAR_DETALLE_CHEQUES';

export const PIE_VENTAS_COMPARATIVO_VENDEDOR_START = 'PIE_VENTAS_COMPARATIVO_VENDEDOR_START';
export const PIE_VENTAS_COMPARATIVO_VENDEDOR_SUCCESS = 'PIE_VENTAS_COMPARATIVO_VENDEDOR_SUCCESS';
export const PIE_VENTAS_COMPARATIVO_VENDEDOR_FAIL = 'PIE_VENTAS_COMPARATIVO_VENDEDOR_FAIL';
export const CLEAR_PIE_VENTAS_COMPARATIVO = 'CLEAR_PIE_VENTAS_COMPARATIVO';

export const LOAD_VENDEDORES_START = 'LOAD_VENDEDORES_START';
export const LOAD_VENDEDORES_SUCCESS = 'LOAD_VENDEDORES_SUCCESS';
export const LOAD_VENDEDORES_FAIL = 'LOAD_VENDEDORES_FAIL';
export const SELECCIONAR_VENDEDOR = 'SELECCIONAR_VENDEDOR';

export const VENTAS_DIARIAS_COMPARATIVO_START = 'VENTAS_DIARIAS_COMPARATIVO_START';
export const VENTAS_DIARIAS_COMPARATIVO_REQUEST = 'VENTAS_DIARIAS_COMPARATIVO_REQUEST';
export const VENTAS_DIARIAS_COMPARATIVO_SUCCESS = 'VENTAS_DIARIAS_COMPARATIVO_SUCCESS';
export const VENTAS_DIARIAS_COMPARATIVO_FAIL = 'VENTAS_DIARIAS_COMPARATIVO_FAIL';
export const CLEAR_VENTAS_DIARIAS_COMPARATIVO = 'CLEAR_VENTAS_DIARIAS_COMPARATIVO';

export const LINE_VENTAS_COMPARATIVO_START = 'LINE_VENTAS_COMPARATIVO_START';
export const LINE_VENTAS_COMPARATIVO_SUCCESS = 'LINE_VENTAS_COMPARATIVO_SUCCESS';
export const LINE_VENTAS_COMPARATIVO_FAIL = 'LINE_VENTAS_COMPARATIVO_FAIL';

export const BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_START = 'BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_START';
export const BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_SUCCESS =
  'BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_SUCCESS';
export const BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_FAIL = 'BAR_VENTAS_VENDEDOR_POR_PERIODO_COMPARATIVO_FAIL';

export const SELECCIONAR_PERIODO = 'SELECCIONAR_PERIODO';

export const PIE_SALDO_ACREEDORES_START = 'PIE_SALDO_ACREEDORES_START';
export const PIE_SALDO_ACREEDORES_SUCCESS = 'PIE_SALDO_ACREEDORES_SUCCESS';
export const PIE_SALDO_ACREEDORES_FAIL = 'PIE_SALDO_ACREEDORES_FAIL';
export const CLEAR_PIE_SALDO_ACREEDORES = 'CLEAR_PIE_SALDO_ACREEDORES';

export const TEXTO_IVA_FISCAL_START = 'TEXTO_IVA_FISCAL_START';
export const TEXTO_IVA_FISCAL_SUCCESS = 'TEXTO_IVA_FISCAL_SUCCESS';
export const TEXTO_IVA_FISCAL_FAIL = 'TEXTO_IVA_FISCAL_FAIL';
export const CLEAR_TEXTO_IVA_FISCAL = 'CLEAR_TEXTO_IVA_FISCAL';

export const BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_START = 'BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_START';
export const BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_SUCCESS = 'BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_SUCCESS';
export const BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_FAIL = 'BAR_VENTAS_SUCURSAL_PERIODO_COMPARATIVO_FAIL';

export const TEXTO_CHEQUES_PROPIOS_START = 'TEXTO_CHEQUES_PROPIOS_START';
export const TEXTO_CHEQUES_PROPIOS_SUCCESS = 'TEXTO_CHEQUES_PROPIOS_SUCCESS';
export const TEXTO_CHEQUES_PROPIOS_FAIL = 'TEXTO_CHEQUES_PROPIOS_FAIL';
export const CLEAR_TEXTO_CHEQUES_PROPIOS = 'CLEAR_TEXTO_CHEQUES_PROPIOS';

export const LOAD_SUCURSALES_START = 'LOAD_SUCURSALES_START';
export const LOAD_SUCURSALES_SUCCESS = 'LOAD_SUCURSALES_SUCCESS';
export const LOAD_SUCURSALES_FAIL = 'LOAD_SUCURSALES_FAIL';
export const SELECCIONAR_SUCURSAL = 'SELECCIONAR_SUCURSAL';
export const CLEAR_SUCURSALES = 'CLEAR_SUCURSALES';

export const LOAD_SUCURSALES_FILTRO_START = 'LOAD_SUCURSALES_FILTRO_START';
export const LOAD_SUCURSALES_FILTRO_SUCCESS = 'LOAD_SUCURSALES_FILTRO_SUCCESS';
export const LOAD_SUCURSALES_FILTRO_FAIL = 'LOAD_SUCURSALES_FILTRO_FAIL';
export const SELECCIONAR_SUCURSAL_FILTRO = 'SELECCIONAR_SUCURSAL_FILTRO';
export const CLEAR_SUCURSALES_FILTRO = 'CLEAR_SUCURSALES_FILTRO';

export const EXECUTE_CREATE_SUCURSAL = 'EXECUTE_CREATE_SUCURSAL';
export const EXECUTE_UPDATE_SUCURSAL = 'EXECUTE_UPDATE_SUCURSAL';

export const EXECUTE_CREATE_GRUPO_EMPRESAS = 'EXECUTE_CREATE_GRUPO_EMPRESAS';
export const EXECUTE_UPDATE_GRUPO_EMPRESAS = 'EXECUTE_UPDATE_GRUPO_EMPRESAS';

export const EXECUTE_LOAD_GRUPO_GASTOS = 'EXECUTE_LOAD_GRUPO_GASTOS';
export const EXECUTE_CREATE_GRUPO_GASTOS = 'EXECUTE_CREATE_GRUPO_GASTOS';
export const EXECUTE_UPDATE_GRUPO_GASTOS = 'EXECUTE_UPDATE_GRUPO_GASTOS';
export const EXECUTE_DELETE_GRUPO_GASTOS = 'EXECUTE_DELETE_GRUPO_GASTOS';
export const EXECUTE_UPDATE_GRUPO_GASTOS_SELECTED = 'EXECUTE_UPDATE_GRUPO_GASTOS_SELECTED';

export const TEXTO_COBRANZAS_MENSUAL_START = 'TEXTO_COBRANZAS_MENSUAL_START';
export const TEXTO_COBRANZAS_MENSUAL_SUCCESS = 'TEXTO_COBRANZAS_MENSUAL_SUCCESS';
export const TEXTO_COBRANZAS_MENSUAL_FAIL = 'TEXTO_COBRANZAS_MENSUAL_FAIL';
export const CLEAR_TEXTO_COBRANZAS_MENSUAL = 'CLEAR_TEXTO_COBRANZAS_MENSUAL';

export const LINE_RENTABILIDAD_EVOLUTIVO_START = 'LINE_RENTABILIDAD_EVOLUTIVO_START';
export const LINE_RENTABILIDAD_EVOLUTIVO_SUCCESS = 'LINE_RENTABILIDAD_EVOLUTIVO_SUCCESS';
export const LINE_RENTABILIDAD_EVOLUTIVO_FAIL = 'LINE_RENTABILIDAD_EVOLUTIVO_FAIL';
export const CLEAR_LINE_RENTABILIDAD_EVOLUTIVO = 'CLEAR_LINE_RENTABILIDAD_EVOLUTIVO';

export const SELECCION_FECHA_PERIODO_RENTABILIDAD = 'SELECCION_FECHA_PERIODO_RENTABILIDAD';

export const RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_START = 'RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_START';
export const RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_SUCCESS = 'RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_SUCCESS';
export const RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_FAIL = 'RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES_FAIL';
export const CLEAR_RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES = 'CLEAR_RENTABILIDAD_EVOLUTIVO_POR_SUCURSALES';

export const SELECCION_FILTRO_RENTABILIDAD_POR_SUCURSALES = 'SELECCION_FILTRO_RENTABILIDAD_POR_SUCURSALES';

export const TEXTO_DEUDORES_POR_VENTAS_START = 'TEXTO_DEUDORES_POR_VENTAS_START';
export const TEXTO_DEUDORES_POR_VENTAS_SUCCESS = 'TEXTO_DEUDORES_POR_VENTAS_SUCCESS';
export const TEXTO_DEUDORES_POR_VENTAS_FAIL = 'TEXTO_DEUDORES_POR_VENTAS_FAIL';
export const CLEAR_TEXTO_DEUDORES_POR_VENTAS = 'CLEAR_TEXTO_DEUDORES_POR_VENTAS';

export const TEXTO_PLAZO_MEDIO_A_PAGAR_START = 'TEXTO_PLAZO_MEDIO_A_PAGAR_START';
export const TEXTO_PLAZO_MEDIO_A_PAGAR_SUCCESS = 'TEXTO_PLAZO_MEDIO_A_PAGAR_SUCCESS';
export const TEXTO_PLAZO_MEDIO_A_PAGAR_FAIL = 'TEXTO_PLAZO_MEDIO_A_PAGAR_FAIL';
export const CLEAR_TEXTO_PLAZO_MEDIO_A_PAGAR = 'CLEAR_TEXTO_PLAZO_MEDIO_A_PAGAR';

export const PIE_GASTOS_POR_CATEGORIA_START = 'PIE_GASTOS_POR_CATEGORIA_START';
export const PIE_GASTOS_POR_CATEGORIA_SUCCESS = 'PIE_GASTOS_POR_CATEGORIA_SUCCESS';
export const PIE_GASTOS_POR_CATEGORIA_FAIL = 'PIE_GASTOS_POR_CATEGORIA_FAIL';
export const CLEAR_PIE_GASTOS_POR_CATEGORIA = 'CLEAR_PIE_GASTOS_POR_CATEGORIA';

export const PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_START = 'PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_START';
export const PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_SUCCESS = 'PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_SUCCESS';
export const PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_FAIL = 'PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE_FAIL';
export const CLEAR_PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE = 'CLEAR_PIE_GASTOS_POR_CATEGORIA_SIN_DATATABLE';

export const TEXTO_SALDO_BANCOS_START = 'TEXTO_SALDO_BANCOS_START';
export const TEXTO_SALDO_BANCOS_SUCCESS = 'TEXTO_SALDO_BANCOS_SUCCESS';
export const TEXTO_SALDO_BANCOS_FAIL = 'TEXTO_SALDO_BANCOS_FAIL';

export const TEXTO_GASTOS_DEL_MES_START = 'TEXTO_GASTOS_DEL_MES_START';
export const TEXTO_GASTOS_DEL_MES_SUCCESS = 'TEXTO_GASTOS_DEL_MES_SUCCESS';
export const TEXTO_GASTOS_DEL_MES_FAIL = 'TEXTO_GASTOS_DEL_MES_FAIL';
export const CLEAR_TEXTO_GASTOS_DEL_MES = 'CLEAR_TEXTO_GASTOS_DEL_MES';

export const TEXTO_SALDO_EFECTIVO_START = 'TEXTO_SALDO_EFECTIVO_START';
export const TEXTO_SALDO_EFECTIVO_SUCCESS = 'TEXTO_SALDO_EFECTIVO_SUCCESS';
export const TEXTO_SALDO_EFECTIVO_FAIL = 'TEXTO_SALDO_EFECTIVO_FAIL';

export const TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_START = 'TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_START';
export const TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_SUCCESS = 'TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_SUCCESS';
export const TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_FAIL = 'TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES_FAIL';
export const CLEAR_TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES = 'CLEAR_TEXTO_PLAZO_MEDIO_PAGO_PROVEEDORES';

export const TEXTO_PAGOS_DEL_MES_START = 'TEXTO_PAGOS_DEL_MES_START';
export const TEXTO_PAGOS_DEL_MES_SUCCESS = 'TEXTO_PAGOS_DEL_MES_SUCCESS';
export const TEXTO_PAGOS_DEL_MES_FAIL = 'TEXTO_PAGOS_DEL_MES_FAIL';
export const CLEAR_TEXTO_PAGOS_DEL_MES = 'CLEAR_TEXTO_PAGOS_DEL_MES';

export const TEXTO_PROMEDIO_DIAS_COBRANZAS_START = 'TEXTO_PROMEDIO_DIAS_COBRANZAS_START';
export const TEXTO_PROMEDIO_DIAS_COBRANZAS_SUCCESS = 'TEXTO_PROMEDIO_DIAS_COBRANZAS_SUCCESS';
export const TEXTO_PROMEDIO_DIAS_COBRANZAS_FAIL = 'TEXTO_PROMEDIO_DIAS_COBRANZAS_FAIL';
export const CLEAR_TEXTO_PROMEDIO_DIAS_COBRANZAS = 'CLEAR_TEXTO_PROMEDIO_DIAS_COBRANZAS';

export const TEXTO_PROMEDIO_COBRANZAS_START = 'TEXTO_PROMEDIO_COBRANZAS_START';
export const TEXTO_PROMEDIO_COBRANZAS_SUCCESS = 'TEXTO_PROMEDIO_COBRANZAS_SUCCESS';
export const TEXTO_PROMEDIO_COBRANZAS_FAIL = 'TEXTO_PROMEDIO_COBRANZAS_FAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_REDIRECT_PATH = 'AUTH_SET_REDIRECT_PATH';

export const EVOLUCION_GASTOS_MENSUAL_START = 'EVOLUCION_GASTOS_MENSUAL_START';
export const EVOLUCION_GASTOS_MENSUAL_SUCCESS = 'EVOLUCION_GASTOS_MENSUAL_SUCCESS';
export const EVOLUCION_GASTOS_MENSUAL_FAIL = 'EVOLUCION_GASTOS_MENSUAL_FAIL';
export const CLEAR_EVOLUCION_GASTOS_MENSUAL = 'CLEAR_EVOLUCION_GASTOS_MENSUAL';

export const EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_START = 'EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_START';
export const EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_SUCCESS = 'EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_SUCCESS';
export const EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_ERROR = 'EVOLUCION_GASTOS_MENSUAL_POR_CATEGORIA_ERROR';

export const GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_START = 'GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_START';
export const GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_SUCCESS = 'GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_SUCCESS';
export const GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_ERROR = 'GASTOS_POR_ITEM_DE_CATEGORIA_SELECCIONADA_ERROR';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const LOAD_DEUDORES_POR_VENTAS_START = 'LOAD_DEUDORES_POR_VENTAS_START';
export const LOAD_DEUDORES_POR_VENTAS_SUCCESS = 'LOAD_DEUDORES_POR_VENTAS_SUCCESS';
export const LOAD_DEUDORES_POR_VENTAS_FAIL = 'LOAD_DEUDORES_POR_VENTAS_FAIL';
export const CLEAR_DEUDORES_POR_VENTAS = 'CLEAR_DEUDORES_POR_VENTAS';

export const LOAD_MONEDAS_START = 'LOAD_MONEDAS_START';
export const LOAD_MONEDAS_SUCCESS = 'LOAD_MONEDAS_SUCCESS';
export const LOAD_MONEDAS_FAIL = 'LOAD_MONEDAS_FAIL';

export const EXECUTE_CAMBIAR_MONEDA = 'EXECUTE_CAMBIAR_MONEDA';

export const LOAD_VENTAS_MENSUALES_MULTIEMPRESA_START = 'LOAD_VENTAS_MENSUALES_MULTIEMPRESA_START';
export const LOAD_VENTAS_MENSUALES_MULTIEMPRESA_SUCCESS = 'LOAD_VENTAS_MENSUALES_MULTIEMPRESA_SUCCESS';
export const LOAD_VENTAS_MENSUALES_MULTIEMPRESA_FAIL = 'LOAD_VENTAS_MENSUALES_MULTIEMPRESA_FAIL';

export const LOAD_VENTAS_DIARIAS_MULTIEMPRESA_START = 'LOAD_VENTAS_DIARIAS_MULTIEMPRESA_START';
export const LOAD_VENTAS_DIARIAS_MULTIEMPRESA_SUCCESS = 'LOAD_VENTAS_DIARIAS_MULTIEMPRESA_SUCCESS';
export const LOAD_VENTAS_DIARIAS_MULTIEMPRESA_FAIL = 'LOAD_VENTAS_DIARIAS_MULTIEMPRESA_FAIL';

export const LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_START = 'LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_START';
export const LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_SUCCESS = 'LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_SUCCESS';
export const LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_FAIL = 'LOAD_COBRANZAS_MENSUALES_MULTIEMPRESA_FAIL';

export const LOAD_SALDO_DEUDORES_MULTIEMPRESA_START = 'LOAD_SALDO_DEUDORES_MULTIEMPRESA_START';
export const LOAD_SALDO_DEUDORES_MULTIEMPRESA_SUCCESS = 'LOAD_SALDO_DEUDORES_MULTIEMPRESA_SUCCESS';
export const LOAD_SALDO_DEUDORES_MULTIEMPRESA_FAIL = 'LOAD_SALDO_DEUDORES_MULTIEMPRESA_FAIL';

export const LOAD_SALDO_ACREEDORES_MULTIEMPRESA_START = 'LOAD_SALDO_ACREEDORES_MULTIEMPRESA_START';
export const LOAD_SALDO_ACREEDORES_MULTIEMPRESA_SUCCESS = 'LOAD_SALDO_ACREEDORES_MULTIEMPRESA_SUCCESS';
export const LOAD_SALDO_ACREEDORES_MULTIEMPRESA_FAIL = 'LOAD_SALDO_ACREEDORES_MULTIEMPRESA_FAIL';

export const LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_START = 'LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_START';
export const LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_SUCCESS = 'LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_SUCCESS';
export const LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_FAIL = 'LOAD_CHEQUES_EN_CARTERA_MULTIEMPRESA_FAIL';

export const LOAD_CHEQUES_RECHAZADOS_MULTIEMPRESA_START = 'LOAD_CHEQUES_RECHAZADOS_MULTIEMPRESA_START';
export const LOAD_CHEQUES_RECHAZADOS_MULTIEMPRESA_SUCCESS = 'LOAD_CHEQUES_RECHAZADOS_MULTIEMPRESA_SUCCESS';
export const LOAD_CHEQUES_RECHAZADOS_MULTIEMPRESA_FAIL = 'LOAD_CHEQUES_RECHAZADOS_MULTIEMPRESA_FAIL';

export const LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_START = 'LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_START';
export const LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_SUCCESS = 'LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_SUCCESS';
export const LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_FAIL = 'LOAD_CHEQUES_PROPIOS_MULTIEMPRESA_FAIL';

export const LOAD_GASTOS_DEL_MES_MULTIEMPRESA_START = 'LOAD_GASTOS_DEL_MES_MULTIEMPRESA_START';
export const LOAD_GASTOS_DEL_MES_MULTIEMPRESA_SUCCESS = 'LOAD_GASTOS_DEL_MES_MULTIEMPRESA_SUCCESS';
export const LOAD_GASTOS_DEL_MES_MULTIEMPRESA_FAIL = 'LOAD_GASTOS_DEL_MES_MULTIEMPRESA_FAIL';

export const LOAD_PAGOS_DEL_MES_MULTIEMPRESA_START = 'LOAD_PAGOS_DEL_MES_MULTIEMPRESA_START';
export const LOAD_PAGOS_DEL_MES_MULTIEMPRESA_SUCCESS = 'LOAD_PAGOS_DEL_MES_MULTIEMPRESA_SUCCESS';
export const LOAD_PAGOS_DEL_MES_MULTIEMPRESA_FAIL = 'LOAD_PAGOS_DEL_MES_MULTIEMPRESA_FAIL';

export const LOAD_IVA_FISCAL_MULTIEMPRESA_START = 'LOAD_IVA_FISCAL_MULTIEMPRESA_START';
export const LOAD_IVA_FISCAL_MULTIEMPRESA_SUCCESS = 'LOAD_IVA_FISCAL_MULTIEMPRESA_SUCCESS';
export const LOAD_IVA_FISCAL_MULTIEMPRESA_FAIL = 'LOAD_IVA_FISCAL_MULTIEMPRESA_FAIL';

export const LOAD_RENTABILIDAD_MULTIEMPRESA_START = 'LOAD_RENTABILIDAD_MULTIEMPRESA_START';
export const LOAD_RENTABILIDAD_MULTIEMPRESA_SUCCESS = 'LOAD_RENTABILIDAD_MULTIEMPRESA_SUCCESS';
export const LOAD_RENTABILIDAD_MULTIEMPRESA_FAIL = 'LOAD_RENTABILIDAD_MULTIEMPRESA_FAIL';

export const LOAD_COMPROBANTES_GASTOS_START = 'LOAD_COMPROBANTES_GASTOS_START';
export const LOAD_COMPROBANTES_GASTOS_SUCCESS = 'LOAD_COMPROBANTES_GASTOS_SUCCESS';
export const LOAD_COMPROBANTES_GASTOS_FAIL = 'LOAD_COMPROBANTES_GASTOS_FAIL';

export const LOAD_COMPROBANTES_PAGOS_START = 'LOAD_COMPROBANTES_PAGOS_START';
export const LOAD_COMPROBANTES_PAGOS_SUCCESS = 'LOAD_COMPROBANTES_PAGOS_SUCCESS';
export const LOAD_COMPROBANTES_PAGOS_FAIL = 'LOAD_COMPROBANTES_PAGOS_FAIL';

export const LOAD_COMPROBANTES_COMPRAS_START = 'LOAD_COMPROBANTES_COMPRAS_START';
export const LOAD_COMPROBANTES_COMPRAS_SUCCESS = 'LOAD_COMPROBANTES_COMPRAS_SUCCESS';
export const LOAD_COMPROBANTES_COMPRAS_FAIL = 'LOAD_COMPROBANTES_COMPRAS_FAIL';

export const LOAD_COMPROBANTES_VENTAS_START = 'LOAD_COMPROBANTES_VENTAS_START';
export const LOAD_COMPROBANTES_VENTAS_SUCCESS = 'LOAD_COMPROBANTES_VENTAS_SUCCESS';
export const LOAD_COMPROBANTES_VENTAS_FAIL = 'LOAD_COMPROBANTES_VENTAS_FAIL';

export const LOAD_COMPROBANTES_COBRANZAS_START = 'LOAD_COMPROBANTES_COBRANZAS_START';
export const LOAD_COMPROBANTES_COBRANZAS_SUCCESS = 'LOAD_COMPROBANTES_COBRANZAS_SUCCESS';
export const LOAD_COMPROBANTES_COBRANZAS_FAIL = 'LOAD_COMPROBANTES_COBRANZAS_FAIL';

export const LOAD_EMPRESAS_START = 'LOAD_EMPRESAS_START';
export const LOAD_EMPRESAS_SUCCESS = 'LOAD_EMPRESAS_SUCCESS';
export const LOAD_EMPRESAS_FAIL = 'LOAD_EMPRESAS_FAIL';

export const LOAD_CONFIGURACION_INDICADORES_START = 'LOAD_CONFIGURACION_INDICADORES_START';
export const LOAD_CONFIGURACION_INDICADORES_SUCCESS = 'LOAD_CONFIGURACION_INDICADORES_SUCCESS';
export const LOAD_CONFIGURACION_INDICADORES_FAIL = 'LOAD_CONFIGURACION_INDICADORES_FAIL';

export const LOAD_INDICADORES_OCULTOS_START = 'LOAD_INDICADORES_OCULTOS_START';
export const LOAD_INDICADORES_OCULTOS_SUCCESS = 'LOAD_INDICADORES_OCULTOS_SUCCESS';
export const LOAD_INDICADORES_OCULTOS_FAIL = 'LOAD_INDICADORES_OCULTOS_FAIL';

export const EXECUTE_POST_INDICADORES_OCULTOS = 'EXECUTE_POST_INDICADORES_OCULTOS';
export const EXECUTE_POST_COMPROBANTES = 'EXECUTE_POST_COMPROBANTES';
export const EXECUTE_POST_CATEGORIAS_ITEMS_COMPRA = 'EXECUTE_POST_CATEGORIAS_ITEMS_COMPRA';

export const LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_START = 'LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_START';
export const LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_SUCCESS = 'LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_SUCCESS';
export const LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_FAIL = 'LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_FAIL';

export const LOAD_TIEMPOS_REQUEST_START = 'LOAD_TIEMPOS_REQUEST_START';
export const LOAD_TIEMPOS_REQUEST_SUCCESS = 'LOAD_TIEMPOS_REQUEST_SUCCESS';
export const LOAD_TIEMPOS_REQUEST_FAIL = 'LOAD_TIEMPOS_REQUEST_FAIL';
export const CLEAR_TIEMPOS_REQUEST = 'CLEAR_TIEMPOS_REQUEST';

export const EXECUTE_CAMBIAR_EMPRESA = 'EXECUTE_CAMBIAR_EMPRESA';

export const ACTUALIZAR_INDICADORES = 'ACTUALIZAR_INDICADORES';

export const LOAD_CHANGE_LOGS_START = 'LOAD_CHANGE_LOGS_START';
export const LOAD_CHANGE_LOGS_SUCCESS = 'LOAD_CHANGE_LOGS_SUCCESS';
export const LOAD_CHANGE_LOGS_FAIL = 'LOAD_CHANGE_LOGS_FAIL';

export const EXECUTE_CREATE_CHANGE_LOG = 'EXECUTE_CREATE_CHANGE_LOG';
export const EXECUTE_UPDATE_CHANGE_LOG = 'EXECUTE_UPDATE_CHANGE_LOG';
export const EXECUTE_DELETE_CHANGE_LOG = 'EXECUTE_DELETE_CHANGE_LOG';

export const LOAD_CATEGORIAS_DEL_CLIENTE_INDICADORES_START = 'LOAD_CATEGORIAS_DEL_CLIENTE_INDICADORES_START';
export const LOAD_CATEGORIAS_DEL_CLIENTE_INDICADORES_SUCCESS = 'LOAD_CATEGORIAS_DEL_CLIENTE_INDICADORES_SUCCESS';
export const LOAD_CATEGORIAS_DEL_CLIENTE_INDICADORES_FAIL = 'LOAD_CATEGORIAS_DEL_CLIENTE_INDICADORES_FAIL';

export const LOAD_CATEGORIAS_DEL_CLIENTE_START = 'LOAD_CATEGORIAS_DEL_CLIENTE_START';
export const LOAD_CATEGORIAS_DEL_CLIENTE_SUCCESS = 'LOAD_CATEGORIAS_DEL_CLIENTE_SUCCESS';
export const LOAD_CATEGORIAS_DEL_CLIENTE_FAIL = 'LOAD_CATEGORIAS_DEL_CLIENTE_FAIL';

export const EXECUTE_POST_CATEGORIAS_DEL_CLIENTE_INDICADORES = 'EXECUTE_POST_CATEGORIAS_DEL_CLIENTE_INDICADORES';
export const EXECUTE_POST_CATEGORIAS_DEL_CLIENTE = 'EXECUTE_POST_CATEGORIAS_DEL_CLIENTE';
export const EXECUTE_POST_CATEGORIAS_DEL_PROVEEDOR = 'EXECUTE_POST_CATEGORIAS_DEL_PROVEEDOR';


export const LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_START = 'LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_SUCCESS = 'LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_FAIL = 'LOAD_DEUDA_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_DEUDA_POR_CATEGORIA_DE_CLIENTE = 'CLEAR_DEUDA_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_VENTAS_POR_MARCA_START = 'LOAD_VENTAS_POR_MARCA_START';
export const LOAD_VENTAS_POR_MARCA_SUCCESS = 'LOAD_VENTAS_POR_MARCA_SUCCESS';
export const LOAD_VENTAS_POR_MARCA_FAIL = 'LOAD_VENTAS_POR_MARCA_FAIL';
export const CLEAR_VENTAS_POR_MARCA = 'CLEAR_VENTAS_POR_MARCA';

export const LOAD_UTILIDAD_POR_MARCA_START = 'LOAD_UTILIDAD_POR_MARCA_START';
export const LOAD_UTILIDAD_POR_MARCA_SUCCESS = 'LOAD_UTILIDAD_POR_MARCA_SUCCESS';
export const LOAD_UTILIDAD_POR_MARCA_FAIL = 'LOAD_UTILIDAD_POR_MARCA_FAIL';
export const CLEAR_UTILIDAD_POR_MARCA = 'CLEAR_UTILIDAD_POR_MARCA';
export const UPDATE_UTILIDADES_POR_MARCA_OCULTOS = 'UPDATE_UTILIDADES_POR_MARCA_OCULTOS';

export const LOAD_VENTAS_POR_RUBRO_START = 'LOAD_VENTAS_POR_RUBRO_START';
export const LOAD_VENTAS_POR_RUBRO_SUCCESS = 'LOAD_VENTAS_POR_RUBRO_SUCCESS';
export const LOAD_VENTAS_POR_RUBRO_FAIL = 'LOAD_VENTAS_POR_RUBRO_FAIL';
export const CLEAR_VENTAS_POR_RUBRO = 'CLEAR_VENTAS_POR_RUBRO';

export const LOAD_UTILIDAD_POR_RUBRO_START = 'LOAD_UTILIDAD_POR_RUBRO_START';
export const LOAD_UTILIDAD_POR_RUBRO_SUCCESS = 'LOAD_UTILIDAD_POR_RUBRO_SUCCESS';
export const LOAD_UTILIDAD_POR_RUBRO_FAIL = 'LOAD_UTILIDAD_POR_RUBRO_FAIL';
export const CLEAR_UTILIDAD_POR_RUBRO = 'CLEAR_UTILIDAD_POR_RUBRO';
export const UPDATE_UTILIDADES_POR_RUBRO_OCULTOS = 'UPDATE_UTILIDADES_POR_RUBRO_OCULTOS';

export const LOAD_VENTAS_POR_SUBRUBRO_START = 'LOAD_VENTAS_POR_SUBRUBRO_START';
export const LOAD_VENTAS_POR_SUBRUBRO_SUCCESS = 'LOAD_VENTAS_POR_SUBRUBRO_SUCCESS';
export const LOAD_VENTAS_POR_SUBRUBRO_FAIL = 'LOAD_VENTAS_POR_SUBRUBRO_FAIL';
export const CLEAR_VENTAS_POR_SUBRUBRO = 'CLEAR_VENTAS_POR_SUBRUBRO';

export const LOAD_UTILIDAD_POR_SUBRUBRO_START = 'LOAD_UTILIDAD_POR_SUBRUBRO_START';
export const LOAD_UTILIDAD_POR_SUBRUBRO_SUCCESS = 'LOAD_UTILIDAD_POR_SUBRUBRO_SUCCESS';
export const LOAD_UTILIDAD_POR_SUBRUBRO_FAIL = 'LOAD_UTILIDAD_POR_SUBRUBRO_FAIL';
export const CLEAR_UTILIDAD_POR_SUBRUBRO = 'CLEAR_UTILIDAD_POR_SUBRUBRO';
export const UPDATE_UTILIDADES_POR_SUBRUBRO_OCULTOS = 'UPDATE_UTILIDADES_POR_SUBRUBRO_OCULTOS';

export const UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA = 'UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA';
export const CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA = 'CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA';

export const UPDATE_ULTIMA_ACTUALIZACION_GASTOS = 'UPDATE_ULTIMA_ACTUALIZACION_GASTOS';
export const CLEAR_ULTIMA_ACTUALIZACION_GASTOS = 'CLEAR_ULTIMA_ACTUALIZACION_GASTOS';

export const UPDATE_ULTIMA_ACTUALIZACION_MULTIEMPRESA = 'UPDATE_ULTIMA_ACTUALIZACION_MULTIEMPRESA';
export const CLEAR_ULTIMA_ACTUALIZACION_MULTIEMPRESA = 'CLEAR_ULTIMA_ACTUALIZACION_MULTIEMPRESA';

export const UPDATE_ULTIMA_ACTUALIZACION_RENTABILIDAD = 'UPDATE_ULTIMA_ACTUALIZACION_RENTABILIDAD';
export const CLEAR_ULTIMA_ACTUALIZACION_RENTABILIDAD = 'CLEAR_ULTIMA_ACTUALIZACION_RENTABILIDAD';

export const UPDATE_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST = 'UPDATE_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST';
export const CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST = 'CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST';

export const UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENTAS = 'UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENTAS';
export const CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENTAS = 'CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENTAS';

export const UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_SUCURSALES = 'UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_SUCURSALES';
export const CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_SUCURSALES = 'CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_SUCURSALES';

export const UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENDEDORES = 'UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENDEDORES';
export const CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENDEDORES = 'CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_VENDEDORES';

export const UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_UTILIDAD = 'UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_DE_UTILIDAD';
export const CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_UTILIDAD = 'CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_DE_UTILIDAD';

export const UPDATE_ULTIMA_ACTUALIZACION_NOGANET_HOME = 'UPDATE_ULTIMA_ACTUALIZACION_NOGANET_HOME';
export const CLEAR_ULTIMA_ACTUALIZACION_NOGANET_HOME = 'CLEAR_ULTIMA_ACTUALIZACION_NOGANET_HOME';

export const UPDATE_ULTIMA_ACTUALIZACION_TENACTA_HOME = 'UPDATE_ULTIMA_ACTUALIZACION_TENACTA_HOME';
export const CLEAR_ULTIMA_ACTUALIZACION_TENACTA_HOME = 'CLEAR_ULTIMA_ACTUALIZACION_TENACTA_HOME';

export const LOAD_CLIENTES_START = 'LOAD_CLIENTES_START';
export const LOAD_CLIENTES_SUCCESS = 'LOAD_CLIENTES_SUCCESS';
export const LOAD_CLIENTES_FAIL = 'LOAD_CLIENTES_FAIL';
export const CLIENT_SELECTED = 'CLIENT_SELECTED';

export const LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_START = 'LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_START';
export const LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_SUCCESS = 'LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_SUCCESS';
export const LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_FAIL = 'LOAD_DEUDA_POR_CATEGORIA_DE_PROVEEDOR_FAIL';
export const CLEAR_DEUDA_POR_CATEGORIA_DE_PROVEEDOR = 'CLEAR_DEUDA_POR_CATEGORIA_DE_PROVEEDOR';

export const LOAD_CATEGORIAS_DE_PROVEEDOR_START = 'LOAD_CATEGORIAS_DE_PROVEEDOR_START';
export const LOAD_CATEGORIAS_DE_PROVEEDOR_SUCCESS = 'LOAD_CATEGORIAS_DE_PROVEEDOR_SUCCESS';
export const LOAD_CATEGORIAS_DE_PROVEEDOR_FAIL = 'LOAD_CATEGORIAS_DE_PROVEEDOR_FAIL';

export const SHOW_GLOBAL_MODAL = 'SHOW_GLOBAL_MODAL';
export const CHANGE_GLOBAL_MODAL_BODY = 'CHANGE_GLOBAL_MODAL_BODY';
export const SHOW_LIST_FILTERS_MODAL = 'SHOW_LIST_FILTERS_MODAL';
export const HIDDEN_GLOBAL_MODAL = 'HIDDEN_GLOBAL_MODAL';
export const SHOW_SEND_CHART_MODAL = 'SHOW_SEND_CHART_MODAL';
export const SHOW_BACK_BUTTON_MODAL = 'SHOW_BACK_BUTTON_MODAL';

export const LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_START = 'LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_START';
export const LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_SUCCESS = 'LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_SUCCESS';
export const LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_FAIL = 'LOAD_EVOLUCION_GASTOS_POR_CATEGORIA_FAIL';
export const CLEAR_EVOLUCION_GASTOS_POR_CATEGORIA = 'CLEAR_EVOLUCION_GASTOS_POR_CATEGORIA';

export const LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_START = 'LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_START';
export const LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_SUCCESS = 'LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_SUCCESS';
export const LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_FAIL = 'LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_FAIL';
export const CLEAR_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA = 'CLEAR_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA';

export const EJECUTAR_FOTO_DEL_DIA = 'EJECUTAR_FOTO_DEL_DIA';
export const EJECUTAR_GASTOS = 'EJECUTAR_GASTOS';
export const EJECUTAR_MULTIEMPRESA = 'EJECUTAR_MULTIEMPRESA';
export const EJECUTAR_RESULTADO = 'EJECUTAR_RESULTADO';
export const EJECUTAR_ANALISIS_DE_VENTAS = 'EJECUTAR_ANALISIS_DE_VENTAS';
export const EJECUTAR_ANALISIS_DE_VENDEDOR = 'EJECUTAR_ANALISIS_DE_VENDEDOR';
export const EJECUTAR_ANALISIS_DE_SUCURSAL = 'EJECUTAR_ANALISIS_DE_SUCURSAL';
export const EJECUTAR_ANALISIS_DE_UTILIDAD = 'EJECUTAR_ANALISIS_DE_UTILIDAD';
export const EJECUTAR_TIEMPOS_REQ = 'EJECUTAR_TIEMPOS_REQ';
export const EJECUTAR_NOGANET_HOME = 'EJECUTAR_NOGANET_HOME';
export const EJECUTAR_HOME_TENACTA = 'EJECUTAR_HOME_TENACTA';
export const EJECUTAR_INFORMES_TENACTA = 'EJECUTAR_INFORMES_TENACTA';
export const EJECUTAR_INFORME_DOS_TENACTA = 'EJECUTAR_INFORME_DOS_TENACTA';
export const EJECUTAR_INFORME_TRES_TENACTA = 'EJECUTAR_INFORME_TRES_TENACTA';
export const CAMBIO_PAGINA_RENDER = 'CAMBIO_PAGINA_RENDER';
export const RESET_EJECUCION_PAGINAS = 'RESET_EJECUCION_PAGINAS';

export const LOAD_EVOLUCION_DE_VENTAS_START = 'LOAD_EVOLUCION_DE_VENTAS_START';
export const LOAD_EVOLUCION_DE_VENTAS_SUCCESS = 'LOAD_EVOLUCION_DE_VENTAS_SUCCESS';
export const LOAD_EVOLUCION_DE_VENTAS_FAIL = 'LOAD_EVOLUCION_DE_VENTAS_FAIL';
export const CLEAR_EVOLUCION_DE_VENTAS = 'CLEAR_EVOLUCION_DE_VENTAS';

export const LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_START = 'LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_SUCCESS = 'LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_FAIL = 'LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE = 'CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_START = 'LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_START';
export const LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_SUCCESS = 'LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_SUCCESS';
export const LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_FAIL = 'LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_FAIL';
export const CLEAR_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA = 'CLEAR_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA';

export const LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_START =
  'LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_FAIL =
  'LOAD_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE = 'CLEAR_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_START = 'LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_START';
export const LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_SUCCESS = 'LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_FAIL = 'LOAD_EVOLUCION_VENTAS_POR_VENDEDORES_FAIL';
export const CLEAR_EVOLUCION_VENTAS_POR_VENDEDORES = 'CLEAR_EVOLUCION_VENTAS_POR_VENDEDORES';

export const LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_START = 'LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_START';
export const LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_SUCCESS = 'LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_FAIL = 'LOAD_EVOLUCION_VENTAS_POR_PROVINCIAS_FAIL';
export const CLEAR_EVOLUCION_VENTAS_POR_PROVINCIAS = 'CLEAR_EVOLUCION_VENTAS_POR_PROVINCIAS';

export const LOAD_EVOLUCION_VENTAS_POR_ZONAS_START = 'LOAD_EVOLUCION_VENTAS_POR_ZONAS_START';
export const LOAD_EVOLUCION_VENTAS_POR_ZONAS_SUCCESS = 'LOAD_EVOLUCION_VENTAS_POR_ZONAS_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_POR_ZONAS_FAIL = 'LOAD_EVOLUCION_VENTAS_POR_ZONAS_FAIL';
export const CLEAR_EVOLUCION_VENTAS_POR_ZONAS = 'CLEAR_EVOLUCION_VENTAS_POR_ZONAS';

export const LOAD_EVOLUCION_VENTAS_POR_MARCA_START = 'LOAD_EVOLUCION_VENTAS_POR_MARCA_START';
export const LOAD_EVOLUCION_VENTAS_POR_MARCA_SUCCESS = 'LOAD_EVOLUCION_VENTAS_POR_MARCA_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_POR_MARCA_FAIL = 'LOAD_EVOLUCION_VENTAS_POR_MARCA_FAIL';
export const CLEAR_EVOLUCION_VENTAS_POR_MARCA = 'CLEAR_EVOLUCION_VENTAS_POR_MARCA';

export const LOAD_EVOLUCION_VENTAS_POR_RUBRO_START = 'LOAD_EVOLUCION_VENTAS_POR_RUBRO_START';
export const LOAD_EVOLUCION_VENTAS_POR_RUBRO_SUCCESS = 'LOAD_EVOLUCION_VENTAS_POR_RUBRO_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_POR_RUBRO_FAIL = 'LOAD_EVOLUCION_VENTAS_POR_RUBRO_FAIL';
export const CLEAR_EVOLUCION_VENTAS_POR_RUBRO = 'CLEAR_EVOLUCION_VENTAS_POR_RUBRO';

export const LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_START = 'LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_START';
export const LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_SUCCESS = 'LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_FAIL = 'LOAD_EVOLUCION_VENTAS_POR_SUBRUBRO_FAIL';
export const CLEAR_EVOLUCION_VENTAS_POR_SUBRUBRO = 'CLEAR_EVOLUCION_VENTAS_POR_SUBRUBRO';

export const LOAD_VENTAS_DEL_MES_POR_VENDEDOR_START = 'LOAD_VENTAS_DEL_MES_POR_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_VENDEDOR_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_VENDEDOR_FAIL = 'LOAD_VENTAS_DEL_MES_POR_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_PROVINCIA_START = 'LOAD_VENTAS_DEL_MES_POR_PROVINCIA_START';
export const LOAD_VENTAS_DEL_MES_POR_PROVINCIA_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_PROVINCIA_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_PROVINCIA_FAIL = 'LOAD_VENTAS_DEL_MES_POR_PROVINCIA_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_PROVINCIA = 'CLEAR_VENTAS_DEL_MES_POR_PROVINCIA';

export const LOAD_VENTAS_DEL_MES_POR_ZONA_START = 'LOAD_VENTAS_DEL_MES_POR_ZONA_START';
export const LOAD_VENTAS_DEL_MES_POR_ZONA_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_ZONA_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_ZONA_FAIL = 'LOAD_VENTAS_DEL_MES_POR_ZONA_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_ZONA = 'CLEAR_VENTAS_DEL_MES_POR_ZONA';

export const LOAD_VENTAS_DEL_MES_POR_ARTICULO_START = 'LOAD_VENTAS_DEL_MES_POR_ARTICULO_START';
export const LOAD_VENTAS_DEL_MES_POR_ARTICULO_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_ARTICULO_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_ARTICULO_FAIL = 'LOAD_VENTAS_DEL_MES_POR_ARTICULO_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_ARTICULO = 'CLEAR_VENTAS_DEL_MES_POR_ARTICULO';

export const LOAD_VENTAS_DEL_MES_PIVOT_GRID_START = 'LOAD_VENTAS_DEL_MES_PIVOT_GRID_START';
export const LOAD_VENTAS_DEL_MES_PIVOT_GRID_SUCCESS = 'LOAD_VENTAS_DEL_MES_PIVOT_GRID_SUCCESS';
export const LOAD_VENTAS_DEL_MES_PIVOT_GRID_FAIL = 'LOAD_VENTAS_DEL_MES_PIVOT_GRID_FAIL';
export const CLEAR_VENTAS_DEL_MES_PIVOT_GRID = 'CLEAR_VENTAS_DEL_MES_PIVOT_GRID';

export const LOAD_VENTAS_DEL_MES_POR_MARCA_START = 'LOAD_VENTAS_DEL_MES_POR_MARCA_START';
export const LOAD_VENTAS_DEL_MES_POR_MARCA_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_MARCA_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_MARCA_FAIL = 'LOAD_VENTAS_DEL_MES_POR_MARCA_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_MARCA = 'CLEAR_VENTAS_DEL_MES_POR_MARCA';

export const LOAD_VENTAS_DEL_MES_POR_RUBRO_START = 'LOAD_VENTAS_DEL_MES_POR_RUBRO_START';
export const LOAD_VENTAS_DEL_MES_POR_RUBRO_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_RUBRO_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_RUBRO_FAIL = 'LOAD_VENTAS_DEL_MES_POR_RUBRO_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_RUBRO = 'CLEAR_VENTAS_DEL_MES_POR_RUBRO';

export const LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_START = 'LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_START';
export const LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_FAIL = 'LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO = 'CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO';

export const LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_START = 'LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_START';
export const LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_FAIL = 'LOAD_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO = 'CLEAR_VENTAS_DEL_MES_POR_UNIDAD_DE_NEGOCIO';

export const LOAD_VENTAS_DEL_MES_POR_CANAL_START = 'LOAD_VENTAS_DEL_MES_POR_CANAL_START';
export const LOAD_VENTAS_DEL_MES_POR_CANAL_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_CANAL_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_CANAL_FAIL = 'LOAD_VENTAS_DEL_MES_POR_CANAL_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_CANAL = 'CLEAR_VENTAS_DEL_MES_POR_CANAL';

export const LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_START = 'LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_START';
export const LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_FAIL = 'LOAD_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS = 'CLEAR_VENTAS_DEL_MES_POR_GRUPO_DE_COMPRAS';

export const LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_START = 'LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_START';
export const LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_FAIL = 'LOAD_VENTAS_DEL_MES_POR_RESPONSABLE_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_RESPONSABLE = 'CLEAR_VENTAS_DEL_MES_POR_RESPONSABLE';

export const LOAD_VENTAS_DEL_MES_POR_CLIENTE_START = 'LOAD_VENTAS_DEL_MES_POR_CLIENTE_START';
export const LOAD_VENTAS_DEL_MES_POR_CLIENTE_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_CLIENTE_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_CLIENTE_FAIL = 'LOAD_VENTAS_DEL_MES_POR_CLIENTE_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_CLIENTE = 'CLEAR_VENTAS_DEL_MES_POR_CLIENTE';

export const SELECCION_DATA_EVOLUCION_VENTAS = 'SELECCION_DATA_EVOLUCION_VENTAS';
export const SELECCION_DATA_VENTAS_DEL_MES_POR = 'SELECCION_DATA_VENTAS_DEL_MES_POR';
export const SELECCION_FECHA_VENTAS_DEL_MES_ANIO = 'SELECCION_FECHA_VENTAS_DEL_MES_ANIO';

export const CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR = 'CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR';
export const LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_START = 'LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_START';
export const LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_SUCCESS = 'LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_SUCCESS';
export const LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_FAIL = 'LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_FAIL';

export const LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_START =
  'LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_START';
export const LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_SUCCESS =
  'LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_FAIL =
  'LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR = 'CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_START = 'LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_FAIL = 'LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_START = 'LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_FAIL = 'LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_START =
  'LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_SUCCESS =
  'LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_FAIL = 'LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_START =
  'LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_SUCCESS =
  'LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_FAIL =
  'LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_START = 'LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_FAIL = 'LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR';

export const SELECCION_VENDEDOR_ANALISIS_VENDEDORES = 'SELECCION_VENDEDOR_ANALISIS_VENDEDORES';
export const SELECCION_DATA_VENTAS_DEL_MES_DEL_VENDEDOR_POR = 'SELECCION_DATA_VENTAS_DEL_MES_DEL_VENDEDOR_POR';
export const SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DEL_VENDEDOR = 'SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DEL_VENDEDOR';
export const SELECCION_DATA_EVOLUCION_VENTAS_DEL_VENDEDOR = 'SELECCION_DATA_EVOLUCION_VENTAS_DEL_VENDEDOR';
export const SELECCION_DATA_VENTAS_DEL_MES_DE_VENDEDORES_POR = 'SELECCION_DATA_VENTAS_DEL_MES_DE_VENDEDORES_POR';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_START =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_FAIL =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE =
  'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_START =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_FAIL =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_START = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_FAIL = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_START = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_FAIL = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_START = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_FAIL = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_START =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_FAIL =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO';

export const SELECCION_SUCURSAL_ANALISIS_DE_SUCURSAL = 'SELECCION_SUCURSAL_ANALISIS_DE_SUCURSAL';
export const SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_LA_SUCURSAL = 'SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_LA_SUCURSAL';
export const SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_VENDEDORES = 'SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_VENDEDORES';
export const SELECCION_DATA_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR = 'SELECCION_DATA_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR';
export const SELECCION_DATA_VENTAS_DEL_MES_DE_SUCURSALES_POR = 'SELECCION_DATA_VENTAS_DEL_MES_DE_SUCURSALES_POR';
export const SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_SUCURSALES = 'SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_SUCURSALES';

export const CLEAR_VENTAS_POR_MES_DE_LA_SUCURSAL = 'CLEAR_VENTAS_POR_MES_DE_LA_SUCURSAL';
export const LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_START = 'LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_START';
export const LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_SUCCESS = 'LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_SUCCESS';
export const LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_FAIL = 'LOAD_VENTAS_POR_MES_DE_LA_SUCURSAL_FAIL';

export const CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE =
  'CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_START =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_SUCCESS =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_FAIL =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_FAIL';

export const CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA = 'CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_START = 'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_START';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_SUCCESS =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_SUCCESS';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_FAIL = 'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_MARCA_FAIL';

export const CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO = 'CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_START = 'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_START';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_SUCCESS =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_SUCCESS';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_FAIL = 'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_RUBRO_FAIL';

export const CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO = 'CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_START =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_START';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_SUCCESS =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_SUCCESS';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_FAIL =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_SUBRUBRO_FAIL';

export const CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA = 'CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_START =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_START';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_SUCCESS =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_SUCCESS';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_FAIL =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_PROVINCIA_FAIL';

export const CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA = 'CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_START = 'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_START';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_SUCCESS =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_SUCCESS';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_FAIL = 'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_ZONA_FAIL';

export const CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_START =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_SUCCESS =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_FAIL =
  'LOAD_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR_VENDEDOR_FAIL';

export const CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA = 'CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_START =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_START';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_FAIL =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_MARCA_FAIL';

export const CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE =
  'CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_START =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_FAIL =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_FAIL';

export const CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO = 'CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_START =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_START';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_FAIL =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_RUBRO_FAIL';

export const CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO = 'CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_START =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_START';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_FAIL =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_SUBRUBRO_FAIL';

export const CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA =
  'CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_START =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_START';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_FAIL =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_PROVINCIA_FAIL';

export const CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA = 'CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_START =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_START';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_FAIL = 'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_ZONA_FAIL';

export const CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR = 'CLEAR_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_START =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_START';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_FAIL =
  'LOAD_EVOLUCION_VENTAS_DE_LA_SUCURSAL_POR_VENDEDOR_FAIL';

export const LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_START = 'LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_START';
export const LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_SUCCESS = 'LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_SUCCESS';
export const LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_FAIL = 'LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_FAIL';
export const CLEAR_EVOLUCION_DE_VENTAS_DE_VENDEDORES = 'CLEAR_EVOLUCION_DE_VENTAS_DE_VENDEDORES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_START =
  'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_SUCCESS =
  'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_FAIL =
  'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT =
  'CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT';

export const LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START =
  'LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS =
  'LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL =
  'LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES =
  'CLEAR_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_START = 'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_START = 'LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_START =
  'LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_SUCCESS =
  'LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_START =
  'LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_SUCCESS =
  'LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_FAIL =
  'LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_START = 'LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES';

export const LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_START =
  'LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_FAIL =
  'LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE =
  'CLEAR_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_START = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_FAIL = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_START = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_FAIL = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_START =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_FAIL =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_START =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_FAIL =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_START =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_FAIL =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_START = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_SUCCESS = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_FAIL = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA';

export const LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_START =
  'LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_START';
export const LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_FAIL =
  'LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR = 'CLEAR_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR';

export const LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_START = 'LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_START';
export const LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_SUCCESS = 'LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_SUCCESS';
export const LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_FAIL = 'LOAD_EVOLUCION_DE_VENTAS_DE_SUCURSALES_FAIL';
export const CLEAR_EVOLUCION_DE_VENTAS_DE_SUCURSALES = 'CLEAR_EVOLUCION_DE_VENTAS_DE_SUCURSALES';

export const LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START =
  'LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START';
export const LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS =
  'LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL =
  'LOAD_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES =
  'CLEAR_VENTAS_POR_SUCURSAL_POR_CATEGORIA_DE_CLIENTE_DEL_MES';

export const LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_START = 'LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_START';
export const LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_FAIL = 'LOAD_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES = 'CLEAR_VENTAS_POR_SUCURSAL_POR_MARCA_DEL_MES';

export const LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_START = 'LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_START';
export const LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_FAIL = 'LOAD_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES = 'CLEAR_VENTAS_POR_SUCURSAL_POR_RUBRO_DEL_MES';

export const LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_START =
  'LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_START';
export const LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_SUCCESS =
  'LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_FAIL = 'LOAD_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES = 'CLEAR_VENTAS_POR_SUCURSAL_POR_SUBRUBRO_DEL_MES';

export const LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_START =
  'LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_START';
export const LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_SUCCESS =
  'LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_FAIL =
  'LOAD_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES = 'CLEAR_VENTAS_POR_SUCURSAL_POR_PROVINCIA_DEL_MES';

export const LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_START = 'LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_START';
export const LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_FAIL = 'LOAD_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES = 'CLEAR_VENTAS_POR_SUCURSAL_POR_ZONA_DEL_MES';

export const LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_START =
  'LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_START';
export const LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_SUCCESS =
  'LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_FAIL = 'LOAD_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES = 'CLEAR_VENTAS_POR_SUCURSAL_POR_VENDEDOR_DEL_MES';

export const LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_START =
  'LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_SUCCESS =
  'LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_FAIL =
  'LOAD_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE =
  'CLEAR_VENTAS_DE_SUCURSALES_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_START = 'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_START';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_SUCCESS =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_SUCCESS';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_FAIL = 'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA_FAIL';
export const CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA = 'CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_MARCA';

export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_START = 'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_START';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_SUCCESS =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_SUCCESS';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_FAIL = 'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO_FAIL';
export const CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO = 'CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_RUBRO';

export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_START =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_START';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_SUCCESS =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_SUCCESS';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_FAIL =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO_FAIL';
export const CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO = 'CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_SUBRUBRO';

export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_START =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_START';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_SUCCESS =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_FAIL =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR = 'CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_VENDEDOR';

export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_START =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_START';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_SUCCESS =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_SUCCESS';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_FAIL =
  'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA_FAIL';
export const CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA = 'CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_PROVINCIA';

export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_START = 'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_START';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_SUCCESS = 'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_SUCCESS';
export const LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_FAIL = 'LOAD_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA_FAIL';
export const CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA = 'CLEAR_VENTAS_DE_SUCURSALES_DEL_MES_POR_ZONA';

export const LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_START = 'LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_START';
export const LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_SUCCESS = 'LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_FAIL = 'LOAD_EVOLUCION_VENTAS_POR_SUCURSAL_FAIL';
export const CLEAR_EVOLUCION_VENTAS_POR_SUCURSAL = 'CLEAR_EVOLUCION_VENTAS_POR_SUCURSAL';

export const EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION = 'EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION';

export const LOAD_INFLACION_START = 'LOAD_INFLACION_START';
export const LOAD_INFLACION_SUCCESS = 'LOAD_INFLACION_SUCCESS';
export const LOAD_INFLACION_FAIL = 'LOAD_INFLACION_FAIL';

export const SELECCION_DATA_UTILIDAD_DEL_MES_POR = 'SELECCION_DATA_UTILIDAD_DEL_MES_POR';
export const SELECCION_FECHA_UTILIDAD_DEL_MES_ANIO = 'SELECCION_FECHA_UTILIDAD_DEL_MES_ANIO';

export const LOAD_EVOLUCION_DE_UTILIDAD_START = 'LOAD_EVOLUCION_DE_UTILIDAD_START';
export const LOAD_EVOLUCION_DE_UTILIDAD_SUCCESS = 'LOAD_EVOLUCION_DE_UTILIDAD_SUCCESS';
export const LOAD_EVOLUCION_DE_UTILIDAD_FAIL = 'LOAD_EVOLUCION_DE_UTILIDAD_FAIL';
export const CLEAR_EVOLUCION_DE_UTILIDAD = 'CLEAR_EVOLUCION_DE_UTILIDAD';

export const LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_START = 'LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_SUCCESS = 'LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_FAIL = 'LOAD_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_UTILIDAD_POR_CATEGORIA_DE_CLIENTE = 'CLEAR_UTILIDAD_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_UTILIDAD_DEL_MES_POR_MARCA_START = 'LOAD_UTILIDAD_DEL_MES_POR_MARCA_START';
export const LOAD_UTILIDAD_DEL_MES_POR_MARCA_SUCCESS = 'LOAD_UTILIDAD_DEL_MES_POR_MARCA_SUCCESS';
export const LOAD_UTILIDAD_DEL_MES_POR_MARCA_FAIL = 'LOAD_UTILIDAD_DEL_MES_POR_MARCA_FAIL';
export const CLEAR_UTILIDAD_DEL_MES_POR_MARCA = 'CLEAR_UTILIDAD_DEL_MES_POR_MARCA';

export const LOAD_UTILIDAD_DEL_MES_POR_RUBRO_START = 'LOAD_UTILIDAD_DEL_MES_POR_RUBRO_START';
export const LOAD_UTILIDAD_DEL_MES_POR_RUBRO_SUCCESS = 'LOAD_UTILIDAD_DEL_MES_POR_RUBRO_SUCCESS';
export const LOAD_UTILIDAD_DEL_MES_POR_RUBRO_FAIL = 'LOAD_UTILIDAD_DEL_MES_POR_RUBRO_FAIL';
export const CLEAR_UTILIDAD_DEL_MES_POR_RUBRO = 'CLEAR_UTILIDAD_DEL_MES_POR_RUBRO';

export const LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_START = 'LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_START';
export const LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_SUCCESS = 'LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_SUCCESS';
export const LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_FAIL = 'LOAD_UTILIDAD_DEL_MES_POR_SUBRUBRO_FAIL';
export const CLEAR_UTILIDAD_DEL_MES_POR_SUBRUBRO = 'CLEAR_UTILIDAD_DEL_MES_POR_SUBRUBRO';

export const LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_START = 'LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_START';
export const LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_SUCCESS = 'LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_SUCCESS';
export const LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_FAIL = 'LOAD_UTILIDAD_DEL_MES_POR_VENDEDOR_FAIL';
export const CLEAR_UTILIDAD_DEL_MES_POR_VENDEDOR = 'CLEAR_UTILIDAD_DEL_MES_POR_VENDEDOR';

export const LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_START = 'LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_START';
export const LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_SUCCESS = 'LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_SUCCESS';
export const LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_FAIL = 'LOAD_UTILIDAD_DEL_MES_POR_PROVINCIA_FAIL';
export const CLEAR_UTILIDAD_DEL_MES_POR_PROVINCIA = 'CLEAR_UTILIDAD_DEL_MES_POR_PROVINCIA';

export const LOAD_UTILIDAD_DEL_MES_POR_ZONA_START = 'LOAD_UTILIDAD_DEL_MES_POR_ZONA_START';
export const LOAD_UTILIDAD_DEL_MES_POR_ZONA_SUCCESS = 'LOAD_UTILIDAD_DEL_MES_POR_ZONA_SUCCESS';
export const LOAD_UTILIDAD_DEL_MES_POR_ZONA_FAIL = 'LOAD_UTILIDAD_DEL_MES_POR_ZONA_FAIL';
export const CLEAR_UTILIDAD_DEL_MES_POR_ZONA = 'CLEAR_UTILIDAD_DEL_MES_POR_ZONA';

export const LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_START =
  'LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_SUCCESS =
  'LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_FAIL =
  'LOAD_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE = 'CLEAR_EVOLUCION_UTILIDAD_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_START = 'LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_START';
export const LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_SUCCESS = 'LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_SUCCESS';
export const LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_FAIL = 'LOAD_EVOLUCION_UTILIDAD_POR_VENDEDORES_FAIL';
export const CLEAR_EVOLUCION_UTILIDAD_POR_VENDEDORES = 'CLEAR_EVOLUCION_UTILIDAD_POR_VENDEDORES';

export const LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_START = 'LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_START';
export const LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_SUCCESS = 'LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_SUCCESS';
export const LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_FAIL = 'LOAD_EVOLUCION_UTILIDAD_POR_PROVINCIAS_FAIL';
export const CLEAR_EVOLUCION_UTILIDAD_POR_PROVINCIAS = 'CLEAR_EVOLUCION_UTILIDAD_POR_PROVINCIAS';

export const LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_START = 'LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_START';
export const LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_SUCCESS = 'LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_SUCCESS';
export const LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_FAIL = 'LOAD_EVOLUCION_UTILIDAD_POR_ZONAS_FAIL';
export const CLEAR_EVOLUCION_UTILIDAD_POR_ZONAS = 'CLEAR_EVOLUCION_UTILIDAD_POR_ZONAS';

export const LOAD_EVOLUCION_UTILIDAD_POR_MARCA_START = 'LOAD_EVOLUCION_UTILIDAD_POR_MARCA_START';
export const LOAD_EVOLUCION_UTILIDAD_POR_MARCA_SUCCESS = 'LOAD_EVOLUCION_UTILIDAD_POR_MARCA_SUCCESS';
export const LOAD_EVOLUCION_UTILIDAD_POR_MARCA_FAIL = 'LOAD_EVOLUCION_UTILIDAD_POR_MARCA_FAIL';
export const CLEAR_EVOLUCION_UTILIDAD_POR_MARCA = 'CLEAR_EVOLUCION_UTILIDAD_POR_MARCA';

export const LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_START = 'LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_START';
export const LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_SUCCESS = 'LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_SUCCESS';
export const LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_FAIL = 'LOAD_EVOLUCION_UTILIDAD_POR_RUBRO_FAIL';
export const CLEAR_EVOLUCION_UTILIDAD_POR_RUBRO = 'CLEAR_EVOLUCION_UTILIDAD_POR_RUBRO';

export const LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_START = 'LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_START';
export const LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_SUCCESS = 'LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_SUCCESS';
export const LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_FAIL = 'LOAD_EVOLUCION_UTILIDAD_POR_SUBRUBRO_FAIL';
export const CLEAR_EVOLUCION_UTILIDAD_POR_SUBRUBRO = 'CLEAR_EVOLUCION_UTILIDAD_POR_SUBRUBRO';

export const SET_USER_INFO = 'SET_USER_INFO';
export const LOAD_INFLACION_POR_ANIO_START = 'LOAD_INFLACION_POR_ANIO_START';
export const LOAD_INFLACION_POR_ANIO_SUCCESS = 'LOAD_INFLACION_POR_ANIO_SUCCESS';
export const LOAD_INFLACION_POR_ANIO_FAIL = 'LOAD_INFLACION_POR_ANIO_FAIL';

export const LOAD_NOVEDADES_START = 'LOAD_NOVEDADES_START';
export const LOAD_NOVEDADES_SUCCESS = 'LOAD_NOVEDADES_SUCCESS';
export const LOAD_NOVEDADES_FAIL = 'LOAD_NOVEDADES_FAIL';

export const LOAD_ESTADOS_CHEQUES_START = 'LOAD_ESTADOS_CHEQUES_START';
export const LOAD_ESTADOS_CHEQUES_SUCCESS = 'LOAD_ESTADOS_CHEQUES_SUCCESS';
export const LOAD_ESTADOS_CHEQUES_FAIL = 'LOAD_ESTADOS_CHEQUES_FAIL';

export const LOAD_CHEQUES_VALOR_ESTADO_START = 'LOAD_CHEQUES_VALOR_ESTADO_START';
export const LOAD_CHEQUES_VALOR_ESTADO_SUCCESS = 'LOAD_CHEQUES_VALOR_ESTADO_SUCCESS';
export const LOAD_CHEQUES_VALOR_ESTADO_FAIL = 'LOAD_CHEQUES_VALOR_ESTADO_FAIL';

export const STOCK_VALORIZADO_START = 'STOCK_VALORIZADO_START';
export const STOCK_VALORIZADO_SUCCESS = 'STOCK_VALORIZADO_SUCCESS';
export const STOCK_VALORIZADO_FAIL = 'STOCK_VALORIZADO_FAIL';
export const CLEAR_STOCK_VALORIZADO = 'CLEAR_STOCK_VALORIZADO';
export const EJECUCION_STOCK_VALORIZADO = 'EJECUCION_STOCK_VALORIZADO';

export const EXECUTE_CAMBIAR_SUCURSAL = 'EXECUTE_CAMBIAR_SUCURSAL';

export const EXECUTE_CAMBIAR_VENDEDOR_ANALISIS_DE_VENTAS = 'EXECUTE_CAMBIAR_VENDEDOR_ANALISIS_DE_VENTAS';

export const CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO = 'CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO';
export const LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START = 'LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START';
export const LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS = 'LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS';
export const LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL = 'LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL';
export const EJECUCION_DEUDA_DEL_MES_POR_ORDENAMIENTO = 'EJECUCION_DEUDA_DEL_MES_POR_ORDENAMIENTO';

export const CLEAR_COBRANZAS_DEL_DIA = 'CLEAR_COBRANZAS_DEL_DIA';
export const COBRANZAS_DEL_DIA_START = 'COBRANZAS_DEL_DIA_START';
export const COBRANZAS_DEL_DIA_SUCCESS = 'COBRANZAS_DEL_DIA_SUCCESS';
export const COBRANZAS_DEL_DIA_FAIL = 'COBRANZAS_DEL_DIA_FAIL';
export const COBRANZAS_DEL_DIA_REQUEST = 'COBRANZAS_DEL_DIA_REQUEST';

export const CLEAR_DETALLE_COBRANZAS_DEL_DIA = 'CLEAR_DETALLE_COBRANZAS_DEL_DIA';
export const DETALLE_COBRANZAS_DEL_DIA_START = 'DETALLE_COBRANZAS_DEL_DIA_START';
export const DETALLE_COBRANZAS_DEL_DIA_SUCCESS = 'DETALLE_COBRANZAS_DEL_DIA_SUCCESS';
export const DETALLE_COBRANZAS_DEL_DIA_FAIL = 'DETALLE_COBRANZAS_DEL_DIA_FAIL';

export const CLEAR_CHEQUES_DEPOSITADOS_DEL_DIA = 'CLEAR_CHEQUES_DEPOSITADOS_DEL_DIA';
export const CHEQUES_DEPOSITADOS_DEL_DIA_START = 'CHEQUES_DEPOSITADOS_DEL_DIA_START';
export const CHEQUES_DEPOSITADOS_DEL_DIA_SUCCESS = 'CHEQUES_DEPOSITADOS_DEL_DIA_SUCCESS';
export const CHEQUES_DEPOSITADOS_DEL_DIA_FAIL = 'CHEQUES_DEPOSITADOS_DEL_DIA_FAIL';

export const CLEAR_TOTAL_NOTAS_DE_PEDIDO = 'CLEAR_TOTAL_NOTAS_DE_PEDIDO';
export const TOTAL_NOTAS_DE_PEDIDO_START = 'TOTAL_NOTAS_DE_PEDIDO_START';
export const TOTAL_NOTAS_DE_PEDIDO_SUCCESS = 'TOTAL_NOTAS_DE_PEDIDO_SUCCESS';
export const TOTAL_NOTAS_DE_PEDIDO_FAIL = 'TOTAL_NOTAS_DE_PEDIDO_FAIL';

export const CLEAR_DETALLE_NOTAS_DE_PEDIDO = 'CLEAR_DETALLE_NOTAS_DE_PEDIDO';
export const DETALLE_NOTAS_DE_PEDIDO_START = 'DETALLE_NOTAS_DE_PEDIDO_START';
export const DETALLE_NOTAS_DE_PEDIDO_SUCCESS = 'DETALLE_NOTAS_DE_PEDIDO_SUCCESS';
export const DETALLE_NOTAS_DE_PEDIDO_FAIL = 'DETALLE_NOTAS_DE_PEDIDO_FAIL';

export const STOCK_POR_DEPOSITO_START = 'STOCK_POR_DEPOSITO_START';
export const STOCK_POR_DEPOSITO_SUCCESS = 'STOCK_POR_DEPOSITO_SUCCESS';
export const STOCK_POR_DEPOSITO_FAIL = 'STOCK_POR_DEPOSITO_FAIL';
export const CLEAR_STOCK_POR_DEPOSITO = 'CLEAR_STOCK_POR_DEPOSITO';

export const LOAD_CLIENTE_BY_ID_START = 'LOAD_CLIENTE_BY_ID_START';
export const LOAD_CLIENTE_BY_ID_SUCCESS = 'LOAD_CLIENTE_BY_ID_SUCCESS';
export const LOAD_CLIENTE_BY_ID_FAIL = 'LOAD_CLIENTE_BY_ID_FAIL';
export const UNMOUNT_CLIENTE_BY_ID = 'UNMOUNT_CLIENTE_BY_ID';

export const LOAD_ULTIMA_COMPRA_BY_CLIENTE_START = 'LOAD_ULTIMA_COMPRA_BY_CLIENTE_START';
export const LOAD_ULTIMA_COMPRA_BY_CLIENTE_SUCCESS = 'LOAD_ULTIMA_COMPRA_BY_CLIENTE_SUCCESS';
export const LOAD_ULTIMA_COMPRA_BY_CLIENTE_FAIL = 'LOAD_ULTIMA_COMPRA_BY_CLIENTE_FAIL';
export const CLEAR_ULTIMA_COMPRA_BY_CLIENTE = 'CLEAR_ULTIMA_COMPRA_BY_CLIENTE';

export const LOAD_CUENTA_CORRIENTE_CLIENTE_START = 'LOAD_CUENTA_CORRIENTE_CLIENTE_START';
export const LOAD_CUENTA_CORRIENTE_CLIENTE_SUCCESS = 'LOAD_CUENTA_CORRIENTE_CLIENTE_SUCCESS';
export const LOAD_CUENTA_CORRIENTE_CLIENTE_FAIL = 'LOAD_CUENTA_CORRIENTE_CLIENTE_FAIL';
export const CLEAR_CUENTA_CORRIENTE_CLIENTE = 'CLEAR_CUENTA_CORRIENTE_CLIENTE';

export const LOAD_PEDIDOS_PENDIENTES_START = 'LOAD_PEDIDOS_PENDIENTES_START';
export const LOAD_PEDIDOS_PENDIENTES_SUCCESS = 'LOAD_PEDIDOS_PENDIENTES_SUCCESS';
export const LOAD_PEDIDOS_PENDIENTES_FAIL = 'LOAD_PEDIDOS_PENDIENTES_FAIL';
export const CLEAR_PEDIDOS_PENDIENTES = 'CLEAR_PEDIDOS_PENDIENTES';

export const LOAD_PEDIDOS_MES_COMPARATIVO_START = 'LOAD_PEDIDOS_MES_COMPARATIVO_START';
export const LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS = 'LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS';
export const LOAD_PEDIDOS_MES_COMPARATIVO_FAIL = 'LOAD_PEDIDOS_MES_COMPARATIVO_FAIL';
export const CLEAR_PEDIDOS_MES_COMPARATIVO = 'CLEAR_PEDIDOS_MES_COMPARATIVO';

export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_START = 'LOAD_ESTADOS_PEDIDOS_PENDIENTES_START';
export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS = 'LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS';
export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL = 'LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL';
export const CLEAR_ESTADOS_PEDIDOS_PENDIENTES = 'CLEAR_ESTADOS_PEDIDOS_PENDIENTES';

export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START = 'LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START';
export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS = 'LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS';
export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL = 'LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL';
export const CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES = 'CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES';

export const LOAD_VENTAS_POR_VENDEDOR_START = 'LOAD_VENTAS_POR_VENDEDOR_START';
export const LOAD_VENTAS_POR_VENDEDOR_SUCCESS = 'LOAD_VENTAS_POR_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR = 'CLEAR_VENTAS_POR_VENDEDOR';

export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START = 'LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START';
export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS = 'LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS';
export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL = 'LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL';
export const CLEAR_COMPROBANTES_FACTURAS_PENDIENTES = 'CLEAR_COMPROBANTES_FACTURAS_PENDIENTES';

export const SHOW_ESTADO_MODAL = 'SHOW_ESTADO_MODAL';
export const HIDDEN_ESTADO_MODAL = 'HIDDEN_ESTADO_MODAL';

export const VENTAS_DEL_MES_A_UN_CLIENTE_START = 'VENTAS_DEL_MES_A_UN_CLIENTE_START';
export const VENTAS_DEL_MES_A_UN_CLIENTE_SUCCESS = 'VENTAS_DEL_MES_A_UN_CLIENTE_SUCCESS';
export const VENTAS_DEL_MES_A_UN_CLIENTE_FAIL = 'VENTAS_DEL_MES_A_UN_CLIENTE_FAIL';
export const CLEAR_VENTAS_DEL_MES_A_UN_CLIENTE = 'CLEAR_VENTAS_DEL_MES_A_UN_CLIENTE';

export const LOAD_EVOLUCION_DE_COMPRAS_START = 'LOAD_EVOLUCION_DE_COMPRAS_START';
export const LOAD_EVOLUCION_DE_COMPRAS_SUCCESS = 'LOAD_EVOLUCION_DE_COMPRAS_SUCCESS';
export const LOAD_EVOLUCION_DE_COMPRAS_FAIL = 'LOAD_EVOLUCION_DE_COMPRAS_FAIL';
export const CLEAR_EVOLUCION_DE_COMPRAS = 'CLEAR_EVOLUCION_DE_COMPRAS';

export const LOAD_CHEQUES_EN_CARTERA_START = 'LOAD_CHEQUES_EN_CARTERA_START';
export const LOAD_CHEQUES_EN_CARTERA_SUCCESS = 'LOAD_CHEQUES_EN_CARTERA_SUCCESS';
export const LOAD_CHEQUES_EN_CARTERA_FAIL = 'LOAD_CHEQUES_EN_CARTERA_FAIL';
export const CLEAR_CHEQUES_EN_CARTERA = 'CLEAR_CHEQUES_EN_CARTERA';

export const LOAD_FACTURAS_PENDIENTES_START = 'LOAD_FACTURAS_PENDIENTES_START';
export const LOAD_FACTURAS_PENDIENTES_SUCCESS = 'LOAD_FACTURAS_PENDIENTES_SUCCESS';
export const LOAD_FACTURAS_PENDIENTES_FAIL = 'LOAD_FACTURAS_PENDIENTES_FAIL';
export const CLEAR_FACTURAS_PENDIENTES = 'CLEAR_FACTURAS_PENDIENTES';

export const LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_START = 'LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_START';
export const LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_SUCCESS = 'LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_SUCCESS';
export const LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_FAIL = 'LOAD_EVOLUCION_DE_VENTAS_INTERANUAL_FAIL';
export const CLEAR_EVOLUCION_DE_VENTAS_INTERANUAL = 'CLEAR_EVOLUCION_DE_VENTAS_INTERANUAL';
export const CLEAR_EVOLUCIONES_DE_VENTAS_INTERANUAL = 'CLEAR_EVOLUCIONES_DE_VENTAS_INTERANUAL';
export const AGREGAR_EVOLUCION_DE_VENTAS_INTERANUAL = 'AGREGAR_EVOLUCION_DE_VENTAS_INTERANUAL';
export const UPDATE_FILTRO_EVOLUCION_DE_VENTAS_INTERANUAL = 'UPDATE_FILTRO_EVOLUCION_DE_VENTAS_INTERANUAL';
export const CLEAR_FILTRO_EVOLUCION_DE_VENTAS_INTERANUAL = 'CLEAR_FILTRO_EVOLUCION_DE_VENTAS_INTERANUAL';

export const LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_START = 'LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_START';
export const LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_SUCCESS = 'LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_SUCCESS';
export const LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_FAIL = 'LOAD_EVOLUCION_DE_GASTOS_INTERANUAL_FAIL';
export const CLEAR_EVOLUCION_DE_GASTOS_INTERANUAL = 'CLEAR_EVOLUCION_DE_GASTOS_INTERANUAL';
export const CLEAR_EVOLUCIONES_DE_GASTOS_INTERANUAL = 'CLEAR_EVOLUCIONES_DE_GASTOS_INTERANUAL';
export const AGREGAR_EVOLUCION_DE_GASTOS_INTERANUAL = 'AGREGAR_EVOLUCION_DE_GASTOS_INTERANUAL';
export const SELECCION_FECHA_DE_GASTOS_INTERANUAL = 'SELECCION_FECHA_DE_GASTOS_INTERANUAL';

export const LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_START = 'LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_START';
export const LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_SUCCESS = 'LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_SUCCESS';
export const LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_FAIL = 'LOAD_EVOLUCION_DE_UTILIDADES_INTERANUAL_FAIL';
export const CLEAR_EVOLUCION_DE_UTILIDADES_INTERANUAL = 'CLEAR_EVOLUCION_DE_UTILIDADES_INTERANUAL';
export const CLEAR_EVOLUCIONES_DE_UTILIDADES_INTERANUAL = 'CLEAR_EVOLUCIONES_DE_UTILIDADES_INTERANUAL';
export const AGREGAR_EVOLUCION_DE_UTILIDADES_INTERANUAL = 'AGREGAR_EVOLUCION_DE_UTILIDADES_INTERANUAL';

export const LOAD_PIVOT_GRID_CONFIGURACIONES_START = 'LOAD_PIVOT_GRID_CONFIGURACIONES_START';
export const LOAD_PIVOT_GRID_CONFIGURACIONES_SUCCESS = 'LOAD_PIVOT_GRID_CONFIGURACIONES_SUCCESS';
export const LOAD_PIVOT_GRID_CONFIGURACIONES_FAIL = 'LOAD_PIVOT_GRID_CONFIGURACIONES_FAIL';
export const CLEAR_PIVOT_GRID_CONFIGURACIONES = 'CLEAR_PIVOT_GRID_CONFIGURACIONES';

export const LOAD_PIVOT_GRID_INFORMACIONES_START = 'LOAD_PIVOT_GRID_INFORMACIONES_START';
export const LOAD_PIVOT_GRID_INFORMACIONES_SUCCESS = 'LOAD_PIVOT_GRID_INFORMACIONES_SUCCESS';
export const LOAD_PIVOT_GRID_INFORMACIONES_FAIL = 'LOAD_PIVOT_GRID_INFORMACIONES_FAIL';
export const CLEAR_PIVOT_GRID_INFORMACIONES = 'CLEAR_PIVOT_GRID_INFORMACIONES';

export const LOAD_PIVOT_GRID_INFORMACION_START = 'LOAD_PIVOT_GRID_INFORMACION_START';
export const LOAD_PIVOT_GRID_INFORMACION_SUCCESS = 'LOAD_PIVOT_GRID_INFORMACION_SUCCESS';
export const LOAD_PIVOT_GRID_INFORMACION_FAIL = 'LOAD_PIVOT_GRID_INFORMACION_FAIL';
export const CLEAR_PIVOT_GRID_INFORMACION = 'CLEAR_PIVOT_GRID_INFORMACION';

export const LOAD_INFORME_UNO_DATA_START = 'LOAD_INFORME_UNO_DATA_START';
export const LOAD_INFORME_UNO_DATA_SUCCESS = 'LOAD_INFORME_UNO_DATA_SUCCESS';
export const LOAD_INFORME_UNO_DATA_FAIL = 'LOAD_INFORME_UNO_DATA_FAIL';
export const CLEAR_INFORME_UNO_DATA = 'CLEAR_INFORME_UNO_DATA';
export const UPDATE_INFORME_UNO_FILTROS = 'UPDATE_INFORME_UNO_FILTROS';
export const UPDATE_FILTROS_CHART_INFORME_UNO = 'UPDATE_FILTROS_CHART_INFORME_UNO';

export const LOAD_INFORME_DOS_DATA_START = 'LOAD_INFORME_DOS_DATA_START';
export const LOAD_INFORME_DOS_DATA_SUCCESS = 'LOAD_INFORME_DOS_DATA_SUCCESS';
export const LOAD_INFORME_DOS_DATA_FAIL = 'LOAD_INFORME_DOS_DATA_FAIL';
export const CLEAR_INFORME_DOS_DATA = 'CLEAR_INFORME_DOS_DATA';
export const UPDATE_INFORME_DOS_FILTROS = 'UPDATE_INFORME_DOS_FILTROS';

export const EXPORTAR_INFORME_DOS_EXPANDIDO_START = 'EXPORTAR_INFORME_DOS_EXPANDIDO_START';
export const EXPORTAR_INFORME_DOS_EXPANDIDO_SUCCESS = 'EXPORTAR_INFORME_DOS_EXPANDIDO_SUCCESS';
export const EXPORTAR_INFORME_DOS_EXPANDIDO_FAIL = 'EXPORTAR_INFORME_DOS_EXPANDIDO_FAIL';
export const EXPORTAR_INFORME_DOS_EXPANDIDO_CLEAR = 'EXPORTAR_INFORME_DOS_EXPANDIDO_CLEAR';

export const UPDATE_PIVOT_GRID_CONFIGURACION_FIELDS = 'UPDATE_PIVOT_GRID_CONFIGURACION_FIELDS';

export const LOAD_ANIOS_FISCALES_START = 'LOAD_ANIOS_FISCALES_START';
export const LOAD_ANIOS_FISCALES_SUCCESS = 'LOAD_ANIOS_FISCALES_SUCCESS';
export const LOAD_ANIOS_FISCALES_FAIL = 'LOAD_ANIOS_FISCALES_FAIL';
export const CLEAR_ANIOS_FISCALES = 'CLEAR_ANIOS_FISCALES';

export const LOAD_BUDGETS_FORECASTS_START = 'LOAD_BUDGETS_FORECASTS_START';
export const LOAD_BUDGETS_FORECASTS_SUCCESS = 'LOAD_BUDGETS_FORECASTS_SUCCESS';
export const LOAD_BUDGETS_FORECASTS_FAIL = 'LOAD_BUDGETS_FORECASTS_FAIL';
export const CLEAR_BUDGETS_FORECASTS = 'CLEAR_BUDGETS_FORECASTS';

export const LOAD_INFORME_DOS_BUDGETS_FORECASTS_START = 'LOAD_INFORME_DOS_BUDGETS_FORECASTS_START';
export const LOAD_INFORME_DOS_BUDGETS_FORECASTS_SUCCESS = 'LOAD_INFORME_DOS_BUDGETS_FORECASTS_SUCCESS';
export const LOAD_INFORME_DOS_BUDGETS_FORECASTS_FAIL = 'LOAD_INFORME_DOS_BUDGETS_FORECASTS_FAIL';
export const CLEAR_INFORME_DOS_BUDGETS_FORECASTS = 'CLEAR_INFORME_DOS_BUDGETS_FORECASTS';

export const UPLOAD_FILE_DATA_TO_SERVER_START = 'UPLOAD_FILE_DATA_TO_SERVER_START';
export const UPLOAD_FILE_DATA_TO_SERVER_SUCCESS = 'UPLOAD_FILE_DATA_TO_SERVER_SUCCESS';
export const UPLOAD_FILE_DATA_TO_SERVER_FAIL = 'UPLOAD_FILE_DATA_TO_SERVER_FAIL';
export const CLEAR_UPLOAD_FILE_DATA_TO_SERVER = 'CLEAR_UPLOAD_FILE_DATA_TO_SERVER';

export const UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_START = 'UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_START';
export const UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_SUCCESS = 'UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_SUCCESS';
export const UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_FAIL = 'UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_FAIL';
export const CLEAR_UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER = 'CLEAR_UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER';

export const APPROVE_BUDGET_FORECAST_START = 'APPROVE_BUDGET_FORECAST_START';
export const APPROVE_BUDGET_FORECAST_SUCCESS = 'APPROVE_BUDGET_FORECAST_SUCCESS';
export const APPROVE_BUDGET_FORECAST_FAIL = 'APPROVE_BUDGET_FORECAST_FAIL';
export const CLEAR_APPROVE_BUDGET_FORECAST = 'CLEAR_APPROVE_BUDGET_FORECAST';

export const APPROVE_INFORME_DOS_BUDGET_FORECAST_START = 'APPROVE_INFORME_DOS_BUDGET_FORECAST_START';
export const APPROVE_INFORME_DOS_BUDGET_FORECAST_SUCCESS = 'APPROVE_INFORME_DOS_BUDGET_FORECAST_SUCCESS';
export const APPROVE_INFORME_DOS_BUDGET_FORECAST_FAIL = 'APPROVE_INFORME_DOS_BUDGET_FORECAST_FAIL';
export const CLEAR_APPROVE_INFORME_DOS_BUDGET_FORECAST = 'CLEAR_APPROVE_INFORME_DOS_BUDGET_FORECAST';

export const SELECCION_DATA_EVOLUCION_VENTAS_POR_PERIODOS = 'SELECCION_DATA_EVOLUCION_VENTAS_POR_PERIODOS';
export const SELECCION_DATA_VENTAS_POR_PERIODOS_POR = 'SELECCION_DATA_VENTAS_POR_PERIODOS_POR';
export const SELECCION_DATA_VENTAS_POR_MES_POR = 'SELECCION_DATA_VENTAS_POR_MES_POR';
export const FILTROS_EVOLUCION_VENTAS_POR_PERIODOS = 'FILTROS_EVOLUCION_VENTAS_POR_PERIODOS';
export const FILTROS_VENTAS_COMPARATIVAS_POR_PERIODOS_POR = 'FILTROS_VENTAS_COMPARATIVAS_POR_PERIODOS_POR';
export const FILTROS_VENTAS_COMPARATIVAS_POR_MES_POR = 'FILTROS_VENTAS_COMPARATIVAS_POR_MES_POR';
export const ACTUALIZAR_DATA_EVOLUCION_VENTAS_POR_PERIODOS = 'ACTUALIZAR_DATA_EVOLUCION_VENTAS_POR_PERIODOS';
export const ACTUALIZAR_DATA_COMPARATIVAS_POR_PERIODOS = 'ACTUALIZAR_DATA_COMPARATIVAS_POR_PERIODOS';
export const ACTUALIZAR_DATA_COMPARATIVAS_POR_MES = 'ACTUALIZAR_DATA_COMPARATIVAS_POR_MES';

export const SELECCION_TIPO_VENTAS_DESAGREGADAS = 'SELECCION_TIPO_VENTAS_DESAGREGADAS';
export const LOAD_TIPO_VENTAS_DESAGREGADAS_START = 'LOAD_TIPO_VENTAS_DESAGREGADAS_START';
export const LOAD_TIPO_VENTAS_DESAGREGADAS_SUCCESS = 'LOAD_TIPO_VENTAS_DESAGREGADAS_SUCCESS';
export const LOAD_TIPO_VENTAS_DESAGREGADAS_FAIL = 'LOAD_TIPO_VENTAS_DESAGREGADAS_FAIL';
export const CLEAR_TIPO_VENTAS_DESAGREGADAS = 'CLEAR_TIPO_VENTAS_DESAGREGADAS';

export const LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_START =
  'LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_START';
export const LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_FAIL =
  'LOAD_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_FAIL';
export const CLEAR_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA = 'CLEAR_EVOLUCION_VENTAS_POR_PERIODOS_POR_TIPO_VENTA';

export const LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_START = 'LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_START';
export const LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_SUCCESS = 'LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_SUCCESS';
export const LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_FAIL = 'LOAD_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA_FAIL';
export const CLEAR_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA = 'CLEAR_VENTAS_COMPARATIVAS_POR_MES_POR_TIPO_VENTA';

export const LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_START = 'LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_START';
export const LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_SUCCESS = 'LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_SUCCESS';
export const LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_FAIL = 'LOAD_VENTAS_COMPARATIVAS_POR_TIPO_VENTA_FAIL';
export const CLEAR_VENTAS_COMPARATIVAS_POR_TIPO_VENTA = 'CLEAR_VENTAS_COMPARATIVAS_POR_TIPO_VENTA';

export const LOAD_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_START = 'LOAD_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_START';
export const LOAD_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_SUCCESS = 'LOAD_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_SUCCESS';
export const LOAD_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_FAIL = 'LOAD_VENTAS_POR_PERIODOS_POR_TIPO_VENTA_FAIL';
export const CLEAR_VENTAS_POR_PERIODOS_POR_TIPO_VENTA = 'CLEAR_VENTAS_POR_PERIODOS_POR_TIPO_VENTA';

export const LOAD_CUBO_VENTAS_DATA_START = 'LOAD_CUBO_VENTAS_DATA_START';
export const LOAD_CUBO_VENTAS_DATA_SUCCESS = 'LOAD_CUBO_VENTAS_DATA_SUCCESS';
export const LOAD_CUBO_VENTAS_DATA_FAIL = 'LOAD_CUBO_VENTAS_DATA_FAIL';
export const CLEAR_CUBO_VENTAS_DATA = 'CLEAR_CUBO_VENTAS_DATA';
export const UPDATE_CUBO_VENTAS_FILTROS = 'UPDATE_CUBO_VENTAS_FILTROS';
export const UPDATE_FILTROS_CHART_CUBO_VENTAS = 'UPDATE_FILTROS_CHART_CUBO_VENTAS';

export const UPDATE_SELECTED_TOTAL_ROWS = 'UPDATE_SELECTED_TOTAL_ROWS';
export const CLEAR_MODIFICACION_CONFIGURACION = 'CLEAR_MODIFICACION_CONFIGURACION';

export const UPLOAD_FILE_TO_STORAGE_ACCOUNT = 'UPLOAD_FILE_TO_STORAGE_ACCOUNT';
export const UPLOAD_FILE_TO_STORAGE_ACCOUNT_SUCCESS = 'UPLOAD_FILE_TO_STORAGE_ACCOUNT_SUCCESS';
export const UPLOAD_FILE_TO_STORAGE_ACCOUNT_FAIL = 'UPLOAD_FILE_TO_STORAGE_ACCOUNT_FAIL'; 
export const CLEAR_UPLOAD_FILE_TO_STORAGE_ACCOUNT = 'CLEAR_UPLOAD_FILE_TO_STORAGE_ACCOUNT';

export const DOWNLOAD_FILE_START = 'DOWNLOAD_FILE_START'
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL'
export const CLEAR_DOWNLOAD_FILE = 'CLEAR_DOWNLOAD_FILE'

export const LOAD_INFORME_TRES_UNIDADES_NEGOCIO_START = 'LOAD_INFORME_TRES_UNIDADES_NEGOCIO_START';
export const LOAD_INFORME_TRES_UNIDADES_NEGOCIO_SUCCESS = 'LOAD_INFORME_TRES_UNIDADES_NEGOCIO_SUCCESS';
export const LOAD_INFORME_TRES_UNIDADES_NEGOCIO_FAIL = 'LOAD_INFORME_TRES_UNIDADES_NEGOCIO_FAIL';

export const LOAD_INFORME_TRES_BUDGETS_FORECASTS_START = 'LOAD_INFORME_TRES_BUDGETS_FORECASTS_START';
export const LOAD_INFORME_TRES_BUDGETS_FORECASTS_SUCCESS = 'LOAD_INFORME_TRES_BUDGETS_FORECASTS_SUCCESS';
export const LOAD_INFORME_TRES_BUDGETS_FORECASTS_FAIL = 'LOAD_INFORME_TRES_BUDGETS_FORECASTS_FAIL';
export const CLEAR_INFORME_TRES_BUDGETS_FORECASTS = 'CLEAR_INFORME_TRES_BUDGETS_FORECASTS';

export const LOAD_INFORME_TRES_DATA_START = 'LOAD_INFORME_TRES_DATA_START';
export const LOAD_INFORME_TRES_DATA_SUCCESS = 'LOAD_INFORME_TRES_DATA_SUCCESS';
export const LOAD_INFORME_TRES_DATA_FAIL = 'LOAD_INFORME_TRES_DATA_FAIL';
export const CLEAR_INFORME_TRES_DATA = 'CLEAR_INFORME_TRES_DATA';
export const UPDATE_INFORME_TRES_FILTROS = 'UPDATE_INFORME_TRES_FILTROS';

export const EXPORTAR_INFORME_TRES_EXPANDIDO_START = 'EXPORTAR_INFORME_TRES_EXPANDIDO_START';
export const EXPORTAR_INFORME_TRES_EXPANDIDO_SUCCESS = 'EXPORTAR_INFORME_TRES_EXPANDIDO_SUCCESS';
export const EXPORTAR_INFORME_TRES_EXPANDIDO_FAIL = 'EXPORTAR_INFORME_TRES_EXPANDIDO_FAIL';
export const EXPORTAR_INFORME_TRES_EXPANDIDO_CLEAR = 'EXPORTAR_INFORME_TRES_EXPANDIDO_CLEAR';

export const UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER_START = 'UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER_START';
export const UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER_SUCCESS = 'UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER_SUCCESS';
export const UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER_FAIL = 'UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER_FAIL';
export const CLEAR_UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER = 'CLEAR_UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER';

export const APPROVE_INFORME_TRES_BUDGET_FORECAST_START = 'APPROVE_INFORME_TRES_BUDGET_FORECAST_START';
export const APPROVE_INFORME_TRES_BUDGET_FORECAST_SUCCESS = 'APPROVE_INFORME_TRES_BUDGET_FORECAST_SUCCESS';
export const APPROVE_INFORME_TRES_BUDGET_FORECAST_FAIL = 'APPROVE_INFORME_TRES_BUDGET_FORECAST_FAIL';
export const CLEAR_APPROVE_INFORME_TRES_BUDGET_FORECAST = 'CLEAR_APPROVE_INFORME_TRES_BUDGET_FORECAST';

// actionTypes.js
export const CAMBIAR_ESTADO_PAGINA = 'CAMBIAR_ESTADO_PAGINA';
export const GUARDAR_DATOS_ENCUESTA = 'GUARDAR_DATOS_ENCUESTA';
export const GUARDAR_CONTENIDO_ENVIO = 'GUARDAR_CONTENIDO_ENVIO';
export const ACTUALIZAR_HASH_ENCUESTA = 'ACTUALIZAR_HASH_ENCUESTA';